import { Context } from "../../context/Context";
import { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const {userData} =useContext(Context)
  const {
    getDashboardDetail,
    getTransactionGraphDataApi,
    getPainGraphDataApi,
    getSatisfactionGraphDataApi,
    getPainExportDataApi,
    getSatisfactionExportDataApi,
    getTransactionExportDataApi,
    getActivityGraphDataApi,
    getActivityExportDataApi,
  } = useApiAxios();

  const [dashboardData, setDashboardData] = useState({});
  const [chartOption, setChartOption] = useState(null);
  const [chartOptionForSatisfaction, setChartOptionForSatisfaction] =
    useState(null);
  const [ChartOptionForActivityAndPain, setChartOptionForActivityAndPain] =
    useState(null);
  const [dateRange, setDateRange] = useState("last7days");

  const getDateRange = (range) => {
    const endDate = dayjs();
    let startDate;

    switch (range) {
      case "last7days":
        startDate = endDate.subtract(6, "days");
        break;
      case "month_days":
        startDate = endDate.subtract(30, "days");
        break;
      case "fixed_to_next_month":
        
        startDate = dayjs("2024-06-07");
        endDate.add(1, "month").endOf("month");
        break;
      default:
        startDate = endDate.subtract(6, "days");
    }

    return {
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    };
  };

  useEffect(() => {
    const { startDate, endDate } = getDateRange(dateRange);
    fun2(startDate, endDate);
    fetchData();
  }, [dateRange]);

  

  const fun2 = async (startDate, endDate) => {
    try {
      const [resTransaction, resPain, resSatisfaction, resActivity] =
        await Promise.all([
          getTransactionGraphDataApi(startDate, endDate),
          getPainGraphDataApi(startDate, endDate),
          getSatisfactionGraphDataApi(startDate, endDate),
          getActivityGraphDataApi(startDate, endDate),
        ]);

      // Processing data for Transaction Graph
      const transactionData = resTransaction.data.data.map((element) => {
        return {
          x: new Date(element.x),
          y: Number(element.y),
        };
      });

      setChartOption({
        chart: {
          type: "column",
        },
        title: {
          text: "Weekly Revenue",
        },
        xAxis: {
          type: "category",
          labels: {
            formatter: function () {
              const days = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ];

              const date = new Date(transactionData[this.value].x);
              const weekday = days[date.getDay()];
              return weekday;
            },
          },
        },
        yAxis: {
          title: {
            text: "Amount",
          },
          labels: {
            formatter: function () {
              return this.value + " $";
            },
          },
        },
        series: [
          {
            name: "Sales ",
            data: transactionData.map((item) => [item.x, Math.trunc(item.y)]),
            color: "#FD707B",
          },
        ],
      });

      const satisfactionData = resSatisfaction.data.data.map((element) => ({
        x: new Date(element.x),
        y: Number(element.y),
      }));

      setChartOptionForSatisfaction({
        chart: {
          type: "line",
        },
        title: {
          text: "Satisfaction Scale",
        },
        xAxis: {
          type: "category",
          labels: {
            formatter: function () {
              const days = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ];
              console.log(this.value);
              const date = new Date(satisfactionData[this.value].x);
              const weekday = days[date.getDay()];
              return weekday;
            },
          },
        },
        yAxis: {
          title: {
            text: "Percentage",
          },
          labels: {
            formatter: function () {
              return this.value + "%";
            },
          },
        },
        series: [
          {
            name: "Value ",
            data: satisfactionData.map((item) => [item.x, Math.trunc(item.y)]),
            color: "#FD707B",
          },
        ],
      });

      // Processing data for Pain and Activity Graph
      const painData = resPain.data.data.map((element) => ({
        x: new Date(element.x),
        y: Number(element.y),
      }));

      const activityData = resActivity.data.data.map((element) => ({
        x: new Date(element.x),
        y: Number(element.y),
      }));

        // Combine unique dates from both painData and activityData
const uniqueDates = Array.from(
  new Set([
    ...painData.map((item) => moment(item.x).format("MM/DD/YYYY")),
    ...activityData.map((item) => moment(item.x).format("MM/DD/YYYY")),
  ])
);

// Sort the unique dates
uniqueDates.sort((a, b) => new Date(a) - new Date(b));

// Align the data with the uniqueDates
const alignedPainData = uniqueDates.map((date) => {
  const painPoint = painData.find(
    (item) => moment(item.x).format("MM/DD/YYYY") === date
  );
  return painPoint ? painPoint.y : null; // Use null for missing values
});

const alignedActivityData = uniqueDates.map((date) => {
  const activityPoint = activityData.find(
    (item) => moment(item.x).format("MM/DD/YYYY") === date
  );
  return activityPoint ? activityPoint.y : null; // Use null for missing values
});

// Set up the chart options
setChartOptionForActivityAndPain({
  chart: {
    type: "line",
  },
  title: {
    text: "Pain and Function Scale",
  },
  xAxis: {
    type: "category",
    categories: uniqueDates,
    title: {
      text: "Date",
    },
  },
  yAxis: {
    title: {
      text: "Value",
    },
  },
  series: [
    {
      name: "Average Pain level",
      data: alignedPainData,
      color: "#036E49",
    },
    {
      name: "Average Functional level",
      data: alignedActivityData,
      color: "#FD707B",
    },
  ],
});
    } catch (error) {
      console.error("Error fetching graph data:", error);
    }
  };

  const handleExportData = async () => {
    try {
      const response = await getTransactionExportDataApi();
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "subscriptions.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handlePainExportData = async () => {
    try {
      const response = await getPainExportDataApi();
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "painSheet.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleSatisfactionExportData = async () => {
    try {
      const response = await getSatisfactionExportDataApi();
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "satisfactionSheet.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleActivityExportData = async () => {
    try {
      const response = await getActivityExportDataApi();
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "activitySheet.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleDropdownChange = (e) => {
    if (e === "Pain Level") {
      handlePainExportData();
    } else if (e === "Functional Level") {
      handleActivityExportData();
    }
  };

  const fetchData = async (dashboardType) => {
    try {
      const response = await getDashboardDetail(dashboardType);
      setDashboardData(response.data.data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };



  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link
                 to="#">Home </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard
              </li>
            </ol>
          </nav>
          <h1 className="page-title">Main Dashboard</h1>
        </div>
      </div>
      <div className="row align-items-stretch">

           {/*/Col*/}
           <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
          <div className="card mini-stats-wid h-100">
            <div className="card-body">
              <div className="d-flex align-items-center h-100">
                <div className="align-self-center">
                  <div className="me-3 mini-stats-icon d-flex justify-content-center align-items-center">
                    <i className="icon-3" />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="mini-stats-name mb-0">Total Revenue</p>
                  <h3 className="mini-stats-count mb-0">
                  ${dashboardData.TotalPayment}

                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*/Col*/}
  {/*/Col*/}
       {userData.role === "admin"?<div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
          <div className="card mini-stats-wid h-100">
            <div className="card-body">
              <div className="d-flex align-items-center h-100">
                <div className="align-self-center">
                  <div className="me-3 mini-stats-icon d-flex justify-content-center align-items-center">
                    <i className="icon-14" />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="mini-stats-name mb-0">Total PT Payments</p>
                  <h3 className="mini-stats-count mb-0">
                    ${Math.trunc(dashboardData.physicalTherapistTotalPayment)}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>:<></>} 
        {/*/Col*/}
        {dashboardData.therapistCount !== undefined && (
          <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
            <div className="card mini-stats-wid h-100">
              <div className="card-body">
                <div className="d-flex align-items-center h-100">
                  <div className="align-self-center">
                    <div className="me-3 mini-stats-icon d-flex justify-content-center align-items-center">
                      <i className="icon-9" />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="mini-stats-name mb-0">Total Therapists</p>
                    <h3 className="mini-stats-count mb-0">
                      {dashboardData.therapistCount}
                    </h3>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/*/Col*/}
        <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
          <div className="card mini-stats-wid h-100">
            <div className="card-body">
              <div className="d-flex align-items-center h-100">
                <div className="align-self-center">
                  <div className="me-3 mini-stats-icon d-flex justify-content-center align-items-center">
                    <i className="icon-3" />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="mini-stats-name mb-0">Total Patients</p>
                  <h3 className="mini-stats-count mb-0">
                    {dashboardData.patientCount}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*/Col*/}
  {/*/Col*/}
  <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
          <div className="card mini-stats-wid h-100">
            <div className="card-body">
              <div className="d-flex align-items-center h-100">
                <div className="align-self-center">
                  <div className="me-3 mini-stats-icon d-flex justify-content-center align-items-center">
                    <i className="icon-3" />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="mini-stats-name mb-0">Total Users</p>
                  <h3 className="mini-stats-count mb-0">
                    {dashboardData.totalUser}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*/Col*/}

        <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
          <div className="card mini-stats-wid h-100">
            <div className="card-body">
              <div className="d-flex align-items-center h-100">
                <div className="align-self-center">
                  <div className="me-3 mini-stats-icon d-flex justify-content-center align-items-center">
                    <i className="icon-1" />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="mini-stats-name mb-0">
                    Total Company Payments
                  </p>
                  <h3 className="mini-stats-count mb-0">
                    ${Math.trunc(dashboardData.companyTotalPayment)}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
          <div className="card mini-stats-wid h-100">
            <div className="card-body">
              <div className="d-flex align-items-center h-100">
                <div className="align-self-center">
                  <div className="me-3 mini-stats-icon d-flex justify-content-center align-items-center">
                    <i className="icon-7" />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="mini-stats-name mb-0">Total Companies</p>
                  <h3 className="mini-stats-count mb-0">
                    {dashboardData.companyCount}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
          <div className="card mini-stats-wid h-100">
            <div className="card-body">
              <div className="d-flex align-items-center h-100">
                <div className="align-self-center">
                  <div className="me-3 mini-stats-icon d-flex justify-content-center align-items-center">
                    <i className="icon-15" />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="mini-stats-name mb-0">Total Patient Payments</p>
                  <h3 className="mini-stats-count mb-0">
                    ${Math.trunc(dashboardData.patientTotalPayment)}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="col-12 mb-3" style={{ position: "relative" }}>
          <div className="card customer-sf h-100">
            <div className="card-body">
              <div id="transaction_functional " className="mt-4">
                <button
                  onClick={handleExportData}
                  className="btn btn-sm btn-success mt-3"
                  style={{ position: "absolute", top: 0, right: "20px" }}
                >
                  Export
                </button>

                {chartOption && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOption}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 mb-3">
          <div className="card customer-sf h-100">
            <div className="card-body pb-3">
              <div id="satisfaction_functional" className="mt-4">
                <button
                  onClick={handleSatisfactionExportData}
                  className="btn btn-sm btn-success mt-3"
                  style={{ position: "absolute", top: 0, right: "20px" }}
                >
                  Export
                </button>
             
                {chartOptionForSatisfaction &&
                  (chartOptionForSatisfaction.series.length === 0 ? (
                    <>No Data Avalable </>
                  ) : (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={chartOptionForSatisfaction}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 mb-3">
          <div className="card customer-sf h-100">
            <div className="card-body pb-3">
              <div id="pain_activity_functional">
                {/* Export Button */}
                <div className="bulk-action d-flex justify-content-end">
                  <button
                    className="btn btn-sm btn-success"
               
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    type="button"
                  >
                    Export
                  </button>

                  <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3 w-25">
                    <li>
                      <button
                        className="dropdown-item "
                        type="button"
                        onClick={() => handleDropdownChange("Pain Level")}
                      >
                        Avg Pain Level
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleDropdownChange("Functional Level")}
                      >
                        Avg Functional Level
                      </button>
                    </li>
                  </ul>
                </div>
                {ChartOptionForActivityAndPain && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={ChartOptionForActivityAndPain}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
