import React, { useState, useEffect } from "react";
import useApiAxios from "./../../api/useApiAxios";

const VideoComponent = ({ src, className }) => {
  const [imageUrl, setImageUrl] = useState("/imgLoader.gif");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getImageApi } = useApiAxios();

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (src.startsWith("data:im")) {
        setImageUrl(src);
      } else {
        const url = await getImageApi(src);
        if (url.error) {
          setError(true);

          setLoading(false);
        } else {
          setError(false);
          setImageUrl(url.data.data);
          setLoading(false);
        }
      }
    };

    fetchImageUrl();
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading image: </div>;

  return (
    <video
      controlsList="nodownload"
      width="100%"
      controls
      className={className}
    >
      <source
        src={imageUrl}
        type="video/mp4"
      />
    </video>
  );
};

export default VideoComponent;
