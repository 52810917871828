import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../context/Context";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const EditOffer = () => {
  const { updateOfferApi, getAllCourse, getOfferById } = useApiAxios();

  const fun = async () => {
    const { data, error } = await getAllCourse(1, 100);
    const dataArray = [];
    if (!error) {
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        dataArray.push({ value: element._id, label: element.title });
      }
      setCourses(dataArray);
    }
    const data2 = await getOfferById(id);
    const { title, courses, threeMonth, sixMonth, year, description } =
      data2.data.data;

    formik.setValues({
      title,
      courses,
      threeMonthPrice: threeMonth.price,
      sixMonthPrice: sixMonth.price,
      yearPrice: year.price,
      description,
    });
    const dataToSet2 = [];
    for (let index = 0; index < courses.length; index++) {
      const element = courses[index];

      if (dataArray.some((item) => item.value === element)) {
        dataToSet2.push({ value: element._id, label: element.title });
      }
    }
    setSelectedCourseValue(dataToSet2);
  };

  const { id } = useParams();
  const { userData } = useContext(Context);
  const initialValues = {
    title: "",
    url: "",
    isPaid: "",
    paymentType: "",
    price: 0,
    description: "",
    courses: [],
    createdFor: userData.role === "admin" ? "physical-therapists" : "patient",
    interval: "month",
  };

  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedCourseValue, setSelectedCourseValue] = useState(null);
  const [courses, setCourses] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fun();
  }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    threeMonthPrice: Yup.string().required("Price for offer is required"),
    sixMonthPrice: Yup.string().required("Price for offer is required"),
    yearPrice: Yup.string().required("Price for offer is required"),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    const { error } = await updateOfferApi(id, {
      ...values,
      courses: selectedCourse,
      year: { price: values.yearPrice },
      sixMonth: { price: values.sixMonthPrice },
      threeMonth: { price: values.threeMonthPrice },
    });
    setSubmitting(false);
    if (!error) {
      navigate("/sales/offers");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  console.log(formik.getFieldProps("createdFor"));

  return (
    <div>
      <div className="container-fluid">
        <div className="row d-flex ">
          <div className="col-sm-5">
            <nav aria-label="breadcrumb" className="theme-breadcrumb">
              <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                <li className="breadcrumb-item">
                  <Link to="/sales/offers">Offer</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Offer
                </li>
              </ol>
            </nav>
            <h1 className="page-title">Edit Offer</h1>
          </div>
        </div>
        <div className="row align-items-stretch">
          <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
            <div className="card customer-sf h-100">
              <div className="p-30">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row mt-4">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Offer for
                        </label>
                        <div className="col-sm-8">
                          {!(userData.role === "admin") ? (
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input "
                                type="radio"
                                name="createdFor"
                                id="inlineRadio1"
                                defaultValue="physical-therapists"
                                defaultChecked={userData.role === "admin"}
                                disabled={!(userData.role === "admin")}
                                {...formik.getFieldProps("createdFor")}
                              />
                              <label
                                className="form-check-label "
                                htmlFor="inlineRadio1"
                              >
                                Physical Therapists
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="inlineRadio2"
                              name="createdFor"
                              defaultValue="patient"
                              defaultChecked={!(userData.role === "admin")}
                              {...formik.getFieldProps("createdFor")}
                              onChange={() =>
                                formik.setFieldValue("createdFor", "patient")
                              }
                              disabled={true}
                            />
                            <label
                              className="form-check-label "
                              htmlFor="inlineRadio2"
                            >
                              Patient
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="inlineRadio3"
                              name="createdFor"
                              defaultValue="company"
                              {...formik.getFieldProps("createdFor")}
                              disabled={true}
                              onChange={() =>
                                formik.setFieldValue("createdFor", "company")
                              }
                            />
                            <label
                              className="form-check-label "
                              htmlFor="inlineRadio3"
                            >
                              Company
                            </label>
                          </div>

                          {formik.touched.createdFor &&
                          formik.errors.createdFor ? (
                            <div className="error">
                              {formik.errors.createdFor}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Title</label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            {...formik.getFieldProps("title")}
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <div className="error">{formik.errors.title}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Price for 3 Months Plan (USD)
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="number"
                            min={0}
                            step=".01"
                            max={10000}
                            className="form-control"
                            id="threeMonthPrice"
                            {...formik.getFieldProps("threeMonthPrice")}
                          />
                          {formik.touched.threeMonth?.price &&
                          formik.errors.threeMonth?.price ? (
                            <div className="error">
                              {formik.errors.threeMonth?.price}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Price for 6 Months Plan (USD)
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="number"
                            min={0}
                            step=".01"
                            max={10000}
                            className="form-control"
                            id="sixMonthPrice"
                            {...formik.getFieldProps("sixMonthPrice")}
                          />
                          {formik.touched.sixMonth?.price &&
                          formik.errors.sixMonth?.price ? (
                            <div className="error">
                              {formik.errors.sixMonth?.price}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Price for 12 Months Plan (USD)
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="number"
                            min={0}
                            step=".01"
                            max={10000}
                            className="form-control"
                            id="yearPrice"
                            {...formik.getFieldProps("yearPrice")}
                          />
                          {formik.touched?.yearPrice &&
                          formik.errors?.yearPrice ? (
                            <div className="error">
                              {formik.errors.year?.price}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="product-input text-start">
                      <label className="form-label mt-3">
                        Brief Description
                      </label>

                      <SunEditor
                        width="100%"
                        setContents={formik.values.description}
                        onChange={(editorText) =>
                          formik.setFieldValue("description", editorText)
                        }
                        setOptions={{
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["undo", "redo"],
                            ["font", "fontSize"],
                            ["fontColor", "hiliteColor"],
                            ["align", "list", "lineHeight"],

                            ["table", "horizontalRule", "link"],
                          ],
                          defaultTag: "div",
                          minHeight: "300px",
                          showPathLabel: false,
                          // font: sortedFontOptions,
                        }}
                      />
                      {formik.touched.description &&
                      formik.errors.description ? (
                        <div className="text-danger">
                          {formik.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6 col-lg-12 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label" />
                        <div className="col-sm-8">
                          <button
                            type="submit"
                            className="btn addsubmit-btn green-btn  me-3 mb-3"
                            disabled={formik.isSubmitting}
                          >
                            Update
                          </button>
                          <Link
                            to={"/sales/offers"}
                            className="btn addsubmit-btn grey-btn  mb-3"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOffer;
