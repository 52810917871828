import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

const PaginationComponent = ({ total, page, limit, setPage }) => {
  const [items, setItems] = useState(false);

  useEffect(() => {
    let item = [];

    for (let number = 1; number <= Math.trunc(total / limit) + 1; number++) {
      item.push(
        <Pagination.Item
          key={number}
          onClick={() => {
            setPage(number);
          }}
          active={number === page}
        >
          {number}
        </Pagination.Item>
      );
    }
    setItems(item);
  }, [page, total]);

  return (
    <div className="d-flex justify-content-center align-items-center">
      {" "}
      <Pagination>{items}</Pagination>
    </div>
  );
};

export default PaginationComponent;
