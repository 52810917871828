import React, { useState, useEffect } from "react";
import AddCourses from "./AddCourses";
import EditCourses from "./EditCourses";
import DeleteModel from "../deleteModel/DeleteModel";
import moment from "moment";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import PaginationComponent from "../../pagination/PaginationComponent";
import ImageComponent from "../media/ImageComponent";

const Courses = () => {
  const [show, setShow] = useState(false);
  const { getAllCourse, handleCourseDelete, updateCourseViewApi } =
    useApiAxios();
  const [currentCourseData, setCurrentCourseData] = useState({}); // State to store the selected course ID
  const [editimage, setEditImage] = useState("");
  const [editimageUrl, setEditImageUrl] = useState("");
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const searchCourseHandler = async (e) => {
    setSearch(e.target.value);
  };
  const limit = 15;
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [courses, setCourses] = useState([]);
  const [total, setTotal] = useState(null);

  const fun = async () => {
    const data = await getAllCourse(page, limit, sort, search);

    setCourses(data.data.data);
    setTotal(data.data.total);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const openDeleteModel = (data) => {
    setDeleteData(data);
    setDeleteModal(true);
  };
  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };
  const deleteCourseHandler = async (data) => {
    await handleCourseDelete(data);
    await fun();
    closeDeleteModel();
  };
  useEffect(() => {
    fun();
    document.title = "Courses";
  }, [page, sort, search]);

  const handleShow = () => {
    setShow(true);
  };
  const handleEditCourse = async (course) => {
    setCurrentCourseData(course);
    setShowModal(true);
  };
  const handleSort = async (selectedSort) => {
    setSort(selectedSort);

    setPage(1);
  };

  return (
    <div>
      <div className="">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-xl-5 col-sm-5">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Products </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Courses
                  </li>
                </ol>
              </nav>
              <h4 className="page-title ">
                Courses <span>({total})</span>
              </h4>
            </div>
            <div className="col-xl-7 col-sm-7 mb-3 mb-xl-0 d-flex justify-content-start justify-content-sm-end align-items-sm-center">
              <Link className="btn company-btn" onClick={handleShow}>
                <i class="fa-regular fa-plus"></i> Add Courses
              </Link>
            </div>
          </div>
          <div className="row align-items-stretch">
            <div className="col-xxl-12 mb-3">
              <div className="card customer-sf h-100">
                <div className="p-3 pb-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="input-group mb-2 search-table">
                        <input
                          type="text"
                          className="form-control form-control-filter"
                          placeholder="Search Courses"
                          onChange={searchCourseHandler}
                        />
                        <i className="icon-13 search-ic" />
                        <div className="btn-group bulk-action ">
                          <button
                            type="button"
                            className="filter-btn px-3 px-md-4"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                          >
                            <i className="fa fa-filter me-2" /> Filters
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleSort("newest")}
                              >
                                Newest
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleSort("oldest")}
                              >
                                Oldest
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleSort("updated")}
                              >
                                Recently Updated
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-4 companies-table product-table">
                  <div className="mt-2 table-responsive">
                    <table
                      id="products-table"
                      className="table display table-edits"
                      cellSpacing={0}
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th>Thumbnail</th>
                          <th>Description</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {courses.map((course, index) => (
                          <tr key={index}>
                            <td>
                              <span className="product-img">
                                <ImageComponent
                                  className="h-100"
                                  src={`${course.icon}`}
                                />
                              </span>
                            </td>
                            <td className="pro-info text-nowrap">
                              <h5>{course.title}</h5>
                              <p>
                                <span className="course">Course</span>
                                <span className="course-no">
                                  <i className="fa-regular fa-calendar-days" />{" "}
                                  {moment(course.createdAt).format(
                                    "MM/DD/YYYY"
                                  )}
                                </span>
                              </p>
                            </td>
                            <td className="text-center text-nowrap">
                              <div className="d-inline-flex  align-items-center justify-content-center ">
                                <span className="px-2">Draft</span>
                                <div className="form-check form-switch">
                                  <input
                                    onChange={async (e) => {
                                      await updateCourseViewApi({
                                        id: course._id,
                                        publicView: e.target.checked,
                                      });
                                      fun();
                                    }}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked={course.publicView}
                                  />
                                </div>
                                <span>Public</span>
                              </div>
                              <Link
                                to={`/product/courses/videos/${course._id}`}
                                className="btn btn-sm view-btn mx-3 por-btn"
                              >
                                <i className="fa-regular fa-eye"></i> VIEW VIDEO
                              </Link>
                              <Link
                                className="action-btn"
                                onClick={() => {
                                  handleEditCourse(course);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </Link>
                              <Link
                                className="action-btn"
                                onClick={() => openDeleteModel(course._id)}
                              >
                                <i className="fa fa-trash"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PaginationComponent
            total={total}
            page={page}
            limit={limit}
            setPage={setPage}
          />
        </div>
      </div>

      <EditCourses
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setEditImageUrl("");
          setEditImage("");
          setCurrentCourseData({});
        }}
        currentCourseData={currentCourseData} // Pass the selected course ID
        fun={fun}
        imageUrl={editimageUrl}
        setImageUrl={setEditImageUrl}
        image={editimage}
        setImage={setEditImage}
      />
      <AddCourses show={show} handleClose={() => setShow(false)} fun={fun} />

      <DeleteModel
        show={deleteModal}
        handleClose={closeDeleteModel}
        data={deleteData}
        deleteFunction={deleteCourseHandler}
      />
    </div>
  );
};

export default Courses;
