import { createContext, useState } from "react";
import { toast } from "react-toastify";

export const Context = createContext();
export const AuthContextProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [updateCount, setUpdateCount] = useState(0)
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEmail, setShowEmail] = useState(false)
  const [showEmailData, setShowEmailData] = useState(false)
  const openErrorSnackbar = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const openInfoSnackbar = (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  return (
    <Context.Provider
      value={{
        userData,
        setUserData,
        isAuthorized,
        setIsAuthorized,
        isLoading,
        setIsLoading,
        openInfoSnackbar,
        openErrorSnackbar,
        updateCount,
        setUpdateCount,
        showEmailData,
        setShowEmailData,
        showEmail,
        setShowEmail
      }}
    >
      {children}
    </Context.Provider>
  );
};
