import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

const AddPhysicalTherapist = () => {
  const { createPhysicaltherapistApi, getAllTagsApi } = useApiAxios();

  const initialValues = {
    name: "",

    email: "",
    phone: "",
    address: "",
    licenseNumber: "",

    city: "",
    state: "",

    zip: "",
    clinicName: "",
  };
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);

  const navigate = useNavigate();

  const getTags = async () => {
    const { data, error } = await getAllTagsApi();
    if (!error) {
      const dataArray = [];
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        dataArray.push({ value: element._id, label: element.name });
      }
      setTags(dataArray);
    }
  };
  useEffect(() => {
    getTags();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),

    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    licenseNumber: Yup.string().required("License Number is required"),
    zip: Yup.string().required("Zip are required"),
    clinicName: Yup.string().required("Clinic Name is required"),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    const { error } = await createPhysicaltherapistApi({
      ...values,
      tags: selectedTags,
    });
    setSubmitting(false);
    if (!error) {
      navigate("/physical-therapists");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <div className="container-fluid">
        <div className="row d-flex ">
          <div className="col-sm-5">
            <nav aria-label="breadcrumb" className="theme-breadcrumb">
              <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                <li className="breadcrumb-item">
                  <Link to="#">PhysicalTherapists </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add PhysicalTherapist
                </li>
              </ol>
            </nav>
            <h1 className="page-title">Add New PhysicalTherapist</h1>
          </div>
          <div className="col-sm-7 mb-3 mb-sm-0 d-flex justify-content-start justify-content-sm-end align-items-center">
         
          </div>
        </div>
        <div className="row align-items-stretch">
          <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
            <div className="card customer-sf h-100">
              <div className="p-30">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row mt-4">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Name</label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            {...formik.getFieldProps("name")}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className="error">{formik.errors.name}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Email</label>
                        <div className="col-sm-8">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Phone</label>
                        <div className="col-sm-8">
                          <input
                            type="tel"
                            className="form-control"
                            id="phone"
                            {...formik.getFieldProps("phone")}
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="error">{formik.errors.phone}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Address
                        </label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control address-field"
                            rows={3}
                            id="address"
                            {...formik.getFieldProps("address")}
                          />
                          {formik.touched.address && formik.errors.address ? (
                            <div className="error">{formik.errors.address}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">City</label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control city-field"
                            rows={3}
                            id="city"
                            {...formik.getFieldProps("city")}
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className="error">{formik.errors.city}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">State</label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control state-field"
                            rows={3}
                            id="state"
                            {...formik.getFieldProps("state")}
                          />
                          {formik.touched.state && formik.errors.state ? (
                            <div className="error">{formik.errors.state}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          License Number
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="licenseNumber"
                            {...formik.getFieldProps("licenseNumber")}
                          />
                          {formik.touched.licenseNumber &&
                          formik.errors.licenseNumber ? (
                            <div className="error">
                              {formik.errors.licenseNumber}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Zip</label>
                        <div className="col-sm-8">
                          <input
                            type="number"
                            className="form-control"
                            id="zip"
                            {...formik.getFieldProps("zip")}
                            min="1"
                          />
                          {formik.touched.zip && formik.errors.zip ? (
                            <div className="error">{formik.errors.zip}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Clinic Name
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="clinicName"
                            {...formik.getFieldProps("clinicName")}
                          />
                          {formik.touched.clinicName &&
                          formik.errors.clinicName ? (
                            <div className="error">
                              {formik.errors.clinicName}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Tags</label>
                        <div className="col-sm-8">
                          
                          <Select
                            isSearchable={false}
                            isClearable={true}
                            isMulti
                            name="tags"
                            className="form-control"
                           
                            onChange={(e) => {
                              const dataToSet = [];
                              for (let index = 0; index < e.length; index++) {
                                const element = e[index];
                                dataToSet.push(element.value);
                              }
                              setSelectedTags(dataToSet);
                            }}
                            options={tags}
                          />
                          {formik.touched.experience &&
                          formik.errors.experience ? (
                            <div className="error">
                              {formik.errors.experience}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6 col-lg-12 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label" />
                        <div className="col-sm-8">
                          <button
                            type="submit"
                            className="btn addsubmit-btn green-btn  me-3 mb-3"
                            disabled={formik.isSubmitting}
                          >
                            Add
                          </button>
                          <Link
                            to={"/physical-therapists"}
                            className="btn addsubmit-btn grey-btn  mb-3"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPhysicalTherapist;
