import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import EditableText from "./EditableText";
import EditableImage from "./EditableImage";

const VideoLibraryContent = () => {
  const { getWebsiteContent, updateWebsiteContent } = useApiAxios();
  const [content, setContent] = useState({});
  const init = async () => {
    const { data, error } = await getWebsiteContent();
    if (!error) {
      setContent(data.data);
    }
  };
  const updateData = async () => {
    await updateWebsiteContent(content);
  };

  const onChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <div>
        <div className="video_shade">
          <div className="container mb-4">
            <div className="row">
              <div className="col-sm-12">
                <EditableText
                  text={content.t9}
                  isHeading={true}
                  level={1}
                  className="home_title text-center mb-3"
                  name={"t9"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t10}
                  isHeading={false}
                  level={1}
                  className="home_subtext text-center"
                  name={"t10"}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row d-flex align-items-stretch">
              <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
                <div className="card video_card h-100">
                  <EditableImage
                    src={`${content?.img4}`}
                    title="Stretches"
                    alt=""
                    className="card-img-top"
                    name="img4"
                  />
                  <div className="card-body">
                    <EditableText
                      text={content.t11}
                      isHeading={true}
                      level={5}
                      className="card-title"
                      name={"t11"}
                      onChange={onChange}
                    />

                    <EditableText
                      text={content.t12}
                      isHeading={false}
                      level={5}
                      className="card-text"
                      name={"t12"}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
                <div className="card video_card h-100">
                  <EditableImage
                    src={`${content?.img5}`}
                    title="Stretches"
                    alt=""
                    className="card-img-top"
                    name="img5"
                  />
                  <div className="card-body">
                    <EditableText
                      text={content.t13}
                      isHeading={true}
                      level={5}
                      className="card-title"
                      name={"t13"}
                      onChange={onChange}
                    />

                    <EditableText
                      text={content.t14}
                      isHeading={false}
                      level={5}
                      className="card-text"
                      name={"t14"}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
                <div className="card video_card h-100">
                  <EditableImage
                    src={`${content?.img6}`}
                    title="Stretches"
                    alt=""
                    className="card-img-top"
                    name="img6"
                  />
                  <div className="card-body">
                    <EditableText
                      text={content.t15}
                      isHeading={true}
                      level={5}
                      className="card-title"
                      name={"t15"}
                      onChange={onChange}
                    />

                    <EditableText
                      text={content.t16}
                      isHeading={false}
                      level={5}
                      className="card-text"
                      name={"t16"}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
                <div className="card video_card h-100">
                  <EditableImage
                    src={`${content?.img7}`}
                    title="Stretches"
                    alt=""
                    className="card-img-top"
                    name="img7"
                  />
                  <div className="card-body">
                    <EditableText
                      text={content.t17}
                      isHeading={true}
                      level={5}
                      className="card-title"
                      name={"t17"}
                      onChange={onChange}
                    />

                    <EditableText
                      text={content.t18}
                      isHeading={false}
                      level={5}
                      className="card-text"
                      name={"t18"}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
                <div className="card video_card h-100">
                  <EditableImage
                    src={`${content?.img8}`}
                    title="Stretches"
                    alt=""
                    className="card-img-top"
                    name="img8"
                  />
                  <div className="card-body">
                    <EditableText
                      text={content.t19}
                      isHeading={true}
                      level={5}
                      className="card-title"
                      name={"t19"}
                      onChange={onChange}
                    />

                    <EditableText
                      text={content.t20}
                      isHeading={false}
                      level={5}
                      className="card-text"
                      name={"t20"}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
                <div className="card video_card h-100">
                  <EditableImage
                    src={`${content?.img9}`}
                    title="Stretches"
                    alt=""
                    className="card-img-top"
                    name="img9"
                  />
                  <div className="card-body">
                    <EditableText
                      text={content.t21}
                      isHeading={true}
                      level={5}
                      className="card-title"
                      name={"t21"}
                      onChange={onChange}
                    />

                    <EditableText
                      text={content.t22}
                      isHeading={false}
                      level={5}
                      className="card-text"
                      name={"t22"}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-5">
        <button className="virt_btn green" onClick={updateData}>
          Update Data
        </button>
      </div>
    </div>
  );
};

export default VideoLibraryContent;
