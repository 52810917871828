import React from "react";
import Modal from "react-bootstrap/Modal";
import cros from "../../assets/images/cros.svg";
import "suneditor/dist/css/suneditor.min.css";
const EmployeeListModal = ({
  show,
  handleClose,
  data,
  onSubmit,
  title,
  buttonTitle,
}) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className="modal fade success-popup add-company"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <img src={cros} alt="" />
            </button>
          </div>
          <div className="pb-4 companies-table">
            <div className="mt-2">
              <table
                id="example"
                className="table display table-edits"
                cellSpacing={0}
                width="100%"
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>

                    <th>Gender</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((tagItem, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{tagItem.name}</td>
                      <td>{tagItem.email}</td>
                      <td>{tagItem?.phone}</td>

                      <td>{tagItem?.gender}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer justify-content-start">
            <button
              type="submit"
              onClick={() => onSubmit(data)}
              className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
            >
              {buttonTitle}
            </button>
            <button
              type="button"
              className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
              onClick={handleClose}
            >
              Go Back
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EmployeeListModal;
