import React, { useState, useEffect } from "react";
import AddEmailAutoModel from "./AddEmailAutoModel";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import PaginationComponent from "../../pagination/PaginationComponent";

const EmailAuto = () => {
  const [show, setShow] = useState(false);
  const { getAllEmailAutoApi, deleteEmailAutoApi, getAllEmailTemplateApi } =
    useApiAxios();
  const [selectEmailData, setSelectEmailData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [total, setTotal] = useState(null);

  const handleDelete = async (courseId) => {
    await deleteEmailAutoApi(courseId);
    await fun();
  };
  const fun = async () => {
    const data = await getAllEmailAutoApi();

    setCourses(data.data.data);
    setTotal(data.data.total);
    const data2 = await getAllEmailTemplateApi();
    const dataArray = [];
    for (let index = 0; index < data2.data.data.length; index++) {
      const element = data2.data.data[index];
      dataArray.push({ value: element._id, label: element.internalTitle });
    }
    setSelectEmailData(dataArray);
  };
  useEffect(() => {
    fun();
    document.title = "Email Automation";
  }, []);

  const handleShow = () => {
    setShow(true);
  };

  return (
    <div>
      <div className="">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-xl-5 col-sm-5">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                  <li className="breadcrumb-item">
                    <Link href="#">Email </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Email Automation
                  </li>
                </ol>
              </nav>

              <h4 className="page-title ">
                Email Automation <span>({total})</span>
              </h4>
            </div>
            <div className="col-xl-7 col-sm-7 mb-3 mb-xl-0 d-flex justify-content-start justify-content-sm-end align-items-sm-center">
              <Link className="btn company-btn" onClick={handleShow}>
                <i class="fa-regular fa-plus"></i> Email Automation
              </Link>
            </div>
          </div>
          <div className="row align-items-stretch">
            <div className="col-xxl-12 mb-3">
              <div className="card customer-sf h-100">
                <div className="pb-4 companies-table product-table">
                  <div className="mt-2 table-responsive">
                    <table
                      id="products-table"
                      className="table display table-edits"
                      cellSpacing={0}
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th>When</th>
                          <th>For </th>
                          <th>Template Internal Title</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {courses.map((course, index) => (
                          <tr key={index}>
                            <td>{course.when}</td>
                            <td>{course.userType}</td>
                            <td>{course?.emailTemplate?.title}</td>

                            <td className="text-center">
                              <Link
                                className="action-btn"
                                onClick={() => handleDelete(course._id)}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PaginationComponent total={total} page={1} limit={10000} />
        </div>
      </div>
      <AddEmailAutoModel
        show={show}
        handleClose={() => setShow(false)}
        fun={fun}
        selectEmailData={selectEmailData}
      />
    </div>
  );
};

export default EmailAuto;
