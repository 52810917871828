import React, { useEffect} from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import cros from "../../assets/images/cros.svg";
import useApiAxios from "../../api/useApiAxios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
const EditEmailTemplate = ({
  show,
  handleClose,
  currentCourseData,
  fun
 
}) => {
  const { updateEmailTemplateApi } = useApiAxios();
  const formik = useFormik({
    initialValues: {
      internalTitle: currentCourseData?.internalTitle,
      body: currentCourseData?.body,
      title: currentCourseData?.title,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      internalTitle: Yup.string().required("Internal Title is required"),
      body: Yup.string().required("Email body is required"),
    }),
    onSubmit: async (values) => {
      try {
        const data = await updateEmailTemplateApi(
          currentCourseData._id,
          values
        );

        handleClose();
        fun();
      } catch (error) {}
    },
  });
  useEffect(() => {
    formik.setValues({
      internalTitle: currentCourseData?.internalTitle,
      body: currentCourseData?.body,
      title: currentCourseData?.title,
    });
  }, [currentCourseData?.title, show]);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal fade success-popup add-company"
        id="addcompany"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-content">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Update Email Template
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <img src={cros} alt="" />
              </button>
            </div>
            <div className="modal-body text-start">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-2">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="titleInput"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <div className="text-danger">{formik.errors.title}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-2">Internal Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="titleInput"
                      name="internalTitle"
                      value={formik.values.internalTitle}
                      onChange={formik.handleChange}
                      
                    />
                    {formik.touched.internalTitle &&
                    formik.errors.internalTitle ? (
                      <div className="text-danger">
                        {formik.errors.internalTitle}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-3">Email Body</label>
                    {currentCourseData?.body ? (
                      <SunEditor
                        width="100%"
                        setContents={formik.values.body}
                        onChange={(editorText) =>
                          formik.setFieldValue("body", editorText)
                        }
                        setOptions={{
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["undo", "redo"],
                            ["font", "fontSize"],
                            ["fontColor", "hiliteColor"],
                            ["align", "list", "lineHeight"],

                            ["table", "horizontalRule", "link"],
                          ],
                          defaultTag: "div",
                          minHeight: "300px",
                          showPathLabel: false,
                         
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    {formik.touched.body && formik.errors.body ? (
                      <div className="text-danger">{formik.errors.body}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-start">
              <button
                type="submit"
                className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
              >
                Update
              </button>
              <button
                type="button"
                className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
                onClick={handleClose}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default EditEmailTemplate;
