import React, { useState } from "react";

const EditableText = ({
  text,
  isHeading,
  level,
  className,
  name,
  onChange,
  strong,
  span,
}) => {
  const [isEditable, setIsEditable] = useState(false);
 
  const handleDoubleClick = () => {
    setIsEditable(true);
  };

  const handleChange = (e) => {
    onChange(e);
  };

  const handleBlur = () => {
    setIsEditable(false);
  };

  const Tag = span ? "span" : strong ? "strong" : isHeading ? `h${level}` : "p";

  return (
    <Tag className={className} onDoubleClick={handleDoubleClick}>
      {isEditable ? (
        <textarea
          style={{ width: "100%", height: "100%" }}
          type="text"
          value={text}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          name={name}
        />
      ) : (
        text
      )}
    </Tag>
  );
};

export default EditableText;
