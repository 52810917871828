import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
import moment from "moment";
import PaginationComponent from "../../pagination/PaginationComponent";

const Payments = () => {
  const { userData } = useContext(Context);
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const { getAllPaymentsApi } = useApiAxios();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const limit = 15;

  useEffect(() => {
    const fetchPayments = async (type, page, sort, search) => {
      setIsLoading(true);
      const res = await getAllPaymentsApi(type, page, limit, sort, search);
      setIsLoading(false);

      if (!res.error) {
        setPayments(res.data.data);
        setTotal(res.data.total);
      }
    };

    if (userData && userData.role) {
      const roles = userData.role;
      const type = roles.includes("admin") ? "physicalTherapist" : "patient";
      fetchPayments(type, page, sort, search);
    }
  }, [userData, page, sort, search]);

  const searchSubscriptionHandler = (e) => {
    setSearch(e.target.value);
  };

  const handleSort = (selectedSort) => {
    setSort(selectedSort);
    setPage(1);
  };

  return (
    <>
      <div className="row d-flex ">
        <div className="col-sm-5">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link to="/sales/payments">Sales </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Payments
              </li>
            </ol>
          </nav>
          <h1 className="page-title">
            Payments<span>({total})</span>
          </h1>
        </div>
      </div>
      <div className="row align-items-stretch">
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf h-100">
            <div className="p-3 pb-0">
              <div className="input-group search-table">
                <input
                  type="text"
                  className="form-control form-control-filter"
                  placeholder="Search Payments"
                  onChange={searchSubscriptionHandler}
                />
                <i className="icon-13 search-ic" />
                <div className="btn-group bulk-action">
                  <button
                    type="button"
                    className="filter-btn px-3 px-md-4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    <i className="fa fa-filter me-2" /> Filters
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("newest")}
                      >
                        Newest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("oldest")}
                      >
                        Oldest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("updated")}
                      >
                        Recently Updated
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="pb-4 companies-table">
              <div className="mt-2 table-responsive">
                <table
                  id="example"
                  className="table display table-edits"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                    <th className="text-center">S.No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Payment For</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((payment, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>
                          {payment?.admin
                            ? payment?.admin.name
                            : payment?.user?.name}
                        </td>
                        <td>
                          {payment?.admin
                            ? payment?.admin.email
                            : payment?.user?.email}
                        </td>
                        <td>$ {payment?.amount}</td>
                        <td>{payment?.status}</td>
                        <td>{payment?.paymentFor}</td>
                        <td>
                          {moment(payment?.createdAt).format("MM/DD/YYYY")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationComponent
        total={total}
        page={page}
        limit={limit}
        setPage={setPage}
      />
    </>
  );
};

export default Payments;
