import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import cros from "../../assets/images/cros.svg";
import useApiAxios from "../../api/useApiAxios";

import "suneditor/dist/css/suneditor.min.css";

const EditTagModel = ({ show, handleClose, fun, currentTag }) => {
  const { updateTagsApi } = useApiAxios();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const response = await updateTagsApi(currentTag._id, values);

      if (!response.error) {
        resetForm();
        await fun();
        handleClose();
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      name: currentTag.name || "",
    });
  }, [currentTag.name]);
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className="modal fade success-popup add-company"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Edit New Tag
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <img src={cros} alt="" />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-body text-start">
              <div className="row">
                <div className="col-lg-12 text-start"></div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-2">Name</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.name && formik.touched.name
                          ? "is-invalid"
                          : ""
                      }`}
                      value={formik.values.name}
                      placeholder="Name"
                      onChange={formik.handleChange("name")}
                      name="name"
                    />
                    {formik.errors.name && formik.touched.name && (
                      <div className="invalid-feedback">
                        {formik.errors.name}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-start">
              <button
                className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
                type="submit"
              >
                Continue
              </button>
              <button
                className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
                onClick={handleClose}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default EditTagModel;
