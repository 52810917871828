import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import cros from "../../assets/images/cros.svg";
import useApiAxios from "../../api/useApiAxios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Select from "react-select";
import { Link } from "react-router-dom";

const EditCourseVideosModule = ({
  show,
  handleClose,
  fun,
  currentVideoModule,
  selectEmailData,
}) => {
  const { updateVideoModuleApi } = useApiAxios();

  const formik = useFormik({
    initialValues: {
      title: currentVideoModule.title || "", // Set default value if title is undefined
      description: currentVideoModule.description || "", // Set default value if description is undefined
      thumbnail: currentVideoModule.thumbnail || "", // Set default value if thumbnail is undefined
      emailSequence: currentVideoModule.emailSequence || false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const response = await updateVideoModuleApi(
        currentVideoModule._id,
        values
      );

      if (!response.error) {
        resetForm();
        await fun();
        handleClose();
      }
    },
  });

  useEffect(() => {
    const dataToSetForEmailTemplate = [];
    for (
      let index = 0;
      index < currentVideoModule?.emailTemplates?.length;
      index++
    ) {
      const element = currentVideoModule?.emailTemplates[index];
      for (let index2 = 0; index2 < selectEmailData.length; index2++) {
        const element2 = selectEmailData[index2];
        if (element._id === element2.value) {
          dataToSetForEmailTemplate.push(element2);
        }
      }
    }
    const currentEmailTemplateData = selectEmailData.find(
      (element) => element?.value === currentVideoModule?.emailTemplate?._id
    );
    formik.setValues({
      title: currentVideoModule.title || "", // Set default value if title is undefined
      description: currentVideoModule.description || "", // Set default value if description is undefined
      emailSequence: currentVideoModule.emailSequence || false,
      emailTemplates: dataToSetForEmailTemplate,
      emailTemplate: currentEmailTemplateData,
    });
  }, [currentVideoModule.title]);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className="modal fade success-popup add-company"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Update Video Module
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <img src={cros} alt="" />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-body text-start">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-2">Title</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.title && formik.touched.title
                          ? "is-invalid"
                          : ""
                      }`}
                      value={formik.values.title}
                      placeholder="Title Name"
                      onChange={formik.handleChange("title")}
                      name="title"
                    />
                    {formik.errors.title && formik.touched.title && (
                      <div className="invalid-feedback">
                        {formik.errors.title}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-3">Brief Description</label>

                    {currentVideoModule.description ? (
                      <SunEditor
                        width="100%"
                        setContents={formik.values.description}
                        onChange={(editorText) =>
                          formik.setFieldValue("description", editorText)
                        }
                        setOptions={{
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["undo", "redo"],
                            ["font", "fontSize"],
                            ["fontColor", "hiliteColor"],
                            ["align", "list", "lineHeight"],

                            ["table", "horizontalRule", "link"],
                          ],
                          defaultTag: "div",
                          minHeight: "300px",
                          showPathLabel: false,
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    {formik.errors.description &&
                      formik.touched.description && (
                        <div className="invalid-feedback">
                          {formik.errors.description}
                        </div>
                      )}

                    {formik.errors.description &&
                      formik.touched.description && (
                        <div className="invalid-feedback">
                          {formik.errors.description}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <label className="form-label mt-2"> Email Sequence</label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      defaultChecked=""
                      checked={formik.values.emailSequence}
                      onChange={(e) =>
                        formik.setFieldValue("emailSequence", e.target.checked)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Turn on To Use as a email Sequence
                    </label>
                  </div>
                </div>
                {formik.values.emailSequence ? (
                  <div className="col-lg-12 mb-4">
                    <label className="form-label mt-2">
                      Select Internal Email Title
                    </label>
                    <Select
                      isSearchable={true}
                      isClearable={true}
                      isMulti
                      name="emailTemplate"
                      className="form-control"
                      // setValue={multiSelectValue}
                      value={formik.values.emailTemplates}
                      onChange={(e) => {
                        formik.setFieldValue("emailTemplates", e);
                      }}
                      options={selectEmailData}
                    />
                    {formik.touched.emailTemplate &&
                    formik.errors.emailTemplate ? (
                      <div className="error">{formik.errors.emailTemplate}</div>
                    ) : null}
                  </div>
                ) : (
                  <div className="col-lg-12 mb-4">
                    <label className="form-label mt-2">
                      Select Internal Email Title
                    </label>
                    <Select
                      isSearchable={true}
                      isClearable={true}
                      name="emailTemplate"
                      className="form-control"
                      value={formik.values.emailTemplate}

                      onChange={(e) => {
                        formik.setFieldValue("emailTemplate", e);
                      }}
                      options={selectEmailData}
                    />
                    {formik.touched.emailTemplate &&
                    formik.errors.emailTemplate ? (
                      <div className="error">{formik.errors.emailTemplate}</div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer justify-content-start">
              <button
                className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
                type="submit"
              >
                Continue
              </button>
              <Link
                className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
                onClick={handleClose}
              >
                Go Back
              </Link>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default EditCourseVideosModule;
