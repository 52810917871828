import React from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const EditQuestionAddOptionModal = ({
  show,
  handleClose,
  videos,
  setNextVideo,
  videoModule,
  question,
  setNextQuestion,
  setAddedVideoModule,
  createOptions,
  selectEmailData,
  setEmailTemplates,
  setEmailTemplate,
}) => {
  const initialValues = {
    title: "",
    finished: false,
    emailSequence: false,
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    createOptions(values);
    resetForm();
    handleClose();
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="modal fade success-popup add-company"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Add option Actions
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </div>
      <div className="modal-body text-start">
        <form onSubmit={formik.handleSubmit}>
          <div className="col-lg-12 text-start"></div>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Title</label>
            <input
              type="text"
              className={`form-control ${
                formik.errors.title && formik.touched.title ? "is-invalid" : ""
              }`}
              placeholder="Title Name"
              value={formik.values.title}
              onChange={formik.handleChange("title")}
            />
            {formik.errors.title && formik.touched.title && (
              <div className="invalid-feedback">{formik.errors.title}</div>
            )}
          </div>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Next Video</label>
            <Select
              isClearable={true}
              // isMulti
              name="video"
              className="form-control"
              onChange={(e) => {
                setNextVideo(e);
              }}
              options={videos}
            />
            {formik.touched.video && formik.errors.video ? (
              <div className="error">{formik.errors.video}</div>
            ) : null}
          </div>

          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Email Sequence</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                defaultChecked=""
                checked={formik.values.emailSequence}
                onChange={(e) => {
                  formik.setFieldValue("emailSequence", e.target.checked);
                }}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                Turn on To Use as a email Sequence
              </label>
            </div>
          </div>
          {formik.values.emailSequence ? (
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">
                Select Internal Email Title
              </label>
              <Select
                isClearable={true}
                isSearchable={true}
                isMulti
                name="emailTemplates"
                className="form-control"
                onChange={(e) => {
                  setEmailTemplates(e);
                  formik.setFieldValue("emailTemplates", e);
                }}
                options={selectEmailData}
              />
              {formik.touched.emailTemplate && formik.errors.emailTemplate ? (
                <div className="error">{formik.errors.emailTemplate}</div>
              ) : null}
            </div>
          ) : (
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">
                Select Internal Email Title
              </label>
              <Select
                isClearable={true}
                isSearchable={true}
                name="emailTemplate"
                className="form-control"
                onChange={(e) => {
                  setEmailTemplate(e);
                  formik.setFieldValue("emailTemplate", e);
                }}
                options={selectEmailData}
              />
              {formik.touched.emailTemplate && formik.errors.emailTemplate ? (
                <div className="error">{formik.errors.emailTemplate}</div>
              ) : null}
            </div>
          )}
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Select Video Module</label>
            <Select
              isClearable={true}
              isSearchable={true}
              isMulti
              name="video"
              className="form-control"
              onChange={(e) => {
                setAddedVideoModule(e);
              }}
              options={videoModule}
            />
            {formik.touched.video && formik.errors.video ? (
              <div className="error">{formik.errors.video}</div>
            ) : null}
          </div>

          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Next Question</label>
            <Select
              isSearchable={true}
              isClearable={true}
              name="question"
              className="form-control"
              onChange={(e) => {
                setNextQuestion(e);
              }}
              options={question}
            />
            {formik.touched.video && formik.errors.video ? (
              <div className="error">{formik.errors.video}</div>
            ) : null}
          </div>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Finished</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                defaultChecked=""
                checked={formik.values.finished}
                onChange={(e) =>
                  formik.setFieldValue("finished", e.target.checked)
                }
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                Turn on to Finished questions
              </label>
            </div>
          </div>

          <div className="modal-footer justify-content-start">
            <button
              className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
              type="submit"
            >
              Add
            </button>
            <button
              onClick={handleClose}
              className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditQuestionAddOptionModal;
