import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import PaginationComponent from "../../pagination/PaginationComponent";
import userDemo from "./../../assets/images/user-demo.svg";
import moment from "moment";
import { Context } from "../../context/Context";
import AddTagModel from "./../addTagModel/AddTagModel";
import ImageComponent from "../media/ImageComponent";

const EmailList = () => {
  const limit = 15;

  const {
    getAllEmailListApi,
    getEmailExportDataApi,
    getAllTagsApi,
    selectTagsEmailApi,
  } = useApiAxios();
  const [searchField, setSearchField] = useState("");
  const [email, setEmail] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("");
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [selectedAll, setSelectedAll] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagModel, setTagModel] = useState(false);
  const [currentTag, setCurrentTag] = useState(null);
  const { setShowEmailData, setShowEmail } = useContext(Context);
  const [count, setCount] = useState(0);
  const openEmailModal = () => {
    setShowEmail(true);
    setShowEmailData(email);
  };

  const selectTagFunction = async (tagId) => {
    const emailIds = [];
    for (let index = 0; index < email.length; index++) {
      const element = email[index];
      if (element.selected) {
        emailIds.push(element._id);
      }
    }
    const response = await selectTagsEmailApi(tagId, emailIds);
  };

  const searchEmailHandler = async (e) => {
    setSearch(e.target.value);
  };

  const searchTypeHandler = async (e) => {
    setType(e.target.value);
  };

  const handleEmailExportData = async () => {
    try {
      const response = await getEmailExportDataApi(type);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "emails.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const fun = async () => {
    const response = await getAllTagsApi();
    setTags(response.data.data);

    if (!searchField) {
      try {
        const data = await getAllEmailListApi(
          sort,
          search,
          page,
          limit,
          type,
          currentTag?._id
        );
        setEmail(data.data.data);
        setTotal(data.data.total);
      } catch (error) {}
    } else {
      setEmail([]);
      setTotal(0);
    }
  };

  useEffect(() => {
    let c = 0;
    email.forEach((item) => (item.selected ? (c += 1) : c));
    setCount(c);
  }, [email]);

  const closeTagModel = () => {
    setTagModel(false);
  };

  function handleIncrementClick(_id, checked, all) {
    if (!all) {
      setSelectedAll(false);
      const nextCounters = email.map((item) => {
        if (item._id === _id) {
          // Increment the clicked counter
          return { ...item, selected: checked };
        } else {
          // The rest haven't changed
          return item;
        }
      });
      setEmail(nextCounters);
    } else {
      if (!selectedAll) {
        setSelectedAll(true);
        setEmail(
          email.map((item) => {
            return { ...item, selected: checked };
          })
        );
      } else {
        setSelectedAll(false);
        setEmail(
          email.map((item) => {
            return { ...item, selected: false };
          })
        );
      }
    }
  }

  useEffect(() => {
    fun();
    document.title = "Email";
  }, [page, sort, search, searchField, type, currentTag?._id]);

  useEffect(() => {
    let c = 0;
    email.forEach((item) => (item.selected ? (c += 1) : c));
    setCount(c);
  }, [email]);

  const handleSort = async (selectedSort) => {
    setSort(selectedSort);
    setPage(1);
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-9">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">Email</li>
              <li className="breadcrumb-item active" aria-current="page">
                Email
              </li>
            </ol>
          </nav>
          <h1 className="page-title">
            Emails<span>({total})</span>
          </h1>
        </div>

        <div className="col-3 ps-0 text-end">
          <button
            onClick={handleEmailExportData}
            className="btn btn-sm btn-success"
          >
            Export
          </button>
        </div>
      </div>
      {/* Companies */}

      <div className="row align-items-stretch">
        {/* Companies */}
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf">
            <div className="p-3 pb-0">
              <div className="row">
                <div className="col-md-4 mb-3 mb-md-0">
                  <div className="filter-select">
                    <select
                      id=""
                      className="form-select"
                      onChange={searchTypeHandler}
                    >
                      <option value="" selected="">
                        All
                      </option>
                      <option value="physicalTherapist">
                        Physical Therapist{" "}
                      </option>
                      <option value="Patient">Patient </option>
                      <option value="employees">Employees </option>
                      <option value="company">Company</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="input-group mb-2 search-table">
                    <input
                      type="text"
                      className="form-control form-control-filter"
                      placeholder="Search Email"
                      onChange={searchEmailHandler}
                    />
                    <i className="icon-13 search-ic" />
                    <div className="btn-group bulk-action ">
                      <button
                        type="button"
                        className="filter-btn px-3 px-md-4"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                      >
                        <i className="fa fa-filter me-2" /> Filters
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleSort("newest")}
                          >
                            Newest
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleSort("oldest")}
                          >
                            Oldest
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleSort("updated")}
                          >
                            Recently Updated
                          </button>
                        </li>
                        <span>By Tag</span>
                        <li>
                          {tags.map((item) => (
                            <button
                              className={`dropdown-item ${
                                item?._id === currentTag?._id ? "active" : ""
                              }`}
                              type="button"
                              onClick={() => {
                                if (item?._id === currentTag?._id) {
                                  setCurrentTag(null);
                                } else {
                                  setCurrentTag(item);
                                }
                              }}
                            >
                              {item.name}
                            </button>
                          ))}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="companies-table">
                    <div class="text-end">
                      {email && email.some((item) => item.selected) ? (
                        <div className="mt-1">
                          <label className="col-form-label">
                            Selected {count}
                          </label>
                          <div className="btn-group bulk-action ms-2">
                            <button
                              type="button"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Bulk Actions
                            </button>
                            <ul
                              className="dropdown-menu dropdown-menu-end drop-tow mt-3"
                              style={{}}
                            >
                              <li>
                                <button
                                  className="dropdown-item "
                                  type="button"
                                  onClick={openEmailModal}
                                >
                                  Send Email
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item "
                                  type="button"
                                  onClick={() => setTagModel(true)}
                                >
                                  Add tag
                                </button>
                              </li>
                              {currentTag?.name ? (
                                <li>
                                  <button
                                    className="dropdown-item "
                                    type="button"
                                    onClick={openEmailModal}
                                  >
                                    Remove tags
                                  </button>
                                </li>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-4 companies-table">
              <div className="mt-2 table-responsive">
                <table
                  id="example"
                  className="table display table-edits"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Name: activate to sort column ascending"
                        style={{ width: 186 }}
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <input
                              className="form-check-input mr-1"
                              type="checkbox"
                              defaultValue=""
                              id="remember1"
                              checked={selectedAll}
                              onClick={() =>
                                handleIncrementClick("", true, true)
                              }
                            />
                            <label
                              className="form-check-label text-truncate d-flex align-items-center"
                              htmlFor="remember1"
                            >
                              Name
                            </label>
                          </div>
                        </span>
                      </th>

                      <th>Email</th>
                      <th className="text-nowrap">Phone</th>
                      <th className="text-nowrap">Type</th>
                      <th className="text-nowrap">Created At</th>

                      <th />
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {email.map((item, index) => (
                      <tr key={index}>
                        <td className="checked">
                          <span className="form-check d-flex align-items-center ">
                            <input
                              className="form-check-input mr-1"
                              type="checkbox"
                              checked={item?.selected}
                              onClick={(e) => {
                                
                                handleIncrementClick(
                                  item._id,
                                  e.target.checked
                                );
                              }}
                            />

                            <label
                              className="form-check-label text-truncate d-flex align-items-center"
                              htmlFor={`remember${index}`}
                            >
                              <span className="uusr_img me-2">
                                <ImageComponent
                                  src={
                                    item?.profileImage
                                      ? `${item?.profileImage}`
                                      : userDemo
                                  }
                                />
                              </span>
                              {item?.type === "Patient"
                                ? item?.user?.name
                                : item?.type === "employees"
                                ? item?.user?.name
                                : item?.type === "company"
                                ? item?.company?.name
                                : item?.type === "physicalTherapist"
                                ? item?.physicalTherapist?.name
                                : "n/a"}
                            </label>
                          </span>
                        </td>
                        <td className="text-truncate">{item.email}</td>

                        <td className="text-nowrap">
                          {" "}
                          {item?.type === "Patient"
                            ? item?.user?.phone
                            : item?.type === "employees"
                            ? item?.user?.phone
                            : item?.type === "company"
                            ? item?.company?.phone
                            : item?.type === "physicalTherapist"
                            ? item?.physicalTherapist?.phone
                            : "n/a"}
                        </td>
                        <td className="text-truncate">{item.type}</td>
                        <td className="text-nowrap">
                          {moment(item.createdAt).format("MM/DD/YYYY")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* /Companies */}
      </div>
      {/*/Row*/}
      {/* Companies */}

      <PaginationComponent
        total={total}
        page={page}
        limit={limit}
        setPage={setPage}
      />
      <AddTagModel
        show={tagModel}
        handleClose={closeTagModel}
        data={tags}
        selectTagFunction={selectTagFunction}
      />
    </>
  );
};

export default EmailList;
