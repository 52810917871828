import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import cros from "../../assets/images/cros.svg";

const AddTagModel = ({ show, handleClose, data, selectTagFunction }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [count, setCount] = useState(0);

  const handleTagClick = (tagId) => {
    const newSelectedTags = selectedTags.includes(tagId)
      ? selectedTags.filter((id) => id !== tagId)
      : [...selectedTags, tagId];

    setSelectedTags(newSelectedTags);
    setCount(count + 1);
    // selectTagFunction(newSelectedTags); // Pass the updated selected tags array to the parent
  };
  useEffect(() => {
    setSelectedTags(selectedTags);
  }, [count]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="modal fade success-popup add-company"
    >
      <div className="modal-body text-start">
        <div className="modal-header " style={{ padding: 0 }}>
          <h5 className="modal-title" id="staticBackdropLabel">
            Select Tags
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <img src={cros} alt="" />
          </button>
        </div>
        <div className="modal-body text-start">
          <div>
            {data.map((item) => (
              <a
                key={item._id}
                className={`${
                  selectedTags.includes(item._id) ? "btn-selected" : ""
                } btn action-btn m-2 `}
                type="button"
                onClick={() => handleTagClick(item._id)}
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>
        <div className="modal-footer justify-content-start">
          <button
            href=""
            className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
            type="submit"
            onClick={() => {
              selectTagFunction(selectedTags);
              handleClose();
            }}
          >
            Add Tags
          </button>
          <button
            href=""
            onClick={handleClose}
            className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddTagModel;
