import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";

const Subscription = () => {
  const [offer, setOffer] = useState({});
  const { userData } = useContext(Context);
  const {  getOfferById, createSubscription } = useApiAxios();
  const fun = async () => {
    const data = await getOfferById(userData.offer);

    if (!data.error) {
      setOffer(data.data.data);
      const res = await createSubscription(
        userData.priceStripeId,
        data.data.data._id
      );
      if (res.error) {
      } else {
        window.location.href = res.data.data;
      }
    }
  };

  useEffect(() => {
    fun();
  }, []);

  return <div>Subscription</div>;
};

export default Subscription;
