import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const EditOptionModal = ({
  show,
  handleClose,
  videos,
  setNextVideo,
  videoModule,
  question,
  setNextQuestion,
  setAddedVideoModule,
  editOptions,
  currentOptionAction,
  selectEmailData,
  setEmailTemplate,
  setEmailTemplates,
}) => {
  const initialValues = {
    title: "",
    finished: false,
    video: "",
    emailSequence: false,
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
  });
  const defaultVideoRef = useRef();
  const [defaultVideo, setDefaultVideo] = useState({});
  const [defaultNextQuestion, setDefaultNextQuestion] = useState(false);
  const [defaultEmailTemplate, setDefaultEmailTemplate] = useState(false);
  const [defaultEmailTemplates, setDefaultEmailTemplates] = useState(false);
  const [defaultAddedVideoModule, setDefaultAddedVideoModule] = useState(false);
  const handleCloseThisModal = () => {
    handleClose();
    setDefaultVideo({});
    setDefaultAddedVideoModule(false);
    setDefaultNextQuestion(false);
    setDefaultEmailTemplate();
  };
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    editOptions({ ...values }, currentOptionAction._id);
    resetForm();
    handleCloseThisModal();
  };

  useEffect(() => {
    formik.setValues({
      video: videos.find(
        (element) => element?.value === currentOptionAction?.nextVideo?._id
      ),
      videoModule: videos.find(
        (element) => element?.value === currentOptionAction?.nextVideo?._id
      ),
      title: currentOptionAction?.title || "",
      finished: currentOptionAction?.finished,
      emailSequence: currentOptionAction?.emailSequence,
    });
    if (defaultVideoRef.current && currentOptionAction?.title) {
      defaultVideoRef.current.defaultValue = videos.find(
        (element) => element?.value === currentOptionAction?.nextVideo?._id
      );
    }
    const defaultVideoData = videos.find(
      (element) => element?.value === currentOptionAction?.nextVideo?._id
    );

    setDefaultVideo(defaultVideoData);
    setNextVideo(defaultVideoData);

    const currentOptionActionData = question.find(
      (element) => element?.value === currentOptionAction?.nextQuestion?._id
    );
    setDefaultNextQuestion(currentOptionActionData);
    setNextQuestion(currentOptionActionData);

    const currentEmailTemplateData = selectEmailData.find(
      (element) => element?.value === currentOptionAction?.emailTemplate?._id
    );

    setDefaultEmailTemplate(currentEmailTemplateData);
    setEmailTemplate(currentEmailTemplateData);

    const dataToSetForEmailTemplate = [];
    for (
      let index = 0;
      index < currentOptionAction?.emailTemplates?.length;
      index++
    ) {
      const element = currentOptionAction?.emailTemplates[index];
      for (let index2 = 0; index2 < selectEmailData.length; index2++) {
        const element2 = selectEmailData[index2];
        if (element._id === element2.value) {
          dataToSetForEmailTemplate.push(element2);
        }
      }
    }

    setDefaultEmailTemplates(dataToSetForEmailTemplate);
    setEmailTemplates(dataToSetForEmailTemplate);
    const dataToSet = [];
    for (
      let index = 0;
      index < currentOptionAction?.addedVideoModule?.length;
      index++
    ) {
      const element = currentOptionAction?.addedVideoModule[index];
      for (let index2 = 0; index2 < videoModule.length; index2++) {
        const element2 = videoModule[index2];
        if (element._id === element2.value) {
          dataToSet.push(element2);
        }
      }
    }

    setDefaultAddedVideoModule(dataToSet);
    setAddedVideoModule(dataToSet);
  }, [currentOptionAction?.title]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  console.log(formik.values.emailSequence);

  return (
    <Modal
      show={show}
      onHide={handleCloseThisModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="modal fade success-popup add-company"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Edit option
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleCloseThisModal}
        ></button>
      </div>
      <div className="modal-body text-start">
        <form onSubmit={formik.handleSubmit}>
          <div className="col-lg-12 text-start"></div>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Title</label>
            <input
              type="text"
              className={`form-control ${
                formik.errors.title && formik.touched.title ? "is-invalid" : ""
              }`}
              placeholder="Title Name"
              value={formik.values.title}
              onChange={formik.handleChange("title")}
            />
            {formik.errors.title && formik.touched.title && (
              <div className="invalid-feedback">{formik.errors.title}</div>
            )}
          </div>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Next Video</label>

            <Select
              isClearable={true}
              isSearchable={true}
              name="video"
              className="form-control"
              value={defaultVideo}
              onChange={(e) => {
                setNextVideo(e);
                setDefaultVideo(e);
              }}
              options={videos}
            />

            {formik.touched.video && formik.errors.video ? (
              <div className="error">{formik.errors.video}</div>
            ) : null}
          </div>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2"> Email Sequence</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                defaultChecked=""
                checked={formik.values.emailSequence}
                onChange={(e) =>
                  formik.setFieldValue("emailSequence", e.target.checked)
                }
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                Turn on To Use as a email Sequence
              </label>
            </div>
          </div>
          {formik.values.emailSequence ? (
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">
                Select Internal Email Title
              </label>
              <Select
                isClearable={true}
                isSearchable={true}
                isMulti
                name="emailTemplates"
                className="form-control"
                value={defaultEmailTemplates}
               
                onChange={(e) => {
                  formik.setFieldValue("emailTemplates", e);
                  setDefaultEmailTemplates(e);
                  setEmailTemplates(e);
                }}
                options={selectEmailData}
              />
              {formik.touched.emailTemplate && formik.errors.emailTemplate ? (
                <div className="error">{formik.errors.emailTemplate}</div>
              ) : null}
            </div>
          ) : (
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">
                Select Internal Email Title
              </label>
              <Select
                isClearable={true}
                isSearchable={true}
                name="emailTemplate"
                className="form-control"
                value={defaultEmailTemplate}
              
                onChange={(e) => {
                  formik.setFieldValue("emailTemplate", e);
                  setDefaultEmailTemplate(e);
                  setEmailTemplate(e);
                }}
                options={selectEmailData}
              />
              {formik.touched.emailTemplate && formik.errors.emailTemplate ? (
                <div className="error">{formik.errors.emailTemplate}</div>
              ) : null}
            </div>
          )}

          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Select Video Module</label>

            <Select
              isClearable={true}
              isSearchable={true}
              isMulti
              name="video"
              className="form-control"
              value={defaultAddedVideoModule}
              onChange={(e) => {
                setAddedVideoModule(e);
              
                setDefaultAddedVideoModule(e);
              }}
              options={videoModule}
            />

            {formik.touched.video && formik.errors.video ? (
              <div className="error">{formik.errors.video}</div>
            ) : null}
          </div>

          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Next Question</label>

            <Select
              isClearable={true}
              isSearchable={true}
             
              name="video"
              className="form-control"
             
              value={defaultNextQuestion}
              onChange={(e) => {
                setNextQuestion(e);
                setDefaultNextQuestion(e);
              }}
              options={question}
            />

            {formik.touched.video && formik.errors.video ? (
              <div className="error">{formik.errors.video}</div>
            ) : null}
          </div>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Finished</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                defaultChecked=""
                checked={formik.values.finished}
                onChange={(e) =>
                  formik.setFieldValue("finished", e.target.checked)
                }
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                Turn on to Finished questions
              </label>
            </div>
          </div>

          <div className="modal-footer justify-content-start">
            <button
              className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
              type="submit"
            >
              Update
            </button>
            <button
              onClick={handleCloseThisModal}
              className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditOptionModal;
