import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";


const Hab = () => {
  const { getAllPainHab } = useApiAxios();
  const [company, setCompany] = useState([]);

  const fun = async () => {
    const data = await getAllPainHab();
    setCompany(data.data.data);
  };

  useEffect(() => {
    fun();
    document.title = "Question";
  }, []);

  return (
    <>
      <div className="row d-flex ">
        <div className="col-sm-5">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">Hab</li>
              <li className="breadcrumb-item active" aria-current="page">
                Hab
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Companies */}
      <div className="row align-items-stretch">
        {/* Companies */}
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf h-100">
            <div className="p-30 pb-0"></div>
            <div className="pb-4 companies-table">
              <div class="mt-2"></div>

              <div className="mt-2 table-responsive">
                <table
                  id="example"
                  className="table display table-edits"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <label
                              className="form-check-label text-truncate"
                              htmlFor="remember1"
                            >
                              Name
                            </label>
                          </div>
                        </span>
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <label
                              className="form-check-label text-truncate"
                              htmlFor="remember1"
                            >
                              Hab
                            </label>
                          </div>
                        </span>
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <label
                              className="form-check-label text-truncate"
                              htmlFor="remember1"
                            >
                              Type
                            </label>
                          </div>
                        </span>
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <label
                              className="form-check-label text-truncate"
                              htmlFor="remember1"
                            >
                              Pain Level From
                            </label>
                          </div>
                        </span>
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <label
                              className="form-check-label text-truncate"
                              htmlFor="remember1"
                            >
                              Pain Level To
                            </label>
                          </div>
                        </span>
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <label
                              className="form-check-label text-truncate"
                              htmlFor="remember1"
                            >
                              Question View
                            </label>
                          </div>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {company.map((companyData, index) => (
                      <tr key={index}>
                        <td className="checked">
                          <span className="form-check d-flex align-items-center ">
                            {companyData.name}
                          </span>
                        </td>
                        <td className="text-truncate">{companyData.hab}</td>
                        <td className="text-truncate">{companyData.type}</td>
                        <td className="text-truncate">
                          {companyData.painLevelFrom}
                        </td>
                        <td className="text-truncate">
                          {companyData.painLevelTo}
                        </td>

                        <td>
                          <Link
                            to={`/question/${companyData._id}`}
                            className="btn action-btn"
                          >
                            <i className="fa-solid fa-eye"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* /Companies */}
      </div>
      {/*/Row*/}
      {/* Companies */}
    </>
  );
};

export default Hab;
