import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";

import DeleteModel from "../deleteModel/DeleteModel";

const Question = () => {
  const { habId } = useParams();
  const { getQuestionByHabIdApi, deleteQuestionApi } = useApiAxios();
  const [company, setCompany] = useState([]);

  const fun = async () => {
    const data = await getQuestionByHabIdApi(habId);
    setCompany(data.data.data);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const openDeleteModel = (data) => {
    setDeleteData(data);
    setDeleteModal(true);
  };
  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };

  const deleteQuestionHandler = async (data) => {
    await deleteQuestionApi(data);
    await fun();
    closeDeleteModel();
  };

  useEffect(() => {
    fun();
    document.title = "Question";
  }, []);

  return (
    <>
      <div className="row d-flex ">
        <div className="col-sm-5">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link to="/question">Hab</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Question
              </li>
            </ol>
          </nav>
          <h1 className="page-title">Question</h1>
        </div>

        <div className="col-sm-12 pb-3 mb-sm-0 d-flex justify-content-start justify-content-sm-end align-items-center mb-3">
          <Link to={`/question/add/${habId}`} className="btn company-btn">
            <i className="icon-19" /> Add Question
          </Link>
        </div>
      </div>
      {/* Companies */}
      <div className="row align-items-stretch">
        {/* Companies */}
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf h-100">
            <div className="p-30 pb-0"></div>
            <div className="pb-4 companies-table">
              <div class="mt-2"></div>

              <div className="mt-2 table-responsive">
                <table
                  id="example"
                  className="table display table-edits"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <label
                              className="form-check-label text-truncate"
                              htmlFor="remember1"
                            >
                              Title
                            </label>
                          </div>
                        </span>
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <label
                              className="form-check-label text-truncate"
                              htmlFor="remember1"
                            >
                              First Question
                            </label>
                          </div>
                        </span>
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <label
                              className="form-check-label text-truncate"
                              htmlFor="remember1"
                            >
                              Question Id
                            </label>
                          </div>
                        </span>
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <label
                              className="form-check-label text-truncate"
                              htmlFor="remember1"
                            >
                              Action
                            </label>
                          </div>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {company.map((companyData, index) => (
                      <tr key={index}>
                        <td className="checked">
                          <span className="form-check d-flex align-items-center ">
                            {companyData.title}
                          </span>
                        </td>
                        <td className="text-truncate">
                          {companyData.firstQuestion.toString()}
                        </td>
                        <td className="text-truncate">
                          {companyData.questionId}
                        </td>

                        <td>
                          <Link
                            to={`/question/edit/${companyData._id}`}
                            className="btn action-btn"
                          >
                            <i className="fa-solid fa-pencil" />
                          </Link>

                          <Link
                            className="btn action-btn"
                            onClick={() => openDeleteModel(companyData._id)}
                          >
                            <i className="fa-solid fa-trash" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <DeleteModel
          show={deleteModal}
          handleClose={closeDeleteModel}
          data={deleteData}
          deleteFunction={deleteQuestionHandler}
        />
        {/* /Companies */}
      </div>
      {/*/Row*/}
      {/* Companies */}
    </>
  );
};

export default Question;
