import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
import moment from "moment";
import PaginationComponent from "../../pagination/PaginationComponent";

const AppointmentLead = () => {
  const { userData } = useContext(Context);
  const [payments, setPayments] = useState([]);
  const { getAllAppointmentLeadApi } = useApiAxios();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const limit = 15;

  const fetchPayments = async () => {
    const res = await getAllAppointmentLeadApi(page, limit);

    if (!res.error) {
      setPayments(res.data.data);
      setTotal(res.data.total);
    }
  };
  useEffect(() => {
    fetchPayments();
  }, [page]);

  return (
    <>
      <div className="row d-flex ">
        <div className="col-sm-5">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link to="#">Appointment </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Appointment Leads
              </li>
            </ol>
          </nav>
          <h1 className="page-title">
            Appointment Leads<span>({total})</span>
          </h1>
        </div>
      </div>
      <div className="row align-items-stretch">
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf">
            <div className="p-30 pb-0"></div>
            <div className="pb-4 companies-table">
              <div className="mt-2 table-responsive">
                <table
                  id="example"
                  className="table display table-edits"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Contact</th>
                      <th>Address</th>
                      {userData.role === "admin" ? (
                        <>
                          {" "}
                          <th>PT Name</th>
                          <th>PT Email</th>
                        </>
                      ) : (
                        <></>
                      )}

                      <th>Clicked At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((payment, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{payment.user?.name}</td>
                        <td>{payment.user?.email}</td>
                        <td>{payment.user?.phone}</td>
                        <td>
                          {payment.user?.address} {payment.user?.pincode}
                        </td>
                        {userData.role === "admin" ? (
                          <>
                            <td>{payment.physicalTherapist?.name}</td>
                            <td>{payment.physicalTherapist?.email}</td>
                          </>
                        ) : (
                          <></>
                        )}
                        <td>
                          {moment(payment.createdAt).format(
                            "MM/DD/YYYY  hh:mm"
                          )}{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationComponent
        total={total}
        page={page}
        limit={limit}
        setPage={setPage}
      />
    </>
  );
};

export default AppointmentLead;
