import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import cros from "../../assets/images/cros.svg";
import useApiAxios from "../../api/useApiAxios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ImageComponent from "../media/ImageComponent";
const EditCourses = ({
  show,
  handleClose,
  currentCourseData,
  fun,
  imageUrl,
  setImageUrl,
  image,
  setImage,
}) => {
  const { updateCourseApi, updateCourseIconApi } = useApiAxios();
  const formik = useFormik({
    initialValues: {
      title: currentCourseData.title,
      description: currentCourseData.description,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values) => {
      try {
        const data = await updateCourseApi(currentCourseData._id, values);
        if (image) {
          const formData = new FormData();

          formData.append("icon", image);
          const data2 = await updateCourseIconApi(
            currentCourseData._id,
            formData
          );
        }
        handleClose();
        fun();
      } catch (error) {}
    },
  });
  useEffect(() => {
    formik.setValues({
      title: currentCourseData.title,
      description: currentCourseData.description,
      type: currentCourseData.type,
    });
  }, [currentCourseData.title]);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal fade success-popup add-company"
        id="addcompany"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-content">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Update Course
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <img src={cros} alt="" />
              </button>
            </div>
            <div className="modal-body text-start">
              <div className="row">
                <div className="col-lg-12 text-start">
                  <p className="course-title text-start ">Course Details</p>
                  <p className="course-subtitle text-start mb-0">
                    We'll use your title and description to generate a sample
                    course outline:
                  </p>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-2">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="titleInput"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                    
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <div className="text-danger">{formik.errors.title}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-3">Brief Description</label>
                    {currentCourseData.description ? (
                      <SunEditor
                        width="100%"
                        setContents={formik.values.description}
                        onChange={(editorText) =>
                          formik.setFieldValue("description", editorText)
                        }
                        setOptions={{
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["undo", "redo"],
                            ["font", "fontSize"],
                            ["fontColor", "hiliteColor"],
                            ["align", "list", "lineHeight"],

                            ["table", "horizontalRule", "link"],
                          ],
                          defaultTag: "div",
                          minHeight: "300px",
                          showPathLabel: false,
                          
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {formik.touched.description && formik.errors.description ? (
                      <div className="text-danger">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-3">Icon</label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/png, image/gif, image/jpeg"
                      id="iconInput"
                      name="icon"
                      value={formik.values.icon}
                      onChange={(event) => {
                        const file = event.target.files[0];
                        setImage(file);

                        const reader = new FileReader();
                        reader.onload = () => {
                          setImageUrl(reader.result);
                        };
                        reader.readAsDataURL(file);
                      }}
                    />
                    <div>
                      {image ? (
                        <img className="minHeight200" src={imageUrl} alt="" />
                      ) : (
                        <ImageComponent
                          className="minHeight200"
                          src={currentCourseData?.icon}
                        />
                      )}
                    </div>
                    {formik.touched.icon && formik.errors.icon ? (
                      <div className="text-danger">{formik.errors.icon}</div>
                    ) : null}
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-start">
              <button
                type="submit"
                className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
              >
                Continue
              </button>
              <button
                type="button"
                className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
                onClick={handleClose}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default EditCourses;
