import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import EditableText from "./EditableText";
import EditableImage from "./EditableImage";
import VideoComponent from "../media/VideoComponent";

const EmployersContent = () => {
  const { getWebsiteContent, updateWebsiteContent, updateWebsiteContentVideo } =
    useApiAxios();
  const [content, setContent] = useState({});

  const [videoSrc, setVideoSrc] = useState(null);

  const init = async () => {
    const { data, error } = await getWebsiteContent();
    if (!error) {
      setContent(data.data);
      setVideoSrc(data.data.video1);
    }
  };
  const updateData = async () => {
    await updateWebsiteContent(content);
  };

  const onChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    init();
  }, []);

  const inputRef = useRef(null);

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setVideoSrc(e.target.result);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("video", file);
      await updateWebsiteContentVideo(formData, "video1");
      await init();
    }
  };

  return (
    <div>
      <div className="container-fluid banner_area pe-0">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-5 order-2 order-md-1">
            <EditableText
              text={content.t40}
              isHeading={true}
              level={1}
              className="banner-title mt-4 mt-md-0"
              name={"t40"}
              onChange={onChange}
            />

            <EditableText
              text={content.t41}
              isHeading={true}
              level={6}
              className="banner-subtitle"
              name={"t41"}
              onChange={onChange}
            />

            <Link to="/contact" className="book1-btn">
              GET A DEMO
            </Link>
          </div>
          <div className="col-md-7 order-1 order-md-2 text-end">
            <EditableImage
              src={`${content?.img12}`}
              title="Wellness video platform to prevent & reduce pain"
              alt=""
              className="img-fluid pt-5 banner-img"
              name="img12"
            />
          </div>
        </div>
      </div>

      <div className="container my-4">
        <div className="row">
          <div className="col-sm-12">
            <EditableText
              text={content.t42}
              isHeading={true}
              level={1}
              className="home_title text-center mb-4 mb-xl-5"
              name={"t42"}
              onChange={onChange}
            />
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                <strong>
                  <EditableText
                    text={content.t43}
                    isHeading={true}
                    level={1}
                    strong={true}
                    className=""
                    name={"t43"}
                    onChange={onChange}
                  />
                  <EditableText
                    text={content.t44}
                    span={true}
                    level={1}
                    className="text_green"
                    name={"t44"}
                    onChange={onChange}
                  />
                </strong>
                <EditableText
                  text={content.t45}
                  span={true}
                  level={1}
                  className=""
                  name={"t45"}
                  onChange={onChange}
                />
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <EditableText
                  text={content.t46}
                  span={true}
                  level={1}
                  className=""
                  name={"t46"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t47}
                  strong={true}
                  level={1}
                  className=""
                  name={"t47"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t48}
                  span={true}
                  level={1}
                  className=""
                  name={"t48"}
                  onChange={onChange}
                />
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <EditableText
                  text={content.t49}
                  strong={true}
                  level={1}
                  className=""
                  name={"t49"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t50}
                  span={true}
                  level={1}
                  className=""
                  name={"t50"}
                  onChange={onChange}
                />
                <strong>
                  <EditableText
                    text={content.t51}
                    span={true}
                    level={1}
                    className="text_green"
                    name={"t51"}
                    onChange={onChange}
                  />
                </strong>
              </li>
            </ul>
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                <EditableText
                  text={content.t52}
                  span={true}
                  level={1}
                  className=""
                  name={"t52"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t53}
                  strong={true}
                  level={1}
                  className=""
                  name={"t53"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t54}
                  span={true}
                  level={1}
                  className=""
                  name={"t54"}
                  onChange={onChange}
                />
              </li>
              <li>
                <i className="fas fa-check-circle" />

                <EditableText
                  text={content.t55}
                  span={true}
                  level={1}
                  className=""
                  name={"t55"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t56}
                  strong={true}
                  level={1}
                  className=""
                  name={"t56"}
                  onChange={onChange}
                />
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <EditableText
                  text={content.t57}
                  strong={true}
                  level={1}
                  className=""
                  name={"t57"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t58}
                  span={true}
                  level={1}
                  className=""
                  name={"t58"}
                  onChange={onChange}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <>
        <div className="video_shade mt-4 mb-5">
          <div className="container mb-3">
            <div className="row">
              <div className="col-sm-12">
                {videoSrc ? <VideoComponent src={videoSrc} /> : <></>}
              </div>
            </div>
          </div>
          <div className="m-3">
            <button className="virt_btn green" onClick={handleButtonClick}>
              Upload Video
            </button>
            <input
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>

        <div className="container my-4">
          <div className="row">
            <div className="col-sm-12">
              <EditableText
                text={content.t59}
                isHeading={true}
                level={1}
                className="home_title text-center mb-4 mb-xl-5"
                name={"t59"}
                onChange={onChange}
              />
            </div>
            <div className="col-sm-6">
              <ul className="why-us">
                <li>
                  <i className="fas fa-check-circle" />
                  <EditableText
                    text={content.t60}
                    span={true}
                    level={1}
                    className=""
                    name={"t60"}
                    onChange={onChange}
                  />

                  <EditableText
                    text={content.t61}
                    strong={true}
                    level={1}
                    className=""
                    name={"t61"}
                    onChange={onChange}
                  />
                  <EditableText
                    text={content.t62}
                    span={true}
                    level={1}
                    className=""
                    name={"t62"}
                    onChange={onChange}
                  />
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  <EditableText
                    text={content.t63}
                    span={true}
                    level={1}
                    className=""
                    name={"t63"}
                    onChange={onChange}
                  />
                  <EditableText
                    text={content.t64}
                    strong={true}
                    level={1}
                    className=""
                    name={"t64"}
                    onChange={onChange}
                  />
                  <EditableText
                    text={content.t65}
                    span={true}
                    level={1}
                    className=""
                    name={"t65"}
                    onChange={onChange}
                  />
                </li>
              </ul>
            </div>
            <div className="col-sm-6">
              <ul className="why-us">
                <li>
                  <i className="fas fa-check-circle" />
                  <EditableText
                    text={content.t66}
                    span={true}
                    level={1}
                    className=""
                    name={"t66"}
                    onChange={onChange}
                  />
                  <EditableText
                    text={content.t67}
                    strong={true}
                    level={1}
                    className=""
                    name={"t67"}
                    onChange={onChange}
                  />
                  <EditableText
                    text={content.t68}
                    span={true}
                    level={1}
                    className=""
                    name={"t68"}
                    onChange={onChange}
                  />
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  <EditableText
                    text={content.t69}
                    strong={true}
                    level={1}
                    className=""
                    name={"t69"}
                    onChange={onChange}
                  />
                  <EditableText
                    text={content.t70}
                    span={true}
                    level={1}
                    className=""
                    name={"t70"}
                    onChange={onChange}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="musculoskeletal mb-4 mb-xl-5 mt-4 mt-xl-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 p-5">
                <EditableText
                  text={content.t71}
                  isHeading={true}
                  level={1}
                  className="musculoskeletal-title"
                  name={"t71"}
                  onChange={onChange}
                />
              </div>
              <div className="col-lg-6">
                <div className="bg-white rounded-4 p-4">
                  <EditableText
                    text={content.t72}
                    isHeading={true}
                    level={2}
                    className="text-center"
                    name={"t72"}
                    onChange={onChange}
                  />
                  <EditableText
                    text={content.t73}
                    isHeading={false}
                    level={2}
                    className="home_subtext text-center mb-3"
                    name={"t73"}
                    onChange={onChange}
                  />

                  <div className="text-center">
                    <button className="btn btn-danger rounded-5">
                      Get The Paper
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      {/* serve */}
      <div className="container mb-4">
        <div className="row justify-content-center">
          <div className="col-xl-7">
            <EditableText
              text={content.t74}
              isHeading={true}
              level={1}
              className="home_title text-center mb-3"
              name={"t74"}
              onChange={onChange}
            />
            <EditableText
              text={content.t75}
              isHeading={false}
              level={1}
              className="home_subtext text-center mb-3"
              name={"t75"}
              onChange={onChange}
            />

            <div className="row">
              <div className="col-md-12 text-center">
                <Link to="/contact" className="book1-btn">
                  GET A DEMO
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="m-5">
        <button className="virt_btn green" onClick={updateData}>
          Update Data
        </button>
      </div>
    </div>
  );
};

export default EmployersContent;
