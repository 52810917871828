import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import EditableText from "./EditableText";
import EditableImage from "./EditableImage";

const HomeContent = () => {
  const { getWebsiteContent, updateWebsiteContent } = useApiAxios();
  const [content, setContent] = useState({});
  const init = async () => {
    const { data, error } = await getWebsiteContent();
    if (!error) {
      setContent(data.data);
    }
  };
  const updateData = async () => {
    await updateWebsiteContent(content);
  };

  const onChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <div className="container-fluid banner_area">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-5 order-2 order-md-1">
            <EditableText
              text={content.t1}
              isHeading={true}
              level={1}
              className="banner-title mt-4 mt-md-0"
              name={"t1"}
              onChange={onChange}
            />
            <EditableText
              text={content.t2}
              isHeading={true}
              level={6}
              className="banner-subtitle"
              name={"t2"}
              onChange={onChange}
            />

            <Link className="book1-btn">BOOK A DEMO</Link>
          </div>
          <div className="col-md-7 order-1 order-md-2 text-end">
            <EditableImage
              src={`${content?.img1}`}
              title="Wellness video platform to prevent & reduce pain"
              alt=""
              className="img-fluid pt-5 banner-img"
              name="img1"
            />
          </div>
        </div>
      </div>
      <div className="container my-4 my-xl-5 pt-4 pt-xl-5">
        <div className="row">
          <div className="col-sm-6">
            <div className="jurny_area">
              <EditableImage
                src={`${content?.img2}`}
                title="Employers"
                alt=""
                className=""
                name="img2"
              />
              <EditableText
                text={content.t3}
                isHeading={true}
                level={3}
                className=""
                name={"t3"}
                onChange={onChange}
              />
              <EditableText
                text={content.t5}
                isHeading={false}
                level={0}
                className=""
                name={"t5"}
                onChange={onChange}
              />

              <Link to="/employers" className="virt_btn green">
                Hanna Health for Businesses
              </Link>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="jurny_area">
              <EditableImage
                src={`${content.img3}`}
                title="Employers"
                alt=""
                className=""
                name="img3"
              />

              <EditableText
                text={content.t4}
                isHeading={true}
                level={3}
                className=""
                name={"t4"}
                onChange={onChange}
              />
              <EditableText
                text={content.t6}
                isHeading={false}
                level={0}
                className=""
                name={"t6"}
                onChange={onChange}
              />

              <Link to="/physical-therapists" className="virt_btn purpel">
              Hanna Health for Physical Therapists
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9">
            <EditableText
              text={content.t7}
              isHeading={true}
              level={1}
              className="home_title text-center mb-3"
              name={"t7"}
              onChange={onChange}
            />
            <EditableText
              text={content.t8}
              isHeading={false}
              level={0}
              className="home_subtext text-center"
              name={"t8"}
              onChange={onChange}
            />

            <div className="row">
              <div className="col-md-6 text-center">
                <Link to="/employers" className="virt_btn green">
                Hanna Health for Businesses
                </Link>
              </div>
              <div className="col-md-6 text-center">
                <Link to="/physical-therapists" className="virt_btn purpel">
                Hanna Health for Physical Therapists
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-5">
        <button className="virt_btn green" onClick={updateData}>
          Update Data
        </button>
      </div>
    </div>
  );
};

export default HomeContent;
