import Modal from "react-bootstrap/Modal";
import React from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";

const ViewInquiryModal = ({
  show,
  handleClose,
  data,
  reply,
  setReply,
  title,
}) => {
  const { replyContactApi } = useApiAxios();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="modal fade success-popup add-company"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Inquiry
        </h5>
      </div>
      <div className="modal-body text-start">
        <div>
          Name : {data.firstName} {data.lastName}
        </div>
        <div>Email : {data.email}</div>
        <div>Phone : {data.phone}</div>
        <div>Organization : {data.organization}</div>
        <div>Inquiry About : {data.inquiryAbout === ""}</div>
        <div>Query : {data.query}</div>
        <div>Inquiry At : {new Date(data.createdAt).toLocaleString()}</div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            replyContactApi({ id: data._id, reply, title });
            handleClose();
          }}
        >
    
          <label htmlFor="query" className="col-sm-12 col-form-label">
            Reply
          </label>
          <div class="col-sm-12 ">
            <textarea
              id="query"
              name="query"
              className="form-control"
              onChange={(e) => setReply(e.target.value)}
              value={reply}
              maxLength={500}
            />
            <p className="text-end">
              <small className="text-end">{reply.length}/500</small>
            </p>
          </div>

          <div className="modal-footer justify-content-start">
            <button
              className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
              type="submit"
            >
              Reply
            </button>
            <Link
              onClick={handleClose}
              className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
            >
              Close
            </Link>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ViewInquiryModal;
