import React, { useEffect, useState } from "react";
import cros from "../../assets/images/cros.svg";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import moment from "moment";

const EditPhysicalTherapistPatient = ({
  show,
  handleClose,
  fun,
  currentPatient,
}) => {
  const { getPatientApi, updatePhysicalTherapistPatient, getAllTagsApi } =
    useApiAxios();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    formik.setValues({
      name: currentPatient.name,
      email: currentPatient.email,
      phone: currentPatient.phone,
      dateOfBirth: moment(currentPatient.dateOfBirth).format("YYYY-MM-DD"),
      gender: currentPatient.gender,
    });
  }, [currentPatient.name]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      gender: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required")
        .max(255, "Email must be at most 25 characters"),
      phone: Yup.string()
        .matches(/^\d{10}$/, {
          message: "Invalid Phone Number",
          excludeEmptyString: true,
        })
        .required("Phone number is required"),
      dateOfBirth: Yup.date()
        .max(new Date(), "Date of Birth cannot be after today")
        .required("Date of Birth is required"),
      gender: Yup.mixed().required("gender is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        // Check if editing an employee

        const response = await updatePhysicalTherapistPatient(
          currentPatient._id,
          {
            name: values.name,
            email: values.email,
            phone: values.phone,
            dateOfBirth: values.dateOfBirth,
            gender: values.gender,
          }
        );

        resetForm();
        await fun();
        handleClose();
      } catch (error) {}
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { error, data } = await getPatientApi(id);

        setError(null);
        if (!error) {
          formik.setValues({
            // Set form values using fetched data
            name: data.name,
            email: data.email,
            phone: data.phone,
            dateOfBirth: moment(data.dob).format("YYYY-MM-DD"),
            gender: data.gender,
          });
        } else {
          setError("Failed to fetch data");
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="modal fade success-popup add-company"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Update Patient
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <img src={cros} alt="" />
        </button>
      </div>
      <div className="modal-body text-start">
        <form onSubmit={formik.handleSubmit}>
          <h6>Personal Information</h6>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Name</label>
            <input
              type="text"
              className={`form-control ${
                formik.errors.name && formik.touched.name ? "is-invalid" : ""
              }`}
              placeholder="Name"
              value={formik.values.name}
              onChange={formik.handleChange("name")}
            />
            {formik.errors.name && formik.touched.name && (
              <div className="invalid-feedback">{formik.errors.name}</div>
            )}
          </div>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Email</label>
            <input
              type="email"
              className={`form-control ${
                formik.errors.email && formik.touched.email ? "is-invalid" : ""
              }`}
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange("email")}
            />
            {formik.errors.email && formik.touched.email && (
              <div className="invalid-feedback">{formik.errors.email}</div>
            )}
          </div>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Phone</label>
            <input
              type="text"
              className={`form-control ${
                formik.errors.phone && formik.touched.phone ? "is-invalid" : ""
              }`}
              placeholder="Phone"
              value={formik.values.phone}
              onChange={formik.handleChange("phone")}
            />
            {formik.errors.phone && formik.touched.phone && (
              <div className="invalid-feedback">{formik.errors.phone}</div>
            )}
          </div>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Date of birth</label>
            <input
              type="date"
              className={`form-control ${
                formik.errors.dateOfBirth && formik.touched.dateOfBirth
                  ? "is-invalid"
                  : ""
              }`}
              value={formik.values.dateOfBirth}
              onChange={formik.handleChange("dateOfBirth")}
            />
            {formik.errors.dateOfBirth && formik.touched.dateOfBirth && (
              <div className="invalid-feedback">
                {formik.errors.dateOfBirth}
              </div>
            )}
          </div>
          <div className="col-lg-12 mb-4">
            <label className="form-label mt-2">Gender</label>
            <select
              className={`form-select mt-3 ${
                formik.errors.gender && formik.touched.gender
                  ? "is-invalid"
                  : ""
              }`}
              value={formik.values.gender}
              onChange={formik.handleChange("gender")}
              placeholder="Gender"
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {formik.errors.gender && formik.touched.gender && (
              <div className="invalid-feedback">{formik.errors.gender}</div>
            )}
          </div>

          <div className="modal-footer justify-content-start">
            <button
              href=""
              className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
              type="submit"
            >
              Save Changes
            </button>
            <button
              href=""
              onClick={handleClose}
              className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
            >
              Go Back
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditPhysicalTherapistPatient;
