import React, { useContext, useState } from "react";
import cros from "../assets/images/cros.svg";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Context } from "../context/Context";
import useApiAxios from "../api/useApiAxios";

const SendEmailModal = () => {
  const { showEmailData, setShowEmailData, showEmail, setShowEmail } =
    useContext(Context);
  const { sendMultiEmailApi } = useApiAxios();

  const handleClose = () => {
    setShowEmail(false);
    setShowEmailData({});
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      body: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      body: Yup.string().required("Email body is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const emailIds = [];
      showEmailData.map((item) =>
        item.selected
          ? emailIds.push({ email: item.email, name: item.name })
          : true
      );
      sendMultiEmailApi({
        body: values.body,
        title: values.title,
        emails: emailIds,
      });
      resetForm();

      handleClose();
    },
  });

  return (
    <div>
      <Modal
        show={showEmail}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className="modal fade success-popup add-company"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Send Email
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <img src={cros} alt="" />
          </button>
        </div>
        <div className="modal-body text-start">
          <form onSubmit={formik.handleSubmit}>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">Subject</label>
              <input
                type="text"
                className={`form-control ${
                  formik.errors.title && formik.touched.title
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Title Name"
                value={formik.values.title}
                onChange={formik.handleChange("title")}
              />
              {formik.errors.title && formik.touched.title && (
                <div className="invalid-feedback">{formik.errors.title}</div>
              )}
            </div>

            <div className="col-lg-12 mb-4">
              <label className="form-label mt-3">Email Body</label>

              <SunEditor
                width="100%"
                setContents={formik.values.body}
                onChange={(editorText) =>
                  formik.setFieldValue("body", editorText)
                }
                setOptions={{
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    ["fontColor", "hiliteColor"],
                    ["align", "list", "lineHeight"],

                    ["table", "horizontalRule", "link"],
                  ],
                  defaultTag: "div",
                  minHeight: "300px",
                  showPathLabel: false,
                 
                }}
              />
              {formik.errors.body && formik.touched.body && (
                <div className="invalid-feedback">{formik.errors.body}</div>
              )}
            </div>

            <div className="modal-footer justify-content-start">
              <button
                href=""
                className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
                type="submit"
              >
                Send Email
              </button>
              <button
                href=""
                onClick={handleClose}
                className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default SendEmailModal;
