import React from "react";
import { Modal } from "react-bootstrap";
import cros from "../../assets/images/cros.svg";
import VideoComponent from "../media/VideoComponent";

const Preview = ({ show, handleClose, data }) => {
  return (
    <Modal
      show={show}
      handleClose={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="modal fade success-popup add-company"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Course Preview
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <img src={cros} alt="" />
          </button>
        </div>
        <div className="container mb-4 mb-xl-5 pt-3 z-10">
          <div className="row d-flex align-items-stretch justify-content-center">
            <div className="col-lg-11 col-xl-9 mb_24">
              <div className="video_play_area ">
                <VideoComponent src={`${data.filename}`} />
                <h5 className="intro">{data.title}</h5>
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Preview;
