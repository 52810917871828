import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import cros from "../../assets/images/cros.svg";
import useApiAxios from "../../api/useApiAxios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Select from "react-select";
import { Link } from "react-router-dom";

const AddCourseVideos = ({
  show,
  handleClose,
  fun,
  videoModule,
  selectEmailData,
}) => {
  const { createProductApi } = useApiAxios();
  
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      thumbnail: "",
      filename: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      thumbnail: Yup.string().required("thumbnail is required"),
      filename: Yup.mixed().required("filename is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("course", videoModule.course);
      formData.append("videoModule", videoModule._id);

      if (values.thumbnail) {
        formData.append("thumbnail", values.thumbnail);
      }

      const response = await createProductApi(formData, values.filename);

      if (!response.error) {
        resetForm();
        await fun();
        handleClose();
      }
    },
  });
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className="modal fade success-popup add-company"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Add New Video
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <img src={cros} alt="" />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-body text-start">
              <div className="row">
                <div className="col-lg-12 text-start">
                  <p className="course-title text-start ">Product Details</p>
                  <p className="course-subtitle text-start mb-0">
                    We'll use your title and description to generate a sample
                    course outline:
                  </p>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-2">Title</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.title && formik.touched.title
                          ? "is-invalid"
                          : ""
                      }`}
                      value={formik.values.title}
                      placeholder="Title Name"
                      onChange={formik.handleChange("title")}
                      name="title"
                    />
                    {formik.errors.title && formik.touched.title && (
                      <div className="invalid-feedback">
                        {formik.errors.title}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-3">Brief Description</label>

                    <SunEditor
                      width="100%"
                      setContents={formik.values.description}
                      onChange={(editorText) =>
                        formik.setFieldValue("description", editorText)
                      }
                      setOptions={{
                        buttonList: [
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["undo", "redo"],
                          ["font", "fontSize"],
                          ["fontColor", "hiliteColor"],
                          ["align", "list", "lineHeight"],

                          ["table", "horizontalRule", "link"],
                        ],
                        defaultTag: "div",
                        minHeight: "300px",
                        showPathLabel: false,
                        
                      }}
                    />

                    {formik.errors.description &&
                      formik.touched.description && (
                        <div className="invalid-feedback">
                          {formik.errors.description}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-3">Thumbnail</label>
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      className={`form-control ${
                        formik.errors.thumbnail && formik.touched.thumbnail
                          ? "is-invalid"
                          : ""
                      }`}
                     
                      onChange={(event) =>
                        formik.setFieldValue(
                          "thumbnail",
                          event.currentTarget.files[0]
                        )
                      }
                    />
                    {formik.errors.thumbnail && formik.touched.thumbnail && (
                      <div className="invalid-feedback">
                        {formik.errors.thumbnail}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-3">File</label>

                    <input
                      type="file"
                      className={`form-control ${
                        formik.errors.filename && formik.touched.filename
                          ? "is-invalid"
                          : ""
                      }`}
                      accept="video/mp4,video/x-m4v,video/*"
                      placeholder="File Name"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "filename",
                          event.currentTarget.files[0]
                        )
                      }
                    />
                    {formik.errors.filename && formik.touched.filename && (
                      <div className="invalid-feedback">
                        {formik.errors.filename}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 mb-4">
                  <label className="form-label mt-2"> Email Sequence</label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      defaultChecked=""
                      checked={formik.values.emailSequence}
                      onChange={(e) =>
                        formik.setFieldValue("emailSequence", e.target.checked)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Turn on To Use as a email Sequence
                    </label>
                  </div>
                </div>
                {formik.values.emailSequence ? (
                  <div className="col-lg-12 mb-4">
                    <label className="form-label mt-2">
                      Select Internal Email Title
                    </label>
                    <Select
                      isSearchable={true}
                      isClearable={true}
                      isMulti
                      name="emailTemplate"
                      className="form-control"
                   
                      onChange={(e) => {
                        formik.setFieldValue("emailTemplate", e.value);
                      }}
                      options={selectEmailData}
                    />
                    {formik.touched.emailTemplate &&
                    formik.errors.emailTemplate ? (
                      <div className="error">{formik.errors.emailTemplate}</div>
                    ) : null}
                  </div>
                ) : (
                  <div className="col-lg-12 mb-4">
                    <label className="form-label mt-2">
                      Select Internal Email Title
                    </label>
                    <Select
                      isSearchable={true}
                      isClearable={true}
                      
                      name="emailTemplate"
                      className="form-control"
                      
                      onChange={(e) => {
                        formik.setFieldValue("emailTemplate", e.value);
                      }}
                      options={selectEmailData}
                    />
                    {formik.touched.emailTemplate &&
                    formik.errors.emailTemplate ? (
                      <div className="error">{formik.errors.emailTemplate}</div>
                    ) : null}
                  </div>
                )}
             
              </div>
            </div>
            <div className="modal-footer justify-content-start">
              <button
                className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
                type="submit"
              >
                Continue
              </button>
              <Link
                className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
                onClick={handleClose}
              >
                Go Back
              </Link>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddCourseVideos;
