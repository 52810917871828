import React, { useState } from "react";
import product1 from "../../assets/images/product-thumb 1.jpg";
import AddProducts from "./AddProducts";
import { Link } from "react-router-dom";

const Products = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  return (
    <div>
      <div className="">
        <div className="container-fluid">
          <div className="row d-flex ">
            <div className="col-xl-5 col-sm-5">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Products </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Products
                  </li>
                </ol>
              </nav>
              <h1 className="page-title">All Products</h1>
            </div>
            <div className="col-xl-7 col-sm-7 mb-3 mb-xl-0 d-flex justify-content-start justify-content-sm-end align-items-sm-center">
              <Link className="btn company-btn" onClick={handleShow}>
                <i className="icon-19" /> Add Product
              </Link>
            </div>
          </div>
          {/* Companies */}
          <div className="row align-items-stretch">
            {/* Companies */}
            <div className="col-xxl-12 mb-3">
              <div className="card customer-sf h-100">
                <div className="p-30 pb-0">
                  <h4 className="customer-sf-title ">
                    Products <small>(20)</small>
                  </h4>
                  <div className="row mt-4 ">
                    <div className="col-lg-12">
                      <div className="input-group mb-2 search-table">
                        <input
                          type="text"
                          className="form-control form-control-filter"
                          placeholder="Search Company"
                        />
                        <i className="icon-13 search-ic" />
                        <div className="btn-group bulk-action ">
                          <button
                            type="button"
                            className="filter-btn px-3 px-md-4"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                          >
                            <i className="fa fa-filter me-2" /> Filters
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                            <li>
                              <button
                                className="dropdown-item active"
                                type="button"
                              >
                                Newest
                              </button>
                            </li>
                            <li>
                              <button className="dropdown-item" type="button">
                                Oldest
                              </button>
                            </li>
                            <li>
                              <button className="dropdown-item" type="button">
                                Recently Updated
                              </button>
                            </li>
                            <li>
                              <button className="dropdown-item" type="button">
                                Most Customers
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-4 companies-table product-table">
                  <div className="mt-4">
                    <table
                      id="products-table"
                      className="table display table-edits"
                      cellSpacing={0}
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th />
                          <th />
                          <th>DESCRIPTION</th>
                          <th />
                          <th />
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td />
                          <td>
                            <span className="product-img">
                              <img src={product1} alt="" />
                            </span>
                          </td>
                          <td className="pro-info text-nowrap">
                            <h5>Virt Functional Training Program</h5>
                            <p>
                              <span className="course">Course</span>
                              <span className="course-no">
                                <i className="icon-26" /> 150
                              </span>
                              <span className="course-no">
                                <i className="icon-24" /> 18 Apr 2023
                              </span>
                            </p>
                          </td>
                          <td className=" text-nowrap">
                            <a
                              href="#!"
                              className="btn btn-sm view-btn me-3 por-btn my-1"
                            >
                              <i className="icon-22" /> PREVIEW
                            </a>
                            
                          </td>
                          <td className=" text-nowrap">
                            <a href="#!" className="btn chat-btn my-1">
                              <i className="icon-23" /> MANAGE COMMENTS
                            </a>
                          </td>
                          <td className=" text-nowrap">
                            <a href="#!" className="btn action-btn my-1">
                              <i className="icon-20" />
                            </a>
                            <a href="#!" className="btn action-btn">
                              <i className="icon-21" />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td>
                            <span className="product-img">
                              <img src={product1} alt="" />
                            </span>
                          </td>
                          <td className="pro-info">
                            <h5>Virt Functional Training Program</h5>
                            <p>
                              <span className="course">Course</span>
                              <span className="course-no">
                                <i className="icon-26" /> 150
                              </span>
                              <span className="course-no">
                                <i className="icon-24" /> 18 Apr 2023
                              </span>
                            </p>
                          </td>
                          <td>
                            <a
                              href="#!"
                              className="btn btn-sm view-btn me-3 por-btn my-1"
                            >
                              <i className="icon-22" /> PREVIEW
                            </a>
                            
                          </td>
                          <td>
                            <a href="#!" className="btn chat-btn my-1">
                              <i className="icon-23" /> MANAGE COMMENTS
                            </a>
                          </td>
                          <td>
                            <a href="#!" className="btn action-btn my-1">
                              <i className="icon-20" />
                            </a>
                            <a href="#!" className="btn action-btn my-1">
                              <i className="icon-21" />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td>
                            <span className="product-img">
                              <img src={product1} alt="" />
                            </span>
                          </td>
                          <td className="pro-info">
                            <h5>Virt Functional Training Program</h5>
                            <p>
                              <span className="course">Course</span>
                              <span className="course-no">
                                <i className="icon-26" /> 150
                              </span>
                              <span className="course-no">
                                <i className="icon-24" /> 18 Apr 2023
                              </span>
                            </p>
                          </td>
                          <td>
                            <a
                              href="#!"
                              className="btn btn-sm view-btn me-3 por-btn my-1"
                            >
                              <i className="icon-22" /> PREVIEW
                            </a>
                            
                          </td>
                          <td>
                            <a href="#!" className="btn chat-btn my-1">
                              <i className="icon-23" /> MANAGE COMMENTS
                            </a>
                          </td>
                          <td>
                            <a href="#!" className="btn action-btn my-1">
                              <i className="icon-20" />
                            </a>
                            <a href="#!" className="btn action-btn my-1">
                              <i className="icon-21" />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td>
                            <span className="product-img">
                              <img src={product1} alt="" />
                            </span>
                          </td>
                          <td className="pro-info">
                            <h5>Virt Functional Training Program</h5>
                            <p>
                              <span className="course">Course</span>
                              <span className="course-no">
                                <i className="icon-26" /> 150
                              </span>
                              <span className="course-no">
                                <i className="icon-24" /> 18 Apr 2023
                              </span>
                            </p>
                          </td>
                          <td>
                            <a
                              href="#!"
                              className="btn btn-sm view-btn me-3 por-btn my-1"
                            >
                              <i className="icon-22" /> PREVIEW
                            </a>
                            
                          </td>
                          <td>
                            <a href="#!" className="btn chat-btn my-1">
                              <i className="icon-23" /> MANAGE COMMENTS
                            </a>
                          </td>
                          <td>
                            <a href="#!" className="btn action-btn my-1">
                              <i className="icon-20" />
                            </a>
                            <a href="#!" className="btn action-btn my-1">
                              <i className="icon-21" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* /Companies */}
          </div>
          {/*/Row*/}
          {/* Companies */}
        </div>
      </div>
      <AddProducts show={show} handleClose={() => setShow(false)} />
    </div>
  );
};

export default Products;
