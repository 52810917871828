import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import PaginationComponent from "../../pagination/PaginationComponent";
import moment from "moment";
import userDemo from "./../../assets/images/user-demo.svg";
import DeleteModel from "../deleteModel/DeleteModel";
import { Context } from "../../context/Context";
import ImageComponent from "../media/ImageComponent";
import AddTagModel from "./../addTagModel/AddTagModel";

const PhysicalTherapists = () => {
  const limit = 15;
  const [searchField, setSearchField] = useState("");

  const {
    getAllPhysicaltherapist,
    deletePhysicaltherapistApi,
    selectTagsPhysicalTherapistApi,
    getAllTagsApi,
    getPTExportDataApi,
  } = useApiAxios();
  const [physicalTherapists, setPhysicalTherapists] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedAll, setSelectedAll] = useState(false);
  const { setShowEmailData, setShowEmail } = useContext(Context);

  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [tags, setTags] = useState([]);
  const [tagModel, setTagModel] = useState(false);
  const [currentTag, setCurrentTag] = useState(null);

  const selectTagFunction = async (tagId) => {
    const therapistId = [];
    for (let index = 0; index < physicalTherapists.length; index++) {
      const element = physicalTherapists[index];
      if (element.selected) {
        therapistId.push(element._id);
      }
    }
    const response = await selectTagsPhysicalTherapistApi(tagId, therapistId);
  };

  const searchPhysicalTherapistHandler = async (e) => {
    setSearch(e.target.value);
  };

  const closeTagModel = () => {
    setTagModel(false);
  };
  const openEmailModal = () => {
    setShowEmail(true);
    setShowEmailData(physicalTherapists);
  };
  function handleIncrementClick(_id, checked, all) {
    if (!all) {
      setSelectedAll(false);
      const nextCounters = physicalTherapists.map((item) => {
        if (item._id === _id) {
          // Increment the clicked counter
          return { ...item, selected: checked };
        } else {
          // The rest haven't changed
          return item;
        }
      });
      setPhysicalTherapists(nextCounters);
    } else {
      if (!selectedAll) {
        setSelectedAll(true);
        setPhysicalTherapists(
          physicalTherapists.map((item) => {
            return { ...item, selected: checked };
          })
        );
      } else {
        setSelectedAll(false);
        setPhysicalTherapists(
          physicalTherapists.map((item) => {
            return { ...item, selected: false };
          })
        );
      }
    }
  }

  const fun = async () => {
    const response = await getAllTagsApi();
    setTags(response.data.data);
    if (!searchField) {
      try {
        const data = await getAllPhysicaltherapist(
          page,
          limit,
          sort,
          search,
          currentTag?._id
        );

        setPhysicalTherapists(data.data.data);
        setTotal(data.data.total);
      } catch (error) {}
    } else {
      setPhysicalTherapists([]);
      setTotal(0);
    }
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const openDeleteModel = (data) => {
    setDeleteData(data);
    setDeleteModal(true);
  };
  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };
  const deletePhysicalTherapistHandler = async (data) => {
    await deletePhysicaltherapistApi(data);
    await fun();
    closeDeleteModel();
  };
  useEffect(() => {
    fun();
    document.title = "Physical Therapists";
  }, [page, searchField, sort, search, currentTag?._id]);

  useEffect(() => {
    let c = 0;
    physicalTherapists.forEach((item) => (item.selected ? (c += 1) : c));
    setCount(c);
  }, [physicalTherapists]);

  const handleSort = async (selectedSort) => {
    setSort(selectedSort);
    setPage(1);
  };

  const handlePTExportData = async () => {
    try {
      const response = await getPTExportDataApi();
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PhysicalTherapist.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-9">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">PhysicalTherapists</li>
              <li className="breadcrumb-item active" aria-current="page">
                PhysicalTherapists
              </li>
            </ol>
          </nav>
          <h1 className="page-title">
            PhysicalTherapists<span>({total})</span>{" "}
          </h1>
        </div>

        <div className="col-3 ps-0 text-end">
          <button
            onClick={handlePTExportData}
            className="btn btn-sm btn-success"
          >
            Export
          </button>
        </div>
      </div>
      {/* Companies */}
      <div className="row align-items-stretch">
        {/* Companies */}
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf">
            <div className="p-3 pb-0">
              <div className="input-group search-table">
                <input
                  type="text"
                  className="form-control form-control-filter"
                  placeholder="Search Physical Therapist"
                  onChange={searchPhysicalTherapistHandler}
                />

                <i className="icon-13 search-ic" />
                <div className="btn-group bulk-action ms-2">
                  <button
                    type="button"
                    className="filter-btn px-3 px-md-4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    <i className="fa fa-filter me-2" /> Filters
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("newest")}
                      >
                        Newest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("oldest")}
                      >
                        Oldest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("updated")}
                      >
                        Recently Updated
                      </button>
                    </li>
                    <span>By Tag</span>
                    <li>
                      {tags.map((item) => (
                        <button
                          className={`dropdown-item ${
                            item?._id === currentTag?._id ? "active" : ""
                          }`}
                          type="button"
                          onClick={() => {
                            if (item?._id === currentTag?._id) {
                              setCurrentTag(null);
                            } else {
                              setCurrentTag(item);
                            }
                          }}
                        >
                          {item.name}
                        </button>
                      ))}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="companies-table">
              <div class="text-end">
                {physicalTherapists &&
                physicalTherapists.some((item) => item.selected) ? (
                  <div className="mt-1">
                    <label className="col-form-label">Selected {count}</label>
                    <div className="btn-group bulk-action ms-2">
                      <button
                        type="button"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Bulk Actions
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-end drop-tow mt-3"
                        style={{}}
                      >
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                            onClick={openEmailModal}
                          >
                            Send Email
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                            onClick={() => setTagModel(true)}
                          >
                            Add tag
                          </button>
                        </li>
                        {currentTag?.name ? (
                          <li>
                            <button
                              className="dropdown-item "
                              type="button"
                              onClick={openEmailModal}
                            >
                              Remove tags
                            </button>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="pb-4 companies-table">
              <div className="mt-2 table-responsive">
                <table
                  id="example"
                  className="table display table-edits"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>
                        <span className="nochecked">
                          <div className="form-check">
                            <input
                              className="form-check-input mr-1"
                              type="checkbox"
                              defaultValue=""
                              id="remember1"
                              checked={selectedAll}
                              onClick={() =>
                                handleIncrementClick("", true, true)
                              }
                            />
                          </div>
                        </span>
                      </th>
                      <th>Name</th>
                      <th className="text-nowrap">Email</th>
                      <th className="text-nowrap">Phone</th>
                      <th className="text-nowrap">License Number</th>
                      <th className="text-nowrap">Clinic Name</th>
                      <th className="text-truncate">Address</th>
                      <th className="text-nowrap">City</th>
                      <th className="text-nowrap">State</th>
                      <th className="text-nowrap">Zip</th>
                      <th className="text-nowrap">Created At</th>
                      <th>Plan</th>
                      <th className="text-nowrap">Plan Expiry</th>
                      <th className="text-nowrap text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {physicalTherapists.map((physicalTherapistsData, index) => (
                      <tr key={index}>
                        <td className="checked">
                          <span className="form-check d-flex align-items-center ">
                            <input
                              className="form-check-input mr-1"
                              type="checkbox"
                              checked={physicalTherapistsData?.selected}
                              onClick={(e) => {
                                
                                handleIncrementClick(
                                  physicalTherapistsData._id,
                                  e.target.checked
                                );
                              }}
                            />
                          </span>
                        </td>
                        <td className="text-normal">
                          <label
                            className="form-check-label text-truncate d-flex align-items-center"
                            htmlFor={`remember${index}`}
                          >
                            <span className="uusr_img me-2">
                              <ImageComponent
                                className="h-100"
                                src={
                                  physicalTherapistsData?.profileImage
                                    ? `${physicalTherapistsData?.profileImage}`
                                    : userDemo
                                }
                              />
                            </span>
                            {physicalTherapistsData.name}
                          </label>
                        </td>

                        <td>{physicalTherapistsData.email}</td>

                        <td>{physicalTherapistsData.phone}</td>

                        <td>{physicalTherapistsData.licenseNumber}</td>
                        <td>{physicalTherapistsData.clinicName}</td>
                        <td>{physicalTherapistsData.address}</td>
                        <td>{physicalTherapistsData.city}</td>
                        <td>{physicalTherapistsData.state}</td>
                        <td>{physicalTherapistsData.zip}</td>
                        <td>
                          {moment(physicalTherapistsData.createdAt).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                        <td>{physicalTherapistsData?.offer?.title}</td>
                        <td>
                          {physicalTherapistsData?.plainExpire
                            ? moment(physicalTherapistsData.plainExpire).format(
                                "MM/DD/YYYY"
                              )
                            : "n/a"}
                        </td>

                        <td className="text-nowrap text-center">
                          <Link
                            to={`patient/${physicalTherapistsData._id}`}
                            className=" action-btn"
                          >
                            <i className="fa-solid fa-eye"></i>
                          </Link>

                          <Link
                            to={`edit-physical-therapist/${physicalTherapistsData._id}`}
                            className=" action-btn"
                          >
                            <i className="fa-solid fa-pencil" />
                          </Link>

                          <Link
                            className=" action-btn"
                            onClick={() =>
                              openDeleteModel(physicalTherapistsData._id)
                            }
                          >
                            <i className="fa-solid fa-trash" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* /Companies */}
      </div>
      {/*/Row*/}
      {/* Companies */}
      <PaginationComponent
        total={total}
        page={page}
        limit={limit}
        setPage={setPage}
      />
      <AddTagModel
        show={tagModel}
        handleClose={closeTagModel}
        data={tags}
        selectTagFunction={selectTagFunction}
      />
      <DeleteModel
        show={deleteModal}
        handleClose={closeDeleteModel}
        data={deleteData}
        deleteFunction={deletePhysicalTherapistHandler}
      />
    </>
  );
};

export default PhysicalTherapists;
