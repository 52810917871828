import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/login/Login";
import Backend from "./components/layout/Backend";
import Dashboard from "./pages/dashboard/Dashboard";
import SnackbarComponent from "./components/snackbarComponent/SnackbarComponent";
import Loader from "./components/loader/Loader";
import { Context } from "./context/Context";
import useApiAxios from "./api/useApiAxios";
import { useState } from "react";
import Courses from "./components/Courses/Courses";
import Products from "./components/Products/Products";
import Inquiry from "./components/inquiry/Inquiry";
import Company from "./components/company/Company";
import PhysicalTherapists from "./components/physicalTherapists/PhysicalTherapists";
import CourseVideos from "./components/courseVideos/CourseVideos";
import AddCompany from "./components/company/AddCompany";
import ForgetPassword from "./components/forgetPassword/ForgetPassword";
import AddPhysicalTherapist from "./components/physicalTherapists/AddPhysicalTherapist";
import EditPhysicalTherapist from "./components/physicalTherapists/EditPhysicalTherapist";
import EditCompany from "./components/company/EditCompany";
import SingleCompany from "./components/company/SingleCompany";
import Tags from "./components/tags/Tags";
import PhysicalTherapistPatient from "./components/physicalTherapistPatient/PhysicalTherapistPatient";
import Offers from "./components/sale/Offers";
import AddOffers from "./components/sale/AddOffers";
import EmployeeDetails from "./components/company/EmployeeDetails";
import Email from "./components/email/Email";
import EmailTemplate from "./components/email/EmailTemplate";
import Register from "./components/register/Register";
import Subscription from "./components/subscription/Subscription";
import Canceled from "./components/subscription/Canceled";
import Success from "./components/subscription/Success";
import PhysicalTherapistPatientPT from "./components/physicalTherapistPatient/PhysicalTherapistPatientPT";
import EditOffer from "./components/sale/EditOffer";
import Registered from "./components/register/Registered";
import PhysicalTherapistProfile from "./components/physicalTherapistProfile/PhysicalTherapistProfile";
import Hab from "./components/question/Hab";
import Payments from "./components/sale/Payments";
import Question from "./components/question/Question";
import AddQuestion from "./components/question/AddQuestion";
import EditQuestion from "./components/question/EditQuestion";
import EmailList from "./components/email/EmailList";
import EmailAuto from "./components/email/EmailAuto";
import ChangePassword from "./components/changePassword/ChangePassword";
import HomeContent from "./components/websiteContent/HomeContent";
import VideoLibraryContent from "./components/websiteContent/VideoLibraryContent";
import IndividualContent from "./components/websiteContent/IndividualContent";
import EmployersContent from "./components/websiteContent/EmployersContent";
import PhysicalTherapistsContent from "./components/websiteContent/PhysicalTherapistsContent";
import RazorpayCheckout from "./RazorpayCheckout";
import SendEmailModal from "./components/SendEmailModal";
import Appointment from "./components/appointment/Appointment";
import AppointmentLead from "./components/appointment/AppointmentLead";
import Term from "./components/term/Term";



const Routing = () => {
  const { isLoading, isAuthorized, userData, updateCount } =
    useContext(Context);
  const [firstLoading, setFirstLoading] = useState(false);
  const { homeApi } = useApiAxios();
  useEffect(() => {
    checkLogin();
  }, [updateCount]);
  const checkLogin = async () => {
    setFirstLoading(true);
    await homeApi();
    setFirstLoading(false);
  };
  //aa
  return (
    <>
      <Loader open={isLoading} />
      <SnackbarComponent />

      <Routes>
        <Route path="/subscription/success" element={<Success />} />
        <Route path="/subscription/test" element={<RazorpayCheckout />} />
        <Route path="/subscription/canceled" element={<Canceled />} />
        <Route exact path="/term" element={<Term />} />
      </Routes>

      {firstLoading ? (
        <></>
      ) : isAuthorized ? (
        userData.role === "admin" ||
        userData.subscription?.status === "active" ? (
          <Routes>
            <Route path="/" element={<Backend />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/product/courses" element={<Courses />} />
              <Route path="/product/All-Products" element={<Products />} />
              <Route path="/inquiry" element={<Inquiry />} />
              <Route path="/company" element={<Company />} />
              <Route path="/company/addcompany" element={<AddCompany />} />
              <Route path="/email_marketing/copy/email" element={<Email />} />
              <Route path="/email_marketing/list" element={<EmailList />} />
              <Route
                path="/email_marketing/email/auto"
                element={<EmailAuto />}
              />
              <Route path="/change/password" element={<ChangePassword />} />

              <Route
                path="/email_marketing/email/template"
                element={<EmailTemplate />}
              />
              <Route
                path="/product/courses/videos/:id"
                element={<CourseVideos />}
              />
              <Route path="/company/:id" element={<EditCompany />} />
              <Route path="/singlecompany/:id" element={<SingleCompany />} />
              <Route
                path="/employee-details/:id"
                element={<EmployeeDetails />}
              />
              <Route
                path="/physical-therapists"
                element={<PhysicalTherapists />}
              />
              <Route path="/appointment/lead" element={<AppointmentLead />} />
              <Route
                path="/physical-therapists/add-physical-therapist"
                element={<AddPhysicalTherapist />}
              />
              <Route
                path="/physical-therapists/edit-physical-therapist/:id"
                element={<EditPhysicalTherapist />}
              />
              <Route path="/profile" element={<PhysicalTherapistProfile />} />
              <Route path="/appointment/url" element={<Appointment />} />
              <Route path="/question" element={<Hab />} />
              <Route path="/question/:habId" element={<Question />} />

              <Route path="/question/add/:habId" element={<AddQuestion />} />
              <Route
                path="/question/edit/:questionId"
                element={<EditQuestion />}
              />

              <Route
                path="/physical-therapists/patient/:id"
                element={<PhysicalTherapistPatient />}
              />

              <Route path="/content/home/page" element={<HomeContent />} />
              <Route
                path="/content/video/library"
                element={<VideoLibraryContent />}
              />
              <Route
                path="/content/individual/page"
                element={<IndividualContent />}
              />
              <Route
                path="/content/employers/page"
                element={<EmployersContent />}
              />
              <Route
                path="/content/physical-therapists/page"
                element={<PhysicalTherapistsContent />}
              />
              <Route
                path="/content/physical-therapists/page"
                element={<Offers />}
              />

              <Route path="/sales/offers" element={<Offers />} />
              <Route path="/sales/offers/edit/:id" element={<EditOffer />} />
              <Route path="/patient" element={<PhysicalTherapistPatientPT />} />
              <Route path="/tags" element={<Tags />} />
              <Route path="/sales/offers/add" element={<AddOffers />} />
              <Route path="/register/:id" element={<Registered />} />
              <Route
                path="/payment/:offerId/:priceStripeId"
                element={<Registered />}
              />
              <Route path="/sales/payments" element={<Payments />} />
            </Route>
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Subscription />} />
            <Route
              path="/payment/:offerId/:priceStripeId"
              element={<Register />}
            />
          </Routes>
        )
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="/register/:id" element={<Register />} />

          <Route
            path="/payment/:offerId/:priceStripeId"
            element={<Register />}
          />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
        </Routes>
      )}
      <SendEmailModal />
    </>
  );
};

export default Routing;
