import React, { useEffect } from "react";
import useApiAxios from "../../api/useApiAxios";
import queryString from "query-string";
import styles from "./Thanks.module.css";
import logo from "./images/logo.png";

const Success = () => {
  const { session_id } = queryString.parse(window.location.search);
  const { updateSubscription } = useApiAxios();

  useEffect(() => {
    updateSubscription(session_id);
  }, []);

  return (
    <div className={styles.mainDiv}>
      <div className={styles.logo}>
        <img src={logo} alt="Logo" />
      </div>
      <div className="d-flex justify-content-center align-items-center vh-100 w-100">
        <div className="text-center align-items-center w-100 m-auto">
          <div className="row">
            <div className="col-12 text-center">
              <div className={`mb-5 fw-bold ${styles.font20}`}>
                PAYMENT WAS SUCCESSFUL
              </div>
              <div
                className={` ${styles.text_brittany} ${styles.thank_you_text}`}
              >
                Thank You
              </div>
              <div className={`${styles.font26} mb-5`}>FOR SIGNING UP</div>
              <div className="pt-5 mb-3">
                <a href="/" className={styles.start_btn}>
                  START MY JOURNEY
                </a>
              </div>
            </div>
            {/*/Col*/}
          </div>
          {/*/Row*/}
        </div>
      </div>
      {/* /Thank You */}
    </div>
  );
};

export default Success;
