import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import companyTem from "../../assets/Template.xlsx";
import Modal from "react-bootstrap/Modal";
import cros from "../../assets/images/cros.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import Papa from "papaparse";
import { Context } from "../../context/Context";
import EmployeeListModal from "./../user/EmployeeListModal";
import moment from "moment";
import approved from "./../../assets/images/approved.svg";
import phone from "./../../assets/images/phone.svg";
import message from "./../../assets/images/message.svg";
import smile from "./../../assets/images/smile.svg";
import userDemo from "./../../assets/images/user-demo.svg";
import DeleteModel from "../deleteModel/DeleteModel";
import ImageComponent from "../media/ImageComponent";
import AddTagModel from "./../addTagModel/AddTagModel";
import PaginationComponent from "../../pagination/PaginationComponent";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import * as XLSX from "xlsx";

const SingleCompany = ({ fun }) => {
  const limit = 1000;
  const [editingEmployeeData, setEditingEmployeeData] = useState(null);

  const [companyData, setCompanyData] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const { openErrorSnackbar, setShowEmailData, setShowEmail, userData } =
    useContext(Context);
  const [employeeDataArray, setEmployeeDataArray] = useState([]);
  const [openEmployeeListModal, setOpenEmployeeListModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const { id } = useParams(); // Get the ID from the URL
  const [page, setPage] = useState("");
  const [searchField, setSearchField] = useState("");
  const [total, setTotal] = useState(null);
  const [selectedAll, setSelectedAll] = useState(false);
  const searchPatientHandler = async (e) => {
    setSearch(e.target.value);
  };

  const [satisfactionChartOption, setSatisfactionChartOption] = useState(null);
  const [scaleChartOption, setScaleChartOption] = useState(null);
  const [chartOptionForActivityAndPain, setChartOptionForActivityAndPain] =
    useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagModel, setTagModel] = useState(false);
  const [currentTag, setCurrentTag] = useState(null);
  const [satisfactionData, setSatisfactionData] = useState(null);
  const [count, setCount] = useState(0);
  const [activeUserCount, setActiveUserCount] = useState(0);

  const {
    getCompany,
    createEmployeeApi,
    createMultipleEmployeeApi,
    handleDeleteEmployeeApi,
    updateEmployeeApi,
    getCompanyGraphApi,
    getCompanySatisfactionGraphApi,
    getCompanyPainExportDataApi,
    getCompanySatisfactionExportDataApi,
    getCompanyActivityGraphApi,
    getCompanyActivityExportDataApi,
    selectTagsEmployeesApi,
    getAllTagsApi,
    getEmployeeExportDataApi,
    getCompanyScaleDataApi,
    getCompanyPainCountExportDataApi,
    getCompanyActivityCountExportDataApi,
  } = useApiAxios();

  const openEmailModal = () => {
    setShowEmail(true);
    setShowEmailData(employeeData);
  };

  const selectTagFunction = async (tagId) => {
    const employeeId = [];
    for (let index = 0; index < employeeData.length; index++) {
      const element = employeeData[index];
      if (element.selected) {
        employeeId.push(element._id);
      }
    }
    const response = await selectTagsEmployeesApi(tagId, employeeId);
  };

  const closeTagModel = () => {
    setTagModel(false);
  };

  function handleIncrementClick(_id, checked, all) {
    if (!all) {
      setSelectedAll(false);
      const nextCounters = employeeData.map((item) => {
        if (item._id === _id) {
          // Increment the clicked counter
          return { ...item, selected: checked };
        } else {
          // The rest haven't changed
          return item;
        }
      });
      setEmployeeData(nextCounters);
    } else {
      if (!selectedAll) {
        setSelectedAll(true);
        setEmployeeData(
          employeeData.map((item) => {
            return { ...item, selected: checked };
          })
        );
      } else {
        setSelectedAll(false);
        setEmployeeData(
          employeeData.map((item) => {
            return { ...item, selected: false };
          })
        );
      }
    }
  }

  const fetchCompanyData = async () => {
    const response = await getAllTagsApi();
    setTags(response.data.data);
    if (!searchField) {
      try {
        const response = await getCompany(id, sort, search, currentTag?._id);
        if (!response.error) {
          setCompanyData(response.data.data);
          setEmployeeData(response.data.data.employeess ?? []);
          setTotal(response.data.data.employeess.length);
          setActiveUserCount(response.data.activeUserCount);
        } else {
          setError(response.data.message);
        }
        setIsLoading(false);
      } catch (error) {
        setError("An error occurred while fetching company data.");
        setIsLoading(false);
      }
    } else {
      setEmployeeData([]);
      setTotal(0);
    }
  };

  const fetchCompanyScaleData = async () => {
    const response = await getCompanyScaleDataApi(id);

    if (!response.error) {
      console.log(response.data.painData);

      setScaleChartOption({
        chart: {
          type: "column",
          height: "300px",
        },
        title: {
          text: "Pain Level and Average Function Level Comparison",
        },
        xAxis: {
          categories: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
          crosshair: true,
        },
        yAxis: {
          title: {
            text: "Count",
          },
        },
        series: [
          {
            data: response.data.painData.map((item) => item.count),
            name: "Pain Level",
            // data: response.data.painData.map((item) => item.count),
            color: "#036E49", // Sample data for "painLabel"
          },
          {
            data: response.data.activitiesData.map((item) => item.count),
            name: "Function Level",

            color: "#FD707B", // Sample data for "avgActivityLabel"
          },
        ],
      });
    }
  };

  useEffect(() => {
    let c = 0;
    employeeData.forEach((item) => (item.selected ? (c += 1) : c));
    setCount(c);
  }, [employeeData]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const openDeleteModel = (data) => {
    setDeleteData(data);
    setDeleteModal(true);
  };
  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };

  const deleteSingleCompanyHandler = async (data) => {
    await handleDeleteEmployeeApi(data);

    await fetchCompanyData();

    closeDeleteModel();
  };

  const fun2 = async () => {
    try {
      const [res, res1] = await Promise.all([
        getCompanyGraphApi(id),
        getCompanySatisfactionGraphApi(id),
      ]);
      const res2 = await getCompanyActivityGraphApi(id);

      const satisfactionPain = [];
      for (let index = 0; index < res1.data.data.length; index++) {
        const element = res1.data.data[index];
        if (element?.x) {
          satisfactionPain.push({
            x: new Date(element.x),
            y: Number(element.y),
          });
        }
      }
      setSatisfactionChartOption({
        chart: {
          type: "line",
          Height: "300",
        },
        title: {
          text: "Satisfaction Scale",
        },
        xAxis: {
          type: "category",
          labels: {
            formatter: function () {
              const days = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ];
              console.log(this.value);
              const date = new Date(satisfactionPain[this.value].x);
              const weekday = days[date.getDay()];
              return weekday;
            },
          },
        },
        yAxis: {
          title: {
            text: "Percentage",
          },
          labels: {
            formatter: function () {
              return this.value + "%";
            },
          },
        },
        series: [
          {
            name: "Value ",
            data: satisfactionPain.map((item) => [item.x, Math.trunc(item.y)]),
            color: "#FD707B",
          },
        ],
      });

      const painData = [];
      const activityData = [];

      // Process Pain Data
      for (let index = 0; index < res.data.data.length; index++) {
        const element = res.data.data[index];
        if (element?.x) {
          painData.push({
            x: new Date(element.x),
            y: Number(element.y),
          });
        }
      }

      // Process Activity Data
      for (let index = 0; index < res2.data.data.length; index++) {
        const element = res2.data.data[index];
        if (element?.x) {
          activityData.push({
            x: new Date(element.x),
            y: Number(element.y),
          });
        }
      }

      // Set chart options for combined data
      setChartOptionForActivityAndPain({
        chart: {
          type: "line",
          height: "300px",
        },
        title: {
          text: "Pain and Function Scale",
        },
        xAxis: [
          {
            type: "category",
            categories: painData.map((item) =>
              moment(item.x).format("MM/DD/YYYY")
            ),
            title: {
              text: "Date",
            },
          },
          {
            type: "category",
            categories: activityData.map((item) =>
              moment(item.x).format("MM/DD/YYYY")
            ),

            opposite: true,
          },
        ],
        yAxis: {
          title: {
            text: "Value",
          },
        },
        series: [
          {
            name: "Average Pain level",
            data: painData.map((item) => item.y),
            color: "#036E49",
          },
          {
            name: "Average Functional level",
            data: activityData.map((item) => item.y),
            color: "#FD707B",
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    document.title = "Company";
    fetchCompanyData();
    fetchSatisfactionData();
    fetchCompanyScaleData();
    fun2();
  }, [id, searchField, sort, search, currentTag?._id]); // Fetch data whenever the ID changes

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleEditEmployee = (employee) => {
    formik.setValues({
      name: employee.name,
      email: employee.email,
      phone: employee.phone,
      dateOfBirth: moment(employee.dateOfBirth).format("MM/DD/YYYY"),
      gender: employee.gender,
    });
    setEditingEmployeeData(employee);
    handleOpenModal();
  };

  // Assuming these are the titles you expect in your files
  const expectedTitles = ["Title1", "Title2", "Title3", "Title4", "Gender"];

  const convertCSVtoObjectHandler = (e) => {
    const files = e.target.files;
    const data = [];

    if (files) {
      const file = files[0];
      const fileType = file.type;

      if (fileType === "text/csv") {
        Papa.parse(file, {
          complete: function (results) {
            processData(results.data);
          },
        });
      } else if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel"
      ) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          processData(jsonData);
        };
        reader.readAsBinaryString(file);
      } else {
        openErrorSnackbar("Unsupported file type");
      }
    }
    e.target.value = null;
  };

  const processData = (data) => {
    const result = [];
    for (let index = 1; index < data.length; index++) {
      const element = data[index];
      const title = data[0];
      console.log(element[3]);

      if (element.length === expectedTitles.length) {
        const gender = element[4];
        if (gender === "female" || gender === "male" || gender === "other") {
          if (!element.includes("")) {
            result.push({
              [title[0]]: element[0],
              [title[1]]: element[1],
              [title[2]]: element[2],
              [title[3]]: element[3],
              [title[4]]: element[4],
            });
          } else {
            openErrorSnackbar("Data is missing in file");
            break;
          }
        } else {
          openErrorSnackbar("Invalid gender");
          break;
        }
      } else {
        openErrorSnackbar("Invalid file");
        break;
      }
    }
    setEmployeeDataArray(result);
    setOpenEmployeeListModal(true);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      gender: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required")
        .max(255, "Email must be at most 25 characters"),
      phone: Yup.string()
        .matches(/^\d{10}$/, {
          message: "Invalid Phone Number",
          excludeEmptyString: true,
        })
        .required("Phone number is required"),
      dateOfBirth: Yup.date()
        .max(new Date(), "Date of Birth cannot be after today")
        .required("Date of Birth is required"),
      gender: Yup.mixed().required("gender is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        // Check if editing an employee
        if (editingEmployeeData) {
          const response = await updateEmployeeApi(editingEmployeeData._id, {
            name: values.name,
            email: values.email,
            phone: values.phone,
            dateOfBirth: values.dateOfBirth,
            gender: values.gender,
          });
          await fetchCompanyData();
          if (!response.error) {
            resetForm();
            await fun();
            handleClose();
          }
        } else {
          // If not editing, call createEmployeeApi
          const response = await createEmployeeApi(id, {
            name: values.name,
            email: values.email,
            phone: values.phone,
            dateOfBirth: values.dateOfBirth,
            gender: values.gender,
          });
          await fetchCompanyData();
          if (!response.error) {
            resetForm();
            await fun();
            handleClose();
          }
        }
      } catch (error) {
        console.log(error);
        handleClose();
      }
    },
  });

  // Function to open the modal
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleClose = async () => {
    setShowModal(false);
    await fetchCompanyData();
  };
  const handleSort = async (selectedSort) => {
    setSort(selectedSort);
    setPage(1);
  };

  const handleCompanyPainExportData = async () => {
    try {
      const response = await getCompanyPainExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "companyPainLevels.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleCompanySatisfactionExportData = async () => {
    try {
      const response = await getCompanySatisfactionExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "companySatisfactionLevels.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleCompanyActivityExportData = async () => {
    try {
      const response = await getCompanyActivityExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "CompanyActivityLevels.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleExport = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleDropdownChange = (e) => {
    if (e === "Pain Level") {
      handleCompanyPainExportData();
    } else if (e === "Functional Level") {
      handleCompanyActivityExportData();
    }
  };

  const handleCompanyPainCountExportData = async () => {
    try {
      const response = await getCompanyPainCountExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "CompanyPainCountData.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleCompanyActivityCountExportData = async () => {
    try {
      const response = await getCompanyActivityCountExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "CompanyActivityCountData.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleGraphDropdownChange = (e) => {
    if (e === "Pain Level") {
      handleCompanyPainCountExportData();
    } else if (e === "Functional Level") {
      handleCompanyActivityCountExportData();
    }
  };

  const fetchSatisfactionData = async () => {
    try {
      const response = await getCompanySatisfactionGraphApi(id);
      const data = response.data.data;

      // Get the last data point directly
      const lastDataPoint = data[data.length - 1];
      if (lastDataPoint) {
        setSatisfactionData({
          percentage: lastDataPoint.y ?? "N/A",
        });
      }
    } catch (error) {
      console.error("Error fetching satisfaction data:", error);
    }
  };

  const handleEmployeeExportData = async () => {
    try {
      const response = await getEmployeeExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Employees.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  function calculateCompletionPercentage(modules) {
    if (!modules) {
      return 0;
    }
    const totalVideos = [];
    for (let index = 0; index < modules.length; index++) {
      const element = modules[index];
      // totalVideos.concat(element?.userVideos);
      element?.userVideos.forEach((item) => totalVideos.push(item));
    }
    const completedVideos = totalVideos.filter((item) => item.completed).length;
    const percentage = (completedVideos / totalVideos.length) * 100;
    return percentage;
  }

  return (
    <>
      <div>
        {companyData && (
          <div className="row">
            <div className="col-xl-4 mb-4 align-items-stretch">
              <div className="card customer-sf h-100">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-12 mb-5 pb-5">
                      <div className="coplany-info">
                        <div className="float-start c-logo-manage">
                          <div className="company-logo d-flex justify-content-center align-items-center float-start">
                            <ImageComponent
                              src={
                                companyData?.profileImage
                                  ? `${companyData?.profileImage}`
                                  : userDemo
                              }
                            />
                          </div>
                        </div>
                        <div className="company-namearea float-start">
                          <h4 className="c-title">{companyData.name}</h4>
                          <h6 className="c-subtitle">{companyData.industry}</h6>
                          <div className="approve">
                            <img src={approved} alt="" className="me-2" />{" "}
                            Approved
                          </div>
                          <div className="action-icon">
                            <Link
                              to={`tel:${companyData?.phone?.substring(
                                0,
                                3
                              )}-${companyData?.phone?.substring(3)}`}
                              className="ps-1 pe-2 opc8"
                            >
                              <img src={phone} alt="" />
                            </Link>

                            <Link
                              onClick={() => {
                                setShowEmail(true);
                                setShowEmailData([
                                  {
                                    email: companyData.email,
                                    name: companyData.name,
                                    selected: true,
                                  },
                                ]);
                              }}
                              className="px-2 opc8"
                            >
                              <img src={message} alt="" />
                            </Link>
                          </div>
                        </div>
                        <div className="company-satisfaction-container">
                          {isLoading ? (
                            <p>Loading...</p> // Display a loading indicator
                          ) : satisfactionData ? (
                            <div className="company-satisfaction float-end">
                              <p className="title-cs">
                                Customer <br /> Satisfaction
                              </p>
                              <p className="title-pr d-inline-flex align-items-center mb-1">
                                <img src={smile} alt="Smile" className="me-1" />
                                {satisfactionData.percentage
                                  ? satisfactionData.percentage + "%"
                                  : "N/A"}
                              </p>
                            </div>
                          ) : (
                            <div className="company-satisfaction float-end">
                              <p className="title-cs">
                                Customer <br /> Satisfaction
                              </p>
                              <p className="title-pr d-inline-flex align-items-center mb-1">
                                <img src={smile} alt="Smile" className="me-1" />
                                N/A
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row compamy-numbers">
                    <div className="col-sm-4 mb-2 mb-sm-0">
                      <h6 className="fw-bold">
                        {companyData?.employeess?.length}
                      </h6>
                      <p>Total Employees</p>
                    </div>
                    <div className="col-sm-4 mb-2 mb-sm-0 px-0 bdr-color border-end border-start">
                      <h6 className="fw-bold">
                        {moment(companyData.createdAt).format("MM/DD/YYYY")}
                      </h6>
                      <p>Start Date</p>
                    </div>
                    <div className="col-sm-4 mb-2 mb-sm-0">
                      <h6 className="fw-bold">
                        {moment(companyData?.plainExpire).diff(
                          moment(),
                          "days"
                        )}
                      </h6>
                      <p>Days Left</p>
                    </div>
                  </div>
                  <hr style={{ borderBottom: "1px solid #E9EDF7" }} />
                  <div className="row compamy-numbers mb-3">
                    <div className="col-sm-4 mb-2 mb-sm-0">
                      <h6 className="fw-bold">{activeUserCount}</h6>
                      <p> Active</p>
                    </div>
                    <div className="col-sm-4 mb-2 mb-sm-0 px-0 bdr-color border-end border-start">
                      <h6 className="fw-bold">{total - activeUserCount}</h6>
                      <p>Inactive</p>
                    </div>
                    <div className="col-sm-4 mb-2 mb-sm-0">
                      <h6 className="fw-bold">0</h6>
                      <p>To be Activated</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* /Col */}
            <div className="col-xl-4 mb-4 align-items-stretch">
              <div className="card h-100">
                <div className="card-body py-2 px-0">
                  <div id="pain_activity_count_data">
                    {/* Export Button */}
                    <div className="bulk-action d-flex justify-content-end me-2">
                      <button
                        className="btn btn-sm btn-success"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                        type="button"
                      >
                        Export
                      </button>

                      <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3 w-25">
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                            onClick={() =>
                              handleGraphDropdownChange("Pain Level")
                            }
                          >
                            Pain Level
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() =>
                              handleGraphDropdownChange("Functional Level")
                            }
                          >
                            Functional Level
                          </button>
                        </li>
                      </ul>
                    </div>

                    {/* Selection Dropdown */}

                    {scaleChartOption ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={scaleChartOption}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* /Col */}

            <div className="col-xl-4 mb-4 align-items-stretch">
              <div className="card h-100">
                <div className="card-body py-2 px-0">
                  <div id="pain_activity_functional">
                    {/* Export Button */}

                    <div className="bulk-action d-flex justify-content-end me-2">
                      <button
                        className="btn btn-sm btn-success"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                        type="button"
                      >
                        Export
                      </button>

                      <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3 w-25">
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                            onClick={() => handleDropdownChange("Pain Level")}
                          >
                            Avg Pain Level
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() =>
                              handleDropdownChange("Functional Level")
                            }
                          >
                            Avg Functional Level
                          </button>
                        </li>
                      </ul>
                    </div>

                    {/* Selection Dropdown */}

                    {chartOptionForActivityAndPain ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptionForActivityAndPain}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* /Col */}

            {/* /Col */}
            <div className="col-xl-4 mb-4 align-items-stretch">
              <div className="card h-100">
                <div className="card-body py-2 px-0">
                  <div id="satisfaction_data">
                    {/* Export Button */}
                    <div className="bulk-action d-flex justify-content-end me-2">
                      <button
                        className="btn btn-sm btn-success"
                        onClick={handleCompanySatisfactionExportData}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                        type="button"
                      >
                        Export
                      </button>
                    </div>

                    {/* Selection Dropdown */}

                    {satisfactionChartOption ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={satisfactionChartOption}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* /Col */}
          </div>
        )}
      </div>

      <div className="row align-items-center">
        <div className="col-9">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link to="/company">Companies</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Employees
              </li>
            </ol>
          </nav>
          <h1 className="page-title">
            Employees<span>({total})</span>
          </h1>
        </div>

        <div className="col-3 ps-0 text-end">
          <button
            onClick={handleEmployeeExportData}
            className="btn btn-sm btn-success"
          >
            Export
          </button>
        </div>
        <div className="col-sm-7 mb-3 mb-sm-0 d-flex justify-content-start justify-content-sm-end align-items-center"></div>
      </div>

      <div className="col-xxl-12 mb-3">
        <div className="card customer-sf">
          <div className="p-3 pb-0">
            <div className="input-group search-table">
              <input
                type="text"
                className="form-control form-control-filter"
                placeholder="Search Employee"
                onChange={searchPatientHandler}
              />

              <i className="icon-13 search-ic" />
              <div className="btn-group bulk-action ">
                <button
                  type="button"
                  className="filter-btn px-3 px-md-4"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <i className="fa fa-filter me-2" /> Filters
                </button>
                <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleSort("newest")}
                    >
                      Newest
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleSort("oldest")}
                    >
                      Oldest
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleSort("updated")}
                    >
                      Recently Updated
                    </button>
                  </li>
                  <span>By Tag</span>
                  <li>
                    {tags.map((item) => (
                      <button
                        className={`dropdown-item ${
                          item?._id === currentTag?._id ? "active" : ""
                        }`}
                        type="button"
                        onClick={() => {
                          if (item?._id === currentTag?._id) {
                            setCurrentTag(null);
                          } else {
                            setCurrentTag(item);
                          }
                        }}
                      >
                        {item.name}
                      </button>
                    ))}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="companies-table">
            <div class="text-end">
              {employeeData && employeeData.some((item) => item.selected) ? (
                <div className="mt-1">
                  <label className="col-form-label">Selected {count}</label>
                  <div className="btn-group bulk-action ms-2">
                    <button
                      type="button"
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Bulk Actions
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end drop-tow mt-3"
                      style={{}}
                    >
                      <li>
                        <button
                          className="dropdown-item "
                          type="button"
                          onClick={openEmailModal}
                        >
                          Send Email
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item "
                          type="button"
                          onClick={() => setTagModel(true)}
                        >
                          Add tag
                        </button>
                      </li>
                      {currentTag?.name ? (
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                            onClick={openEmailModal}
                          >
                            Remove tags
                          </button>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="pb-4 companies-table ">
            <div className="mt-2">
              <div className="row d-flex">
                <div className="col-sm-5"></div>
                {userData.role === "admin" ? (
                  <></>
                ) : (
                  <div className="col-sm-7 mb-3 mb-sm-0 d-flex justify-content-start justify-content-sm-end align-items-center">
                    <a href={companyTem} download="Template.xlsx">
                      <button
                        className="btn company-btn download-button"
                        style={{ color: "white" }}
                      >
                        Download Template
                      </button>
                    </a>
                    &nbsp;
                    <button
                      className="btn company-btn"
                      onClick={handleOpenModal}
                    >
                      <i className="icon-19" /> Add Single Employee
                    </button>
                    &nbsp;
                    <div>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={convertCSVtoObjectHandler}
                      />
                      <button
                        className="btn company-btn"
                        onClick={handleButtonClick}
                      >
                        <i className="icon-19" /> Upload Excel
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div
                id="example_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="row">
                  <div className="col-sm-12 table-responsive">
                    <table
                      id="example"
                      className="table display table-edits dataTable no-footer"
                      cellSpacing={0}
                      width="100%"
                      role="grid"
                      aria-describedby="example_info"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr role="row">
                          <th>
                            <span className="nochecked">
                              <div className="form-check">
                                <input
                                  className="form-check-input mr-1"
                                  type="checkbox"
                                  defaultValue=""
                                  id="remember1"
                                  checked={selectedAll}
                                  onClick={() =>
                                    handleIncrementClick("", true, true)
                                  }
                                />
                              </div>
                            </span>
                          </th>
                          <th>Name</th>
                          <th className="text-nowrap">Email</th>

                          <th className="text-nowrap">Phone Number</th>
                          <th className="text-nowrap">Date Of Birth</th>

                          <th className="text-nowrap">Start Date</th>
                          <th className="text-nowrap">Gender</th>

                          <th className="text-nowrap">Last Seen</th>

                          <th className="text-nowrap">Progress</th>

                          <th className="text-nowrap text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeData &&
                          employeeData.map((employee, index) => (
                            <tr className="odd" key={index}>
                              <td className="checked">
                                <span className="form-check d-flex align-items-center ">
                                  <input
                                    className="form-check-input mr-1"
                                    type="checkbox"
                                    checked={employee?.selected}
                                    onClick={(e) => {
                                      handleIncrementClick(
                                        employee._id,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                </span>
                              </td>
                              <td>
                                <label
                                  className="form-check-label text-truncate d-flex align-items-center"
                                  htmlFor={`remember${index}`}
                                >
                                  <span className="uusr_img me-2">
                                    <ImageComponent
                                      src={
                                        employee?.profileImage
                                          ? `${employee?.profileImage}`
                                          : userDemo
                                      }
                                    />
                                  </span>
                                  {employee.name}
                                </label>
                              </td>
                              <td>{employee.email}</td>
                              <td>{employee.phone}</td>
                              <td className="text-truncate  ">
                                {moment(employee.dateOfBirth).format(
                                  "MM/DD/YYYY"
                                )}
                              </td>

                              <td className="text-nowrap">
                                {moment(employee.createdAt).format(
                                  "MM/DD/YYYY"
                                )}
                              </td>
                              <td className="text-truncate">
                                {employee.gender}
                              </td>

                              <td>
                                {employee?.lastSeen && employee?.address
                                  ? moment(employee?.lastSeen).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "Null"}
                              </td>

                              <td className="align-middle">
                                <div className="progress progress-bg1">
                                  <div
                                    className="progress-bar progress-red"
                                    role="progressbar"
                                    style={{
                                      width: calculateCompletionPercentage(
                                        employee.currentUserAnswer
                                          ?.userVideoModule
                                      ),
                                    }}
                                    aria-valuenow={0}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  ></div>
                                </div>
                              </td>
                              <td className="text-nowrap text-center">
                                <Link
                                  to={`tel:${employee.phone.substring(
                                    0,
                                    3
                                  )}-${employee.phone.substring(3)}`}
                                  className="action-btn"
                                >
                                  <i className="fa fa-phone" title="Phone"></i>
                                </Link>
                                <Link
                                  onClick={() => {
                                    setShowEmail(true);
                                    setShowEmailData([
                                      {
                                        email: employee.email,
                                        name: employee.name,
                                        selected: true,
                                      },
                                    ]);
                                  }}
                                  className="action-btn"
                                >
                                  <i
                                    className="fa fa-envelope"
                                    title="Message"
                                  ></i>
                                </Link>

                                <Link
                                  to={`/employee-details/${employee._id}`}
                                  className="action-btn"
                                >
                                  <i
                                    className="fa-regular fa-eye"
                                    title="View"
                                  />
                                </Link>
                                <Link
                                  className="action-btn"
                                  onClick={() => handleEditEmployee(employee)}
                                >
                                  <i className="fa fa-pencil" title="Edit"></i>
                                </Link>
                                <Link
                                  className="action-btn"
                                  onClick={() => openDeleteModel(employee._id)}
                                >
                                  <i className="fa fa-trash" title="Delete"></i>
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EmployeeListModal
        show={openEmployeeListModal}
        handleClose={() => {
          setOpenEmployeeListModal(false);
        }}
        onSubmit={async (data) => {
          await createMultipleEmployeeApi(id, data);
          setOpenEmployeeListModal(false);
          await fetchCompanyData();
        }}
        data={employeeDataArray}
        title={"Employee List"}
        buttonTitle="Create Employees"
      />

      <Modal
        show={showModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className="modal fade success-popup add-company"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {editingEmployeeData ? "Edit Employee" : "Add New Employee"}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <img src={cros} alt="" />
          </button>
        </div>
        <div className="modal-body text-start">
          <form onSubmit={formik.handleSubmit}>
            <h6 className="course-title text-start">Employee Details</h6>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">Name</label>
              <input
                type="text"
                className={`form-control ${
                  formik.errors.name && formik.touched.name ? "is-invalid" : ""
                }`}
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange("name")}
              />
              {formik.errors.name && formik.touched.name && (
                <div className="invalid-feedback">{formik.errors.name}</div>
              )}
            </div>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">Email</label>
              <input
                type="email"
                className={`form-control ${
                  formik.errors.email && formik.touched.email
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange("email")}
                maxLength={256}
              />
              {formik.errors.email && formik.touched.email && (
                <div className="invalid-feedback">{formik.errors.email}</div>
              )}
            </div>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">Phone</label>
              <input
                type="tel"
                className={`form-control ${
                  formik.errors.phone && formik.touched.phone
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Phone"
                value={formik.values.phone}
                onChange={formik.handleChange("phone")}
                maxLength={10}
              />
              {formik.errors.phone && formik.touched.phone && (
                <div className="invalid-feedback">{formik.errors.phone}</div>
              )}
            </div>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">Date of birth</label>
              <input
                type="date"
                className={`form-control ${
                  formik.errors.dateOfBirth && formik.touched.dateOfBirth
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Date of Birth"
                value={formik.values.dateOfBirth}
                onChange={formik.handleChange("dateOfBirth")}
                max={new Date().toISOString().split("T")[0]}
              />
              {formik.errors.dateOfBirth && formik.touched.dateOfBirth && (
                <div className="invalid-feedback">
                  {formik.errors.dateOfBirth}
                </div>
              )}
            </div>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">Gender</label>
              <select
                className={`form-select ${
                  formik.errors.gender && formik.touched.gender
                    ? "is-invalid"
                    : ""
                }`}
                value={formik.values.gender}
                onChange={formik.handleChange("gender")}
                placeholder="Gender"
              >
                <option value="" disabled>
                  Select gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              {formik.errors.gender && formik.touched.gender && (
                <div className="invalid-feedback">{formik.errors.gender}</div>
              )}
            </div>

            <div className="modal-footer justify-content-start">
              <button
                className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
                type="submit"
              >
                {editingEmployeeData ? "Save Changes" : "Add Employee"}
              </button>
              <button
                className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <DeleteModel
        show={deleteModal}
        handleClose={closeDeleteModel}
        data={deleteData}
        deleteFunction={deleteSingleCompanyHandler}
      />
      <PaginationComponent
        total={total}
        page={page}
        limit={limit}
        setPage={setPage}
      />
      <AddTagModel
        show={tagModel}
        handleClose={closeTagModel}
        data={tags}
        selectTagFunction={selectTagFunction}
      />
    </>
  );
};

export default SingleCompany;
