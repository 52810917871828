import logoSm from "../assets/images/logo_sm.png";
import logo from "../assets/images/hanna1.png";
import sclose from "../assets/images/sclose.svg";
import profile from "../assets/images/profile.png";
import defaultImg from "../assets/images/defaultImg.jpg";
import { Link } from "react-router-dom";
import useApiAxios from "../api/useApiAxios";
import { useContext, useState } from "react";
import { Context } from "../context/Context";
import ImageComponent from "./media/ImageComponent";
const Header = (props) => {
  const { userData } = useContext(Context);
  const { logOutApi, searchApi } = useApiAxios();
  const [search, setSearch] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <a href="/" className="logo logo-dark">
              <span className="logo-sm">
                {" "}
                <img src={logoSm} alt="VIRT" />{" "}
              </span>{" "}
              <span className="logo-lg">
                {" "}
                <img src={logo} alt="VIRT" />{" "}
              </span>
            </a>
          </div>
          <button
            type="button"
            className="btn btn-sm ps-1 ps-md-3 px-md-3 menu-hamburger header-item"
            id="vertical-menu-btn"
            onClick={() =>
              props.setSideBarEnable(props.sideBarEnable ? false : true)
            }
          >
            <i className="icon-5"></i>
          </button>
          <form className="app-search d-none d-xl-block">
            <div className="position-relative search-inp">
              <input
                type="text"
                className="form-control"
                id="search-suto"
                placeholder="Search here..."
                onChange={async (e) => {
                  const res = await searchApi(e.target.value);
                  setSearchResult(res.data.data);
                }}
                onClick={() => setSearch(true)}
              />
              <span className="icon-13" onClick={() => setSearch(false)}></span>
              <a onClick={() => setSearch(false)} className="speaker">
                <img src={sclose} alt="" />
              </a>
            </div>
            <div
              className="search-list"
              id="search-suto-list"
              style={{ display: search ? "block" : "none" }}
            >
              <ul>
                {searchResult.length === 0 && search ? (
                  <li> No result found </li>
                ) : (
                  searchResult.map((item) => (
                    <li>
                      <Link
                        onClick={() => setSearch(false)}
                        to={`/employee-details/${item._id}`}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))
                )}{" "}
                {}
              </ul>
            </div>
          </form>
        </div>
        <div className="d-flex align-items-center">
          <div className="dropdown d-inline-block d-xl-none ms-2">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="icon-13"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-end py-0 px-3"
              aria-labelledby="page-header-search-dropdown"
            >
              <form className="app-search">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                  />
                  <span className="icon-13"></span>
                  <a href="#!" className="speaker">
                    <img src={sclose} />
                  </a>
                </div>
              </form>
            </div>
          </div>

          <div className="credit_sms ms-2 d-flex align-items-center">
            <div className="dropdown d-inline-flex">
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown"
                style={{ width: "320px" }}
              >
                <div className="p-3">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="m-0" key="t-notifications">
                        Notifications
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  {" "}
                  <h6 className="mb-1" key="t-your-order">
                    No new Notification
                  </h6>
                </div>
              </div>
            </div>
            <div className="dropdown d-inline-flex profile_dropdown">
              <button
                type="button"
                className="btn w-100 header-item waves-effect d-flex justify-content-between align-items-center position-relative"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <ImageComponent
                  className="rounded-circle header-profile-user me-2"
                  src={
                    userData?.role === "admin"
                      ? profile
                      : userData?.physicalTherapist?.profileImage
                      ? userData?.physicalTherapist?.profileImage
                      : defaultImg
                  }
                  alt="Header Avatar"
                />

                <span
                  className="d-none d-md-inline-block ms-1 text-start pe-2 pe-md-4"
                  key="t-henry"
                >
                  <strong>{userData.name}</strong>
                  <small className=" d-block">
                    {userData?.role === "admin"
                      ? "Admin"
                      : "Physical Therapist"}
                  </small>
                </span>
                <i className="fa fa-angle-down down_arrow"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end w-100">
                {userData?.role === "admin" ? (
                  <></>
                ) : (
                  <a className="dropdown-item" href="/profile">
                    <i className="icon-11 align-middle me-1"></i>{" "}
                    <span key="t-profile">Account Profile</span>
                  </a>
                )}
                <Link to="/change/password" className="dropdown-item">
                  <i className="icon-11 align-middle me-1"></i>{" "}
                  <span key="t-profile">Change Password</span>
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item text-danger" onClick={logOutApi}>
                  <i className="fa fa-power-off align-middle me-1 text-danger"></i>{" "}
                  <span key="t-logout">Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
