import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import useApiAxios from "../../api/useApiAxios";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import usStates from "./usStates.json"; // Import the JSON file

const AddCompany = () => {
  const { createCompany, getAllTagsApi } = useApiAxios(); 

  const initialValues = {
    name: "",
    industry: "",
    country: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    employees: "",
    selectPlan: "",

  };
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);

  const getTags = async () => {
    const { data, error } = await getAllTagsApi();
    if (!error) {
      const dataArray = [];
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        dataArray.push({ value: element._id, label: element.name });
      }
      setTags(dataArray);
    }
  };
  useEffect(() => {
    getTags();
  }, []);

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .max(50, "Name must be at most 50 characters"),
    industry: Yup.string().required("Industry is required"),
    country: Yup.string()
      .required("Address is required")
      .max(50, "Address must be at most 50 characters"),
    address: Yup.string()
      .required("Address is required")
      .max(50, "Address must be at most 50 characters"),
    city: Yup.string()
      .required("City is required")
      .max(25, "City must be at most 25 characters"),
    state: Yup.string().required("State is required"),
    zip: Yup.string()
      .required("Zip code is required")
      .max(6, "zip must be at most 6 characters"),
    phone: Yup.string()
      .matches(/^\d{10}$/, {
        message: "Invalid Phone Number",
        excludeEmptyString: true,
      })
      .required("Phone number is required"),

    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .max(255, "Email must be at most 25 characters"),
    employees: Yup.string().required("Employee number is required"),
    selectPlan: Yup.string().required("Plan is required"),
   
  });

  // Dummy industry options
  const industryOptions = [
    { value: "tech", label: "Technology" },
    { value: "finance", label: "Finance" },
    { value: "healthcare", label: "Healthcare" },
    { value: "others", label: "others" },
 
  ];

  const stateOptions = usStates; // Use the imported JSON data

  const employeeOptions = [
    { value: "1 to 50", label: "1 to 50" },
    { value: "51 to 100", label: "51 to 100" },
    { value: "101 to 500", label: "101 to 500" },
    { value: "500+", label: "500+" },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    console.log(values);
    const { error } = await createCompany({ ...values, tags: selectedTags });
    setSubmitting(false);

    if (!error) {
      navigate("/company");
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div className="container-fluid">
      <div className="row d-flex ">
        <div className="col-sm-5">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link to="#">Company</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Add Company
              </li>
            </ol>
          </nav>
          <h1 className="page-title">Add New Company</h1>
        </div>
      </div>
      {/* Companies */}
      <div className="row align-items-stretch">
        {/* Companies */}
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf h-100">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-30">
                <div className="row mt-4">
                  <div className="col-md-6 col-xl-6">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-sm-4 col-form-label">Name</label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.name && formik.touched.name
                              ? "is-invalid"
                              : ""
                          }`}
                          id="name"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          maxLength={50}
                        />
                        {formik.errors.name && formik.touched.name && (
                          <div className="invalid-feedback">
                            {formik.errors.name}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6 col-xl-6">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-sm-4 col-form-label">
                        Industry
                      </label>
                      <div className="col-sm-8">
                        <select
                          className={`form-select ${
                            formik.errors.industry && formik.touched.industry
                              ? "is-invalid"
                              : ""
                          }`}
                          id="industry"
                          name="industry"
                          value={formik.values.industry}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                        >
                          <option value="">Choose...</option>
                          {industryOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {formik.errors.industry && formik.touched.industry && (
                          <div className="invalid-feedback">
                            {formik.errors.industry}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-xl-6">
                  <div className="row mb-3 form-fieldc">
                    <label className="col-sm-4 col-form-label">Country</label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.country && formik.touched.country
                            ? "is-invalid"
                            : ""
                        }`}
                        id="country"
                        name="country"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.country && formik.touched.country && (
                        <div className="invalid-feedback">
                          {formik.errors.country}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6">
                  <div className="row mb-3 form-fieldc">
                    <label className="col-sm-4 col-form-label">Address</label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.address && formik.touched.address
                            ? "is-invalid"
                            : ""
                        }`}
                        id="address"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        maxLength={256}
                      />
                      {formik.errors.address && formik.touched.address && (
                        <div className="invalid-feedback">
                          {formik.errors.address}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 col-xl-6">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-sm-4 col-form-label">City</label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.city && formik.touched.city
                              ? "is-invalid"
                              : ""
                          }`}
                          id="city"
                          name="city"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          maxLength={25}
                        />
                        {formik.errors.city && formik.touched.city && (
                          <div className="invalid-feedback">
                            {formik.errors.city}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-md-6">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="row mb-3 form-fieldc">
                          <label className="col-md-5 col-sm-4 col-form-label text-nowrap ps-3 ps-lg-5">
                            State
                          </label>
                          <div className="col-md-7 col-sm-8">
                            <select
                              className={`form-select ${
                                formik.errors.state && formik.touched.state
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="state"
                              name="state"
                              value={formik.values.state}
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                            >
                              <option value="">Choose...</option>
                              {stateOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {formik.errors.state && formik.touched.state && (
                              <div className="invalid-feedback">
                                {formik.errors.state}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="row mb-3 form-fieldc">
                          <label className="col-md-3 col-sm-4 col-form-label text-nowrap">
                            Zip
                          </label>
                          <div className="col-md-9 col-sm-8">
                            <input
                              type="text"
                              className={`form-control ${
                                formik.errors.zip && formik.touched.zip
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="zip"
                              name="zip"
                              value={formik.values.zip}
                              onChange={formik.handleChange}
                              maxLength={6}
                            />
                            {formik.errors.zip && formik.touched.zip && (
                              <div className="invalid-feedback">
                                {formik.errors.zip}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-lg-6 col-xl-6 col-md-6">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-sm-4 col-form-label">Phone</label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.phone && formik.touched.phone
                              ? "is-invalid"
                              : ""
                          }`}
                          id="phone"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          maxLength={10}
                          pattern="\+?\d{10}"
                        />
                        {formik.errors.phone && formik.touched.phone && (
                          <div className="invalid-feedback">
                            {formik.errors.phone}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-6 col-md-6">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-md-3 col-sm-4 col-form-label text-nowrap ps-3 ps-lg-5">
                        Email
                      </label>
                      <div className="col-md-9 col-sm-8">
                        <input
                          type="email"
                          className={`form-control ${
                            formik.errors.email && formik.touched.email
                              ? "is-invalid"
                              : ""
                          }`}
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          maxLength={256}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="row mb-3 form-fieldc d-flex align-items-center">
                    <label className="col-md-2 col-sm-4 col-form-label">
                      Employees
                    </label>
                    <div className="col-md-10 col-sm-8 h_50">
                      {employeeOptions.map((option) => (
                        <div
                          key={option.value}
                          className="form-check form-check-inline py-2 pe-2"
                        >
                          <input
                            className="form-check-input mr-1"
                            type="radio"
                            name="employees"
                            value={option.value}
                            id={`employeeNumber${option.value}`}
                            onChange={formik.handleChange}
                          />

                          <label
                            className="form-check-label font14"
                            htmlFor={`employeeNumber${option.value}`}
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                      {formik.errors.employees && formik.touched.employees && (
                        <div className="invalid-feedback">
                          {formik.errors.employees}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 col-xl-6">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-sm-4 col-form-label">Tags</label>
                      <div className="col-sm-8">
                        <Select
                          isSearchable={false}
                          isClearable={true}
                          isMulti
                          name="tags"
                          className="form-control"
                          // setValue={multiSelectValue}
                          onChange={(e) => {
                            const dataToSet = [];
                            for (let index = 0; index < e.length; index++) {
                              const element = e[index];
                              dataToSet.push(element.value);
                            }
                            setSelectedTags(dataToSet);
                          }}
                          options={tags}
                        />
                        {formik.touched.experience &&
                        formik.errors.experience ? (
                          <div className="error">
                            {formik.errors.experience}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6 col-lg-12 col-xl-6">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-sm-4 col-form-label" />
                      <div className="col-sm-8">
                        <button
                          className="btn addsubmit-btn green-btn me-3 mb-3"
                          type="submit"
                          disabled={formik.isSubmitting}
                        >
                          Add
                        </button>

                        <Link
                          to="/company"
                          className="btn addsubmit-btn grey-btn  mb-3"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* /Companies */}
      </div>
      {/*/Row*/}
      {/* Companies */}
    </div>
  );
};

export default AddCompany;
