import React from "react";
import cros from "../../assets/images/cros.svg";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const AddCourses = ({ show, handleClose, fun }) => {

  const { createCourseApi } = useApiAxios();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      icon: null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      icon: Yup.mixed().required("Icon is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("type", values.type);

      if (values.icon) {
        formData.append("icon", values.icon);
      }

      const response = await createCourseApi(formData);
      if (!response.error) {
        resetForm();
        await fun();
        handleClose();
      }
    },
  });

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className="modal fade success-popup add-company"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Add New Course
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <img src={cros} alt="" />
          </button>
        </div>
        <div className="modal-body text-start">
          <form onSubmit={formik.handleSubmit}>
            <div className="col-lg-12 text-start">
              <p className="course-title text-start">Course Details</p>
              <p className="course-subtitle text-start mb-0">
                We'll use your title and description to generate a sample course
                outline:
              </p>
            </div>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">Title</label>
              <input
                type="text"
                className={`form-control ${
                  formik.errors.title && formik.touched.title
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Title Name"
                value={formik.values.title}
                onChange={formik.handleChange("title")}
              />
              {formik.errors.title && formik.touched.title && (
                <div className="invalid-feedback">{formik.errors.title}</div>
              )}
            </div>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-3">Brief Description</label>
              <SunEditor
                width="100%"
                setContents={formik.values.description}
                onChange={(editorText) =>
                  formik.setFieldValue("description", editorText)
                }
                setOptions={{
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    ["fontColor", "hiliteColor"],
                    ["align", "list", "lineHeight"],

                    ["table", "horizontalRule", "link"],
                  ],
                  defaultTag: "div",
                  minHeight: "300px",
                  showPathLabel: false,
                }}
              />
              {formik.errors.description && formik.touched.description && (
                <div className="invalid-feedback">
                  {formik.errors.description}
                </div>
              )}
            </div>
          
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-3">Icon</label>
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className={`form-control ${
                  formik.errors.icon && formik.touched.icon ? "is-invalid" : ""
                }`}
                placeholder="Icon Name"
                onChange={(event) =>
                  formik.setFieldValue("icon", event.currentTarget.files[0])
                }
              />
              {formik.errors.icon && formik.touched.icon && (
                <div className="invalid-feedback">{formik.errors.icon}</div>
              )}
            </div>
            <div className="modal-footer justify-content-start">
              <button
                href=""
                className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
                type="submit"
              >
                Continue
              </button>
              <button
                href=""
                onClick={handleClose}
                className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddCourses;
