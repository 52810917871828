import React from "react";
import cros from "../../assets/images/cros.svg";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import "suneditor/dist/css/suneditor.min.css";
import Select from "react-select";

const AddEmailAutoModel = ({ show, handleClose, fun, selectEmailData }) => {
  const { createEmailAutoApi } = useApiAxios();

  const formik = useFormik({
    initialValues: {
      when: "",
      userType: "",
      emailTemplate: "",
    },
    validationSchema: Yup.object({
      when: Yup.string().required("When is required"),
      userType: Yup.string().required("User Type is required"),
      emailTemplate: Yup.string().required("Internal Title is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const response = await createEmailAutoApi(values);
      if (!response.error) {
        resetForm();
        await fun();
        handleClose();
      }
    },
  });

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className="modal fade success-popup add-company"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            New Automation
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <img src={cros} alt="" />
          </button>
        </div>
        <div className="modal-body text-start">
          <form onSubmit={formik.handleSubmit}>
            <div className="col-lg-12 mb-4">
              <label className="col-sm-4 col-form-label">When</label>
              <div className="col-sm-12">
                <select
                  className="form-select"
                  {...formik.getFieldProps("when")}
                >
                  <option value="">Select Automation</option>
                  <option value="inactive7">
                    Person has been inactive for 7 days
                  </option>
                  <option value="inactive14">
                    Person has been inactive for 14 days
                  </option>
                  <option value="inactive30">
                    {" "}
                    Person has been inactive for 30 days
                  </option>
                  <option value="inactive60">
                    {" "}
                    Person has been inactive for 60 days
                  </option>
                  <option value="inactive90">
                    {" "}
                    Person has been inactive for 90 days
                  </option>
                </select>
                {formik.touched.when && formik.errors.when ? (
                  <div className="error">{formik.errors.when}</div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <label className="col-sm-4 col-form-label">For</label>
              <div className="col-sm-12">
                <select
                  className="form-select"
                  {...formik.getFieldProps("userType")}
                >
                  <option value="">Select Automation</option>
                  <option value="user">Employee/Patient</option>
                  <option value="admin">Physical Therapist</option>
                </select>
                {formik.touched.userType && formik.errors.userType ? (
                  <div className="error">{formik.errors.userType}</div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2"> Select Internal Title</label>
              <Select
                isSearchable={true}
                isClearable={true}
                name="emailTemplate"
                className="form-control"
                onChange={(e) => {
                  formik.setFieldValue("emailTemplate", e.value);
                }}
                options={selectEmailData}
              />
              {formik.touched.emailTemplate && formik.errors.emailTemplate ? (
                <div className="error">{formik.errors.emailTemplate}</div>
              ) : null}
            </div>

            <div className="modal-footer justify-content-start">
              <button
                href=""
                className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
                type="submit"
              >
                Continue
              </button>
              <button
                href=""
                onClick={handleClose}
                className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddEmailAutoModel;
