import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AddCourseVideos from "./AddCourseVideos";
import useApiAxios from "../../api/useApiAxios";
import EditCourseVideos from "./EditCourseVideos";
import Preview from "./Preview";
import AddCourseVideosModule from "./AddCourseVideosModule";
import EditCourseVideosModule from "./EditCourseVideosModule";
import DeleteModel from "../deleteModel/DeleteModel";
import DeleteModuleModel from "../deleteModel/DeleteModuleModel";
import moment from "moment";
import ImageComponent from "../media/ImageComponent";
import VideoComponent from "../media/VideoComponent";

const CourseVideos = () => {
  const [show, setShow] = useState(false);
  const {
    
    handleVideoDelete,
    updateCourseVideoViewApi,
    getAllVideoModule,
    updateCourseVideoModuleViewApi,
    handleVideoModuleDelete,
    getAllEmailTemplateApi,
  } = useApiAxios();
  const [editThumbnail, setEditThumbnail] = useState("");
  const [editThumbnailUrl, setEditThumbnailUrl] = useState("");
  const [editVideo, setEditVideo] = useState("");
  const [editVideoUrl, setEditVideoUrl] = useState("");
  const [page, setPage] = useState(1);
  const [openVideoModuleModal, setOpenVideoModuleModal] = useState(false);
  const [currentCourseVideoData, setCurrentCourseVideoData] = useState({});
  const [currentVideoModule, setCurrentVideoModule] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showVideoModuleEditModal, setShowVideoModuleEditModal] =
    useState(false);
  const [courses, setCourses] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [selectEmailData, setSelectEmailData] = useState([]); // State to store the selected course ID

  const searchCourseVideosHandler = async (e) => {
    setSearch(e.target.value);
  };

  const { id } = useParams();

  const fun = async () => {
    const data = await getAllVideoModule(id, page, sort, search);
    setCourses(data.data.data);
    const data2 = await getAllEmailTemplateApi();
    const dataArray = [];
    for (let index = 0; index < data2.data.data.length; index++) {
      const element = data2.data.data[index];
      dataArray.push({ value: element._id, label: element.internalTitle });
    }
    setSelectEmailData(dataArray);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [deleteModuleModal, setDeleteModuleModal] = useState(false);
  const [deleteModuleData, setDeleteModuleData] = useState(null);

  const openDeleteModel = (data) => {
    setDeleteData(data);
    setDeleteModal(true);
  };
  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };

  const openDeleteModuleModel = (data) => {
    setDeleteModuleData(data);
    setDeleteModuleModal(true);
  };
  const closeDeleteModuleModel = () => {
    setDeleteModuleModal(false);
    setDeleteModuleData(null);
  };
  const deleteCourseVideoHandler = async (data) => {
    await handleVideoDelete(data);
    await fun();
    closeDeleteModuleModel();
  };
  const deleteModuleHandler = async (data) => {
    await handleVideoModuleDelete(data);
    await fun();
    closeDeleteModel();
  };

  useEffect(() => {
    fun();
    document.title = "Videos";
  }, [page, sort, search]);
  const handleShow = () => {
    setShow(true);
  };
  const handleEditCourseVideos = async (video) => {
    setCurrentCourseVideoData(video);
    setShowModal(true);
  };
  const handleEditCourseVideosModule = async (video) => {
    setCurrentVideoModule(video);
    setShowVideoModuleEditModal(true);
  };
  const handleOpenVideoModuleModal = async () => {
    setOpenVideoModuleModal(true);
  };

  const previewModalCloseHandler = async () => {
    setShowPreviewModal(false);
    setCurrentCourseVideoData({});
  };

  const handleSort = async (selectedSort) => {
    setSort(selectedSort);
    setPage(1);
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row d-flex ">
          <div className="col-xl-5 col-sm-5">
            <nav aria-label="breadcrumb" className="theme-breadcrumb">
              <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                <li className="breadcrumb-item">
                  <Link to="/product/courses">Courses </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  All Course Videos
                </li>
              </ol>
            </nav>
            <h1 className="page-title">All Course Videos</h1>
          </div>

          <div className="col-xl-7 col-sm-7 mb-3 mb-xl-0 d-flex justify-content-start justify-content-sm-end align-items-sm-center">
            <div
              className="btn company-btn"
              onClick={handleOpenVideoModuleModal}
            >
              <i class="fa-regular fa-plus"></i> Add Video module
            </div>
          </div>
        </div>

        <div className="row align-items-stretch">
          <div className="col-xxl-12 mb-3">
            <div className="card customer-sf h-100">
              <div className="p-3 pb-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-group search-table">
                      <input
                        type="text"
                        className="form-control form-control-filter"
                        placeholder="Search Module"
                        onChange={searchCourseVideosHandler}
                      />
                      <i className="icon-13 search-ic" />
                      <div className="btn-group bulk-action ">
                        <button
                          type="button"
                          className="filter-btn px-3 px-md-4"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <i className="fa fa-filter me-2" /> Filters
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                          <li>
                            <button
                              className="dropdown-item active"
                              type="button"
                              onClick={() => handleSort("newest")}
                            >
                              Newest
                            </button>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => handleSort("oldest")}
                            >
                              Oldest
                            </button>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => handleSort("update")}
                            >
                              Recently Updated
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-4 companies-table table-responsive product-table">
                <div className="mt-4">
                  {courses.length === 0 ? (
                    <p className="m-4">No Video Module Found</p>
                  ) : (
                    courses.map((item, itemIndex) => (
                      <div
                        key={item._id}
                        className="accordion m-4"
                        id="accordionExample"
                      >
                        <table
                          id="products-table"
                          className="table display table-responsive table-edits"
                          cellSpacing={0}
                          width="100%"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={`headingOne${itemIndex}`}
                            >
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseOne${itemIndex}`}
                                aria-expanded={
                                  itemIndex === 0 ? "true" : "false"
                                }
                                aria-controls={`collapseOne${itemIndex}`}
                              >
                                <div className="d-flex w-100 justify-content-between">
                                  <div className="d-flex  align-items-center  ">
                                    {item.title}
                                  </div>
                                  <div>
                                    <div className=" text-nowrap">
                                      <div className="d-flex  align-items-center  ">
                                        <span className="px-2">Draft</span>
                                        <div className="form-check form-switch">
                                          <input
                                            onChange={async (e) => {
                                              e.stopPropagation();
                                              await updateCourseVideoModuleViewApi(
                                                {
                                                  id: item._id,
                                                  publicView: e.target.checked,
                                                }
                                              );
                                              fun();
                                            }}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckChecked"
                                            checked={item.publicView}
                                          />
                                        </div>
                                        <span>Public</span>
                                        <Link
                                          className="action-btn"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleEditCourseVideosModule(item);
                                          }}
                                        >
                                          <i class="fa fa-pencil"></i>
                                        </Link>
                                        <Link
                                          className="action-btn"
                                          onClick={async (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            openDeleteModel(item._id);
                                            await fun();
                                          }}
                                        >
                                          <i class="fa fa-trash"></i>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </h2>
                            <div
                              id={`collapseOne${itemIndex}`}
                              className="accordion-collapse collapse show"
                              aria-labelledby={`headingOne${itemIndex}`}
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <div className="row d-flex ">
                                  <div className="col-xl-5 col-sm-5"></div>

                                  <div className="col-xl-7 col-sm-7 mb-3 mb-xl-0 d-flex justify-content-start justify-content-sm-end align-items-sm-center">
                                    <div
                                      className="btn company-btn mb-2"
                                      onClick={() => {
                                        handleShow();
                                        setCurrentVideoModule(item);
                                      }}
                                    >
                                      <i className="fa-regular fa-plus"></i> Add
                                      Video
                                    </div>
                                  </div>
                                </div>
                                {item.videos.length === 0 ? (
                                  <p>No Video Found</p>
                                ) : (
                                  <>
                                    <table
                                      className="table display table-edits"
                                      cellSpacing={0}
                                      width="100%"
                                    >
                                      <thead>
                                        <tr>
                                          <th>FILE</th>
                                          <th>DESCRIPTION</th>
                                          <th>THUMBNAIL</th>
                                          <th className="text-center">
                                            ACTION
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.videos.map((video, index) => (
                                          <tr key={index}>
                                            <td>
                                              <span className="product-img">
                                                <VideoComponent
                                                  src={`${video.filename}`}
                                                />
                                              </span>
                                            </td>

                                            <td className="pro-info text-nowrap">
                                              <h5>{video?.title}</h5>
                                              <p>
                                                <span className="course">
                                                  Video
                                                </span>
                                                <span className="course-no">
                                                  <i className="fa-regular fa-calendar-days" />{" "}
                                                  {moment(
                                                    video.createdAt
                                                  ).format("MM/DD/YYYY")}
                                                </span>
                                              </p>
                                            </td>
                                            <td>
                                              {" "}
                                              <span className="product-img">
                                                <ImageComponent
                                                  className="h-100"
                                                  src={`${video.thumbnail}`}
                                                  alt=""
                                                />
                                              </span>
                                            </td>
                                            <td className="text-nowrap">
                                              <div className="d-inline-flex  align-items-center justify-content-center ">
                                                <span className="px-2">
                                                  Draft
                                                </span>
                                                <div className="form-check form-switch">
                                                  <input
                                                    onChange={async (e) => {
                                                      await updateCourseVideoViewApi(
                                                        {
                                                          id: video._id,
                                                          publicView:
                                                            e.target.checked,
                                                        }
                                                      );
                                                      fun();
                                                    }}
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="flexSwitchCheckChecked"
                                                    checked={video.publicView}
                                                  />
                                                </div>
                                                <span>Public</span>
                                              </div>
                                              <a
                                                href="#!"
                                                className="btn btn-sm view-btn mx-3 por-btn"
                                                onClick={() => {
                                                  setShowPreviewModal(true);
                                                  setCurrentCourseVideoData(
                                                    video
                                                  );
                                                }}
                                              >
                                                <i class="fa fa-eye"></i>{" "}
                                                PREVIEW
                                              </a>
                                              <Link
                                                className="action-btn"
                                                onClick={() => {
                                                  handleEditCourseVideos(video);
                                                }}
                                              >
                                                <i class="fa fa-pencil"></i>
                                              </Link>
                                              <Link
                                                className="action-btn"
                                                onClick={() =>
                                                  openDeleteModuleModel(
                                                    video._id
                                                  )
                                                }
                                              >
                                                <i class="fa fa-trash"></i>
                                              </Link>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </table>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Preview
          show={showPreviewModal}
          handleClose={previewModalCloseHandler}
          data={currentCourseVideoData}
        />
    
      </div>

      <EditCourseVideos
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setEditThumbnail("");
          setEditThumbnailUrl("");
          setEditVideo("");
          setEditVideoUrl("");
          setCurrentCourseVideoData({});
        }}
        currentCourseVideoData={currentCourseVideoData} // Pass currentCourseVideoData
        fun={fun}
        course={id}
        editThumbnail={editThumbnail}
        setEditThumbnail={setEditThumbnail}
        editThumbnailUrl={editThumbnailUrl}
        setEditThumbnailUrl={setEditThumbnailUrl}
        editVideo={editVideo}
        setEditVideo={setEditVideo}
        editVideoUrl={editVideoUrl}
        setEditVideoUrl={setEditVideoUrl}
      />

      <EditCourseVideosModule
        show={showVideoModuleEditModal}
        selectEmailData={selectEmailData}
        handleClose={() => {
          setShowVideoModuleEditModal(false);
          setCurrentVideoModule({});
        }}
        currentVideoModule={currentVideoModule} // Pass currentCourseVideoData
        fun={fun}
      />

      <AddCourseVideos
        show={show}
        selectEmailData={selectEmailData}
        handleClose={() => {
          setShow(false);
          setCurrentVideoModule({});
        }}
        videoModule={currentVideoModule}
        fun={fun}
      />
      <AddCourseVideosModule
        show={openVideoModuleModal}
        handleClose={() => setOpenVideoModuleModal(false)}
        course={id}
        fun={fun}
        selectEmailData={selectEmailData}
      />

      <DeleteModel
        show={deleteModal}
        handleClose={closeDeleteModel}
        data={deleteData}
        deleteFunction={deleteModuleHandler}
      />

      <DeleteModuleModel
        show={deleteModuleModal}
        handleClose={closeDeleteModuleModel}
        data={deleteModuleData}
        deleteFunction={deleteCourseVideoHandler}
      />
    </div>
  );
};

export default CourseVideos;
