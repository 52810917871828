import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import EditableText from "./EditableText";
import EditableImage from "./EditableImage";

const PhysicalTherapistsContent = () => {
  const { getWebsiteContent, updateWebsiteContent } = useApiAxios();
  const [content, setContent] = useState({});
  const init = async () => {
    const { data, error } = await getWebsiteContent();
    if (!error) {
      setContent(data.data);
    }
  };
  const updateData = async () => {
    await updateWebsiteContent(content);
  };

  const onChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <div>
      <div className="container-fluid banner_area ">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-5 order-2 order-md-1">
            <EditableText
              text={content.t76}
              isHeading={true}
              level={1}
              className="banner-title mt-4 mt-md-0"
              name={"t76"}
              onChange={onChange}
            />

            <EditableText
              text={content.t77}
              isHeading={true}
              level={6}
              className="banner-subtitle"
              name={"t77"}
              onChange={onChange}
            />

            <Link to="/contact" className="book1-btn">
              GET A DEMO
            </Link>
          </div>
          <div className="col-md-7 order-1 order-md-2 text-end">
            <EditableImage
              src={`${content?.img13}`}
              title="Wellness video platform to prevent & reduce pain"
              alt=""
              className="img-fluid pt-5 banner-img"
              name="img13"
            />
          </div>
        </div>
      </div>
      <div className="container my-4">
        <div className="row">
          <div className="col-sm-12">
            <EditableText
              text={content.t78}
              isHeading={true}
              level={1}
              className="home_title text-center mb-3"
              name={"t78"}
              onChange={onChange}
            />
            <EditableText
              text={content.t79}
              isHeading={false}
              level={1}
              className="home_subtext text-center"
              name={"t79"}
              onChange={onChange}
            />
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                <EditableText
                  text={content.t80}
                  strong={true}
                  level={1}
                  className=""
                  name={"t80"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t81}
                  span={true}
                  level={1}
                  className=""
                  name={"t81"}
                  onChange={onChange}
                />
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <EditableText
                  text={content.t82}
                  strong={true}
                  level={1}
                  className=""
                  name={"t82"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t83}
                  span={true}
                  level={1}
                  className=""
                  name={"t83"}
                  onChange={onChange}
                />
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <EditableText
                  text={content.t84}
                  span={true}
                  level={1}
                  className=""
                  name={"t84"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t85}
                  strong={true}
                  level={1}
                  className=""
                  name={"t85"}
                  onChange={onChange}
                />
              </li>
            </ul>
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                <EditableText
                  text={content.t86}
                  span={true}
                  level={1}
                  className=""
                  name={"t86"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t87}
                  strong={true}
                  level={1}
                  className=""
                  name={"t87"}
                  onChange={onChange}
                />
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <EditableText
                  text={content.t88}
                  span={true}
                  level={1}
                  className=""
                  name={"t88"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t89}
                  strong={true}
                  level={1}
                  className=""
                  name={"t89"}
                  onChange={onChange}
                />
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <EditableText
                  text={content.t90}
                  strong={true}
                  level={1}
                  className=""
                  name={"t90"}
                  onChange={onChange}
                />
                <EditableText
                  text={content.t91}
                  span={true}
                  level={1}
                  className=""
                  name={"t91"}
                  onChange={onChange}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container mb-4">
        <div className="row justify-content-center">
          <div className="col-xl-7">
            <EditableText
              text={content.t92}
              isHeading={true}
              level={1}
              className="home_title text-center mb-3"
              name={"t92"}
              onChange={onChange}
            />

            <EditableText
              text={content.t93}
              isHeading={false}
              level={1}
              className="home_subtext text-center mb-3"
              name={"t93"}
              onChange={onChange}
            />

            <div className="row">
              <div className="col-md-12 text-center">
                <Link className="book1-btn">GET A DEMO</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4">
        <button className="virt_btn green" onClick={updateData}>
          Update Data
        </button>
      </div>
    </div>
  
  );
};

export default PhysicalTherapistsContent;
