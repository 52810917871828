import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import cros from "../../assets/images/cros.svg";
import useApiAxios from "../../api/useApiAxios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Select from "react-select";
import VideoComponent from "../media/VideoComponent";
import ImageComponent from "../media/ImageComponent";

const EditCourseVideos = ({
  show,
  handleClose,
  fun,
  currentCourseVideoData,
  editThumbnail,
  setEditThumbnail,
  editThumbnailUrl,
  setEditThumbnailUrl,
  editVideo,
  setEditVideo,
  editVideoUrl,
  setEditVideoUrl,
  selectEmailData,
}) => {
  const {
    updateCourseVideoApi,
    updateCourseVideoThumbnailApi,
    updateCourseVideoFileApi,
  } = useApiAxios();

  const formik = useFormik({
    initialValues: {
      title: currentCourseVideoData.title || "", // Set default value if title is undefined
      description: currentCourseVideoData.description || "", // Set default value if description is undefined
      thumbnail: currentCourseVideoData.thumbnail || "", // Set default value if thumbnail is undefined
      filename: "", // Assuming filename is not provided in currentCourseVideoData
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);

      const response = await updateCourseVideoApi(
        currentCourseVideoData._id,
        formData
      );
      if (editThumbnail) {
        await updateCourseVideoThumbnailApi(
          currentCourseVideoData._id,
          editThumbnail
        );
      }
      if (editVideo) {
        await updateCourseVideoFileApi(currentCourseVideoData._id, editVideo);
      }
      if (!response.error) {
        resetForm();
        await fun();
        handleClose();
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      title: currentCourseVideoData.title || "", 
      description: currentCourseVideoData.description || "", 
      thumbnail: currentCourseVideoData.thumbnail || "", 
      filename: "", 
    });
  }, [currentCourseVideoData.title]);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className="modal fade success-popup add-company"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Update Course Video
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <img src={cros} alt="" />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-body text-start">
              <div className="row">
                <div className="col-lg-12 text-start">
                  <p className="course-title text-start ">
                    Course Video Details
                  </p>
                  <p className="course-subtitle text-start mb-0">
                    We'll use your title and description to update a sample
                    course outline:
                  </p>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-2">Title</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.title && formik.touched.title
                          ? "is-invalid"
                          : ""
                      }`}
                      value={formik.values.title}
                      placeholder="Title Name"
                      onChange={formik.handleChange("title")}
                      name="title"
                    />
                    {formik.errors.title && formik.touched.title && (
                      <div className="invalid-feedback">
                        {formik.errors.title}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-3">Brief Description</label>
                   
                    {currentCourseVideoData?.description ? (
                      <SunEditor
                        width="100%"
                        setContents={formik.values.description}
                        onChange={(editorText) =>
                          formik.setFieldValue("description", editorText)
                        }
                        setOptions={{
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["undo", "redo"],
                            ["font", "fontSize"],
                            ["fontColor", "hiliteColor"],
                            ["align", "list", "lineHeight"],

                            ["table", "horizontalRule", "link"],
                          ],
                          defaultTag: "div",
                          minHeight: "300px",
                          showPathLabel: false,
                        
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    {formik.errors.description &&
                      formik.touched.description && (
                        <div className="invalid-feedback">
                          {formik.errors.description}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-3">thumbnail</label>
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      className="form-control"
                      id="thumbnailInput"
                      name="thumbnail"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        setEditThumbnail(file);

                        const reader = new FileReader();
                        reader.onload = () => {
                          setEditThumbnailUrl(reader.result);
                        };
                        reader.readAsDataURL(file);
                      }} 
                    />
                    <div>
                      {editThumbnail ? (
                        <img
                          className="minHeight200"
                          src={editThumbnailUrl}
                          alt=""
                        />
                      ) : (
                        <ImageComponent
                          className="minHeight200"
                          src={`${currentCourseVideoData.thumbnail}`}
                        />
                      )}
                    </div>
                    {formik.touched.icon && formik.errors.icon ? (
                      <div className="text-danger">{formik.errors.icon}</div>
                    ) : null}
                    <img src="" alt="" />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="product-input text-start">
                    <label className="form-label mt-3">File</label>

                    <input
                      type="file"
                      className={`form-control ${
                        formik.errors.filename && formik.touched.filename
                          ? "is-invalid"
                          : ""
                      }`}
                      accept="video/mp4,video/x-m4v,video/*"
                      placeholder="File Name"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        setEditVideo(file);

                        const reader = new FileReader();
                        reader.onload = () => {
                          setEditVideoUrl(reader.result);
                        };
                        reader.readAsDataURL(file);
                      }}
                    />
                    <div>
                      {editVideo ? (
                        <video
                          controlsList="nodownload"
                          width="200"
                          height="150"
                          controls
                        >
                          <source src={editVideoUrl} type="video/mp4" />
                        </video>
                      ) : (
                        <VideoComponent
                          src={`${currentCourseVideoData.filename}`}
                        />
                      )}
                    </div>
                    {formik.errors.filename && formik.touched.filename && (
                      <div className="invalid-feedback">
                        {formik.errors.filename}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <label className="form-label mt-2"> Email Sequence</label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      defaultChecked=""
                      checked={formik.values.emailSequence}
                      onChange={(e) =>
                        formik.setFieldValue("emailSequence", e.target.checked)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Turn on To Use as a email Sequence
                    </label>
                  </div>
                </div>
                {formik.values.emailSequence ? (
                  <div className="col-lg-12 mb-4">
                    <label className="form-label mt-2">
                      Select Internal Email Title
                    </label>
                    <Select
                      isSearchable={true}
                      isClearable={true}
                      isMulti
                      name="emailTemplate"
                      className="form-control"
                      
                      onChange={(e) => {
                        formik.setFieldValue("emailTemplate", e.value);
                      }}
                      options={selectEmailData}
                    />
                    {formik.touched.emailTemplate &&
                    formik.errors.emailTemplate ? (
                      <div className="error">{formik.errors.emailTemplate}</div>
                    ) : null}
                  </div>
                ) : (
                  <div className="col-lg-12 mb-4">
                    <label className="form-label mt-2">
                      Select Internal Email Title
                    </label>
                    <Select
                      isSearchable={true}
                      isClearable={true}
                     
                      name="emailTemplate"
                      className="form-control"
                      
                      onChange={(e) => {
                        formik.setFieldValue("emailTemplate", e.value);
                      }}
                      options={selectEmailData}
                    />
                    {formik.touched.emailTemplate &&
                    formik.errors.emailTemplate ? (
                      <div className="error">{formik.errors.emailTemplate}</div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer justify-content-start">
              <button
                className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
                type="submit"
              >
                Continue
              </button>
              <Link
                className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
                onClick={handleClose}
              >
                Go Back
              </Link>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default EditCourseVideos;
