import React, { useContext, useEffect } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

const Appointment = () => {
  const {  createAppointmentUrlApi } = useApiAxios();
  const { userData } = useContext(Context);

  useEffect(() => {
    formik.setValues({ appointmentUrl: userData?.appointmentUrl?.url });
  }, [userData?.appointmentUrl?.url]);

  const validationSchema = Yup.object().shape({
    appointmentUrl: Yup.string()
      .matches(
        /^https:\/\/calendly\.com\/.+$/,
        'URL must start with "https://calendly.com/"'
      )
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      appointmentUrl: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await createAppointmentUrlApi({
          url: values.appointmentUrl,
        });
      } catch (error) {}
    },
  });

  return (
    <div className="">
      <div className="container-fluid">
        <div className="row d-flex ">
          <div className="col-sm-5">
            <nav aria-label="breadcrumb" className="theme-breadcrumb">
              <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                <li className="breadcrumb-item">
                  <Link to="/company"> Appointment </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Appointment URL
                </li>
              </ol>
            </nav>
            <h1 className="page-title"> Appointment URL</h1>
          </div>
          <div className="col-sm-7 mb-3 mb-sm-0 d-flex justify-content-start justify-content-sm-end align-items-center"></div>
        </div>
        {/* Companies */}
        <div className="row align-items-stretch">
          {/* Companies */}
          <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
            <div className="card customer-sf h-100">
              <form onSubmit={formik.handleSubmit}>
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Appointment URL
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="appointmentUrl"
                            name="appointmentUrl"
                            value={formik.values.appointmentUrl}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.appointmentUrl &&
                          formik.errors.appointmentUrl ? (
                            <div className="text-danger">
                              {formik.errors.appointmentUrl}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-lg-12 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label" />
                        <div className="col-sm-8">
                          <button
                            className="btn addsubmit-btn green-btn me-3 mb-3"
                            type="submit"
                          >
                            {userData?.appointmentUrl
                              ? "Update"
                              : "Add Url to get appointments"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* /Companies */}
        </div>
        {/*/Row*/}
        {/* Companies */}
      </div>
    </div>
  );
};

export default Appointment;
