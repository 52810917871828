
import sideBar from "../data/sideBar.json";
import sideBarPt from "../data/sideBarPt.json";
import { Link, NavLink } from "react-router-dom";
import MetisMenu from "@metismenu/react";
import { useContext } from "react";
import { Context } from "../context/Context";

const Aside = () => {
  const { userData } = useContext(Context);
  return (
    <div className="vertical-menu">
      <div data-simplebar="" className="h-100 py-3">
        <div id="sidebar-menu">
          {userData.role === "admin" ? (
            <MetisMenu className="list-unstyled mb-5" id="side-menu">
              {sideBar.map((item, index) =>
                item.subTitle.length === 0 ? (
                  <li key={index}>
                    <Link to={item.slug}>
                      <i className={`${item.logo}`} />{" "}
                      <span key="t-chat">{item.title}</span>
                    </Link>
                  </li>
                ) : (
                  <li key={index}>
                    <Link to="#" className="has-arrow waves-effect">
                      <i className={`${item.logo}`} />
                      {"  "}
                      <span key="t-dashboards">{item.title}</span>
                    </Link>
                    <ul style={{ listStyleType: "none" }} className="sub-menu">
                      {item.subTitle.map((subItem, index) => (
                        <li key={index}>
                          <NavLink
                            to={item.slug + subItem.slug}
                            key="t-full-calendar"
                          >
                            {subItem.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              )}
            </MetisMenu>
          ) : (
            <MetisMenu className="list-unstyled mb-5" id="side-menu">
              {sideBarPt.map((item, index) =>
                item.subTitle.length === 0 ? (
                  <li key={index}>
                    <Link to={item.slug}>
                      <i className={`${item.logo}`} />{" "}
                      <span key="t-chat">{item.title}</span>
                    </Link>
                  </li>
                ) : (
                  <li key={index}>
                    <Link to="#" className="has-arrow waves-effect">
                      <i className={`${item.logo}`} />
                      {"  "}
                      <span key="t-dashboards">{item.title}</span>
                    </Link>
                    <ul style={{ listStyleType: "none" }} className="sub-menu">
                      {item.subTitle.map((subItem, index) => (
                        <li key={index}>
                          <NavLink
                            to={item.slug + subItem.slug}
                            key="t-full-calendar"
                          >
                            {subItem.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              )}
            </MetisMenu>
          )}
        </div>
      </div>
    </div>
  );
};

export default Aside;
