import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import PaginationComponent from "../../pagination/PaginationComponent";
import userDemo from "./../../assets/images/user-demo.svg";
import DeleteModel from "../deleteModel/DeleteModel";
import moment from "moment";
import { Context } from "../../context/Context";
import AddTagModel from "./../addTagModel/AddTagModel";
import ImageComponent from "../media/ImageComponent";
import DeleteSubscriptionModel from "../deleteModel/DeleteSubscriptionModal";

const Company = () => {
  const { userData } = useContext(Context);
  const limit = 15;
  const [searchField, setSearchField] = useState("");

  const {
    getAllCompany,
    handleCompanyDelete,
    getAllTagsApi,
    selectTagsCompanyApi,
    getCompanyExportDataApi,
    handleSubscriptionCancel,
    sendEmailToResubscribeApiForCompanyApi,
  } = useApiAxios();
  const [company, setCompany] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [selectedAll, setSelectedAll] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagModel, setTagModel] = useState(false);
  const [currentTag, setCurrentTag] = useState(null);
  const { setShowEmailData, setShowEmail } =
    useContext(Context);
  const [count, setCount] = useState(0);
  const openEmailModal = () => {
    setShowEmail(true);
    setShowEmailData(company);
  };

  const selectTagFunction = async (tagId) => {
    const companyIds = [];
    for (let index = 0; index < company.length; index++) {
      const element = company[index];
      if (element.selected) {
        companyIds.push(element._id);
      }
    }
    const response = await selectTagsCompanyApi(tagId, companyIds);
  };
  const searchCompanyHandler = async (e) => {
    setSearch(e.target.value);
  };
  function handleIncrementClick(_id, checked, all) {
    if (!all) {
      setSelectedAll(false);
      const nextCounters = company.map((item) => {
        if (item._id === _id) {
          // Increment the clicked counter
          return { ...item, selected: checked };
        } else {
          // The rest haven't changed
          return item;
        }
      });
      setCompany(nextCounters);
    } else {
      if (!selectedAll) {
        setSelectedAll(true);
        setCompany(
          company.map((item) => {
            return { ...item, selected: checked };
          })
        );
      } else {
        setSelectedAll(false);
        setCompany(
          company.map((item) => {
            return { ...item, selected: false };
          })
        );
      }
    }
  }

  const sendEmailToResubscribeApiForCompany = async (id) => {
    await sendEmailToResubscribeApiForCompanyApi(id);
  };
  const fun = async () => {
    const response = await getAllTagsApi();
    setTags(response.data.data);
    if (!searchField) {
      try {
        const data = await getAllCompany(
          page,
          limit,
          sort,
          search,
          currentTag?._id
        );
        setCompany(data.data.data);
        setTotal(data.data.total);
      } catch (error) {}
    } else {
      setCompany([]);
      setTotal(0);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const openDeleteModel = (data) => {
    setDeleteData(data);
    setDeleteModal(true);
  };
  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };

  const closeTagModel = () => {
    setTagModel(false);
  };

  const deleteCompanyHandler = async (data) => {
    await handleCompanyDelete(data);
    await fun();
    closeDeleteModel();
  };

  const [deleteSubscriptionModal, setDeleteSubscriptionModal] = useState(false);
  const [deleteSubscriptionData, setDeleteSubscriptionData] = useState(null);
  const openDeleteSubscriptionModel = (data) => {
    setDeleteSubscriptionData(data);
    setDeleteSubscriptionModal(true);
  };
  const closeDeleteSubscriptionModel = () => {
    setDeleteSubscriptionModal(false);
    setDeleteSubscriptionData(null);
  };

  const deleteSubscriptionHandler = async (data) => {
    await handleSubscriptionCancel(data);
    await fun();
    closeDeleteSubscriptionModel();
  };

  useEffect(() => {
    fun();
    document.title = "Company";
  }, [page, searchField, sort, search, currentTag?._id]);

  useEffect(() => {
    let c = 0;
    company.forEach((item) => (item.selected ? (c += 1) : c));
    setCount(c);
  }, [company]);

  const handleSort = async (selectedSort) => {
    setSort(selectedSort);
    setPage(1);
  };

  const handleCompanyExportData = async () => {
    try {
      const response = await getCompanyExportDataApi();
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "companies.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  return (
    <>
      <div className="row align-items-center">
        <div className="col-9">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">Companies</li>
              <li className="breadcrumb-item active" aria-current="page">
                Companies
              </li>
            </ol>
          </nav>
          <h1 className="page-title">
            Companies<span>({total})</span>
          </h1>
        </div>
        <div className="col-3 ps-0 text-end">
          <button
            onClick={handleCompanyExportData}
            className="btn btn-sm btn-success"
          >
            Export
          </button>
        </div>
      </div>
      {/* Companies */}
      <div className="row align-items-stretch">
        {/* Companies */}
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf">
            <div className="p-3 pb-0">
              <div className="input-group search-table">
                <input
                  type="text"
                  className="form-control form-control-filter"
                  placeholder="Search Company"
                  onChange={searchCompanyHandler}
                />

                <i className="icon-13 search-ic" />
                <div className="btn-group bulk-action ">
                  <button
                    type="button"
                    className="filter-btn px-3 px-md-4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    <i className="fa fa-filter me-2" /> Filters
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("newest")}
                      >
                        Newest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("oldest")}
                      >
                        Oldest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("updated")}
                      >
                        Recently Updated
                      </button>
                    </li>
                    <span>By Tag</span>
                    <li>
                      {tags.map((item) => (
                        <button
                          className={`dropdown-item ${
                            item?._id === currentTag?._id ? "active" : ""
                          }`}
                          type="button"
                          onClick={() => {
                            if (item?._id === currentTag?._id) {
                              setCurrentTag(null);
                            } else {
                              setCurrentTag(item);
                            }
                          }}
                        >
                          {item.name}
                        </button>
                      ))}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="companies-table">
              <div class="text-end">
                {company && company.some((item) => item.selected) ? (
                  <div className="mt-1">
                    <label className="col-form-label">Selected {count}</label>
                    <div className="btn-group bulk-action ms-2">
                      <button
                        type="button"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Bulk Actions
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-end drop-tow mt-3"
                        style={{}}
                      >
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                            onClick={openEmailModal}
                          >
                            Send Email
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                            onClick={() => setTagModel(true)}
                          >
                            Add tag
                          </button>
                        </li>
                        {currentTag?.name ? (
                          <li>
                            <button
                              className="dropdown-item "
                              type="button"
                              onClick={openEmailModal}
                            >
                              Remove tags
                            </button>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="pb-4 companies-table">
              <div className="mt-2 table-responsive">
                <table
                  id="example"
                  className="table display table-edits"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Name: activate to sort column ascending"
                        style={{ width: 186 }}
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <input
                              className="form-check-input mr-1"
                              type="checkbox"
                              defaultValue=""
                              id="remember1"
                              checked={selectedAll}
                              onClick={() =>
                                handleIncrementClick("", true, true)
                              }
                            />
                          </div>
                        </span>
                      </th>
                      <th>Name</th>
                      <th>Industry</th>
                      <th>Email</th>
                      <th className="text-nowrap">Phone</th>
                      <th className="text-nowrap">Country</th>
                      <th className="text-nowrap">Address</th>
                      <th className="text-nowrap">Employees</th>
                      <th className="text-nowrap">Zip</th>
                      {/* <th className="text-nowrap">PLAN</th> */}
                      {userData.role === "admin" ? (
                        <th className="text-nowrap">Physical Therapist Name</th>
                      ) : (
                        ""
                      )}

                      <th className="text-nowrap">Created At</th>
                      <th>Plan</th>
                      <th className="text-nowrap">Plan Expiry</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {company.map((companyData, index) => (
                      <tr key={index}>
                        <td className="checked">
                          <span className="form-check d-flex align-items-center ">
                            <input
                              className="form-check-input mr-1"
                              type="checkbox"
                              checked={companyData?.selected}
                              onClick={(e) => {
                                handleIncrementClick(
                                  companyData._id,
                                  e.target.checked
                                );
                              }}
                            />
                          </span>
                        </td>
                        <td>
                          <label
                            className="form-check-label text-truncate d-flex align-items-center"
                            htmlFor={`remember${index}`}
                          >
                            <span className="uusr_img me-2">
                              <ImageComponent
                                src={
                                  companyData?.profileImage
                                    ? `${companyData?.profileImage}`
                                    : userDemo
                                }
                              />
                            </span>
                            {companyData.name}
                          </label>
                        </td>
                        <td className="text-truncate">
                          {companyData.industry}
                        </td>

                        <td className="text-truncate">{companyData.email}</td>

                        <td className="text-nowrap">{companyData.phone}</td>
                        <td className="text-nowrap">{companyData.country}</td>
                        <td className="">
                          {companyData.address},{companyData.state},
                          {companyData.city}
                        </td>
                        <td className="text-nowrap">{companyData.employees}</td>

                        <td className="text-nowrap">{companyData.zip}</td>

                        {userData.role === "admin" ? (
                          <td className="text-nowrap">
                            {companyData?.created_by?.name}
                          </td>
                        ) : (
                          ""
                        )}

                        <td className="text-nowrap">
                          {moment(companyData.createdAt).format("MM/DD/YYYY")}
                        </td>
                        <td className="text-nowrap">
                          {companyData?.offer?.title}
                        </td>
                        <td>
                          {companyData?.plainExpire
                            ? moment(companyData.plainExpire).format(
                                "MM/DD/YYYY"
                              )
                            : "n/a"}
                        </td>

                        <td className="text-nowrap text-center">
                          <Link
                            to={`/company/${companyData._id}`}
                            className="action-btn"
                          >
                            <i className="fa-solid fa-pencil"></i>
                          </Link>
                          <Link
                            className="action-btn"
                            onClick={() => openDeleteModel(companyData._id)}
                          >
                            <i class="fa-solid fa-trash"></i>
                          </Link>
                          <Link
                            to={`/singlecompany/${companyData._id}`}
                            className="action-btn"
                          >
                            <i className="fa-solid fa-eye"></i>
                          </Link>
                          {userData.role === "physicalTherapist" && (
                            <>
                              {console.log(companyData?.subscription?.status)}
                              <button
                                type="button"
                                className="btn addsubmit-btn green-btn mb-3 btn-sm"
                                style={{ marginRight: "10px" }}
                                disabled={
                                  companyData?.subscription?.status === "active"
                                    ? false
                                    : true
                                }
                                onClick={() =>
                                  openDeleteSubscriptionModel(companyData._id)
                                }
                              >
                                Cancel Subscription
                              </button>
                            </>
                          )}
                          {userData.role === "physicalTherapist" &&
                            companyData?.subscription?.status ===
                              "inactive" && (
                              <>
                                <button
                                  type="button"
                                  className="btn addsubmit-btn green-btn mb-3 btn-sm"
                                  style={{ marginRight: "10px" }}
                                  onClick={() =>
                                    sendEmailToResubscribeApiForCompany(
                                      companyData._id
                                    )
                                  }
                                >
                                  Send email for re new subscription
                                </button>
                              </>
                            )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* /Companies */}
      </div>
      {/*/Row*/}
      {/* Companies */}

      <PaginationComponent
        total={total}
        page={page}
        limit={limit}
        setPage={setPage}
      />
      <AddTagModel
        show={tagModel}
        handleClose={closeTagModel}
        data={tags}
        selectTagFunction={selectTagFunction}
      />

      <DeleteModel
        show={deleteModal}
        handleClose={closeDeleteModel}
        data={deleteData}
        deleteFunction={deleteCompanyHandler}
      />
      <DeleteSubscriptionModel
        show={deleteSubscriptionModal}
        handleClose={closeDeleteSubscriptionModel}
        data={deleteSubscriptionData}
        deleteFunction={deleteSubscriptionHandler}
      />
    </>
  );
};

export default Company;
