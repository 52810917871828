import React, { useEffect } from "react";

const RazorpayCheckout = () => {
  useEffect(() => {
    // Automatically submit the form when the component mounts
    document.getElementById("checkoutForm").submit();
  }, []);

  return (
    <div>
      <h1>Checkout Form</h1>
      <form
        id="checkoutForm"
        action="https://api.razorpay.com/v1/checkout/embedded"
        method="POST"
      >
        <input type="hidden" name="key_id" value="rzp_test_BOTLCN8fwUI1hU" />
        <input type="hidden" name="order_id" value="order_O9qjl4VrH3Kkil" />
        <input type="hidden" name="amount" value="5000" />
        <input type="hidden" name="name" value="HDFC" />
        <input type="hidden" name="description" value="Harsh" />
        <input type="hidden" name="prefill[email]" value="had9821@gmail.com" />
        <input type="hidden" name="prefill[contact]" value="8840139807" />
        <input type="hidden" name="notes[transaction_id]" value="asdasda" />
        <input
          type="hidden"
          name="callback_url"
          value="http://localhost:3001/subscription/success"
        />
        <input type="submit" value="Pay Now" />
      </form>
    </div>
  );
};

export default RazorpayCheckout;
