import React, { useEffect, useRef, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import ImageComponent from "../media/ImageComponent";

const EditableImage = ({ src, alt, className, name }) => {
  const fileInputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(src);
  const { updateWebsiteContentImage } = useApiAxios();
  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const handleDoubleClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();

      formData.append("image", file);

      await updateWebsiteContentImage(formData, name);
    }
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <ImageComponent
        className={className}
        src={imageSrc}
        alt={alt}
        onDoubleClick={handleDoubleClick}
      />
      
    </div>
  );
};

export default EditableImage;
