import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";

import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/Context";

const Registered = () => {
  const { userData } = useContext(Context);
  const [offer, setOffer] = useState({});
  const { id } = useParams(); // Get the ID from the URL
  const {  getOfferById } = useApiAxios();
  const fun = async () => {
    const data = await getOfferById(id);
    if (!data.error) {
      setOffer(data.data.data);
    }
  };
  useEffect(() => {
    fun();
  }, []);

  return userData.role === "admin" ? (
    <h1>You Are All ready admin to test please logout or use incognito</h1>
  ) : (
    <>
      <h1>You Are All ready login as a registered PhysicalTherapist</h1>
      <Link to={"/"} className="btn virt_btn green">
        Go back
      </Link>
    </>
  );
};
export default Registered;
