import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import ViewInquiryModal from "./ViewInquiryModal";
import DeleteModel from "../deleteModel/DeleteModel";
import { Link } from "react-router-dom";

const Inquiry = () => {
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reply, setReply] = useState("");
  const [title, setTitle] = useState("");

  const [error, setError] = useState(null);
  const [currentData, setCurrentData] = useState({});
  const [openViewModal, setOpenViewModal] = useState(false);
  const { getContactData, deleteContactApi } = useApiAxios();
  const fun = async () => {
    const data = await getContactData(null, null);
    setContactData(data.data.data.contacts);
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const openDeleteModel = (data) => {
    setDeleteData(data);
    setDeleteModal(true);
  };
  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };
  const deleteContactHandler = async (data) => {
    await deleteContactApi(data);
    await fun();
    closeDeleteModel();
  };
  useEffect(() => {
    // Call the fetch function
    document.title = "Inquiry";
    fun();
    setLoading(false);
  }, []); // The empty dependency array ensures that this effect runs once after the initial render

  if (loading) {
    return <div>Loading...</div>;
  }
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setCurrentData({});
    setReply("");
    setTitle("");
  };
  if (error) {
    return <div>Error: {error}</div>;
  }
  function limitedCractor(text, count) {
    return text?.slice(0, count) + (text?.length > count ? "..." : "");
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row d-flex ">
          <div className="col-sm-5">
            <nav aria-label="breadcrumb" className="theme-breadcrumb">
              <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                <li className="breadcrumb-item">Inquiry</li>
                <li className="breadcrumb-item active" aria-current="page">
                  Inquiry
                </li>
              </ol>
            </nav>
            <h1 className="page-title">Inquiries</h1>
          </div>
         
        </div>
        {/* Companies */}
        <div className="row align-items-stretch">
          {/* Companies */}
          <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
            <div className="card customer-sf h-100">
              <div className="pb-4 companies-table">
                <div className="mt-2 table-responsive ">
                  <table
                    id="example"
                    className="table display table-edits"
                    cellSpacing={0}
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Inquiry At</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Organization</th>
                        <th>Inquiry About</th>
                        <th>Query</th>
                        <th>Query For</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contactData.map((contact, index) => (
                        <tr key={index}>
                          {/* <td className="text-truncate "></td> */}
                          <td className="checked">
                            <span className="form-check d-flex align-items-center ">
                              {index + 1}
                            </span>
                          </td>
                          <td className="text-truncate ">
                            {new Date(contact.createdAt).toLocaleString()}
                          </td>
                          <td className="text-truncate ">
                            {contact.firstName} {contact.lastName}
                          </td>
                          <td className="text-truncate ">
                            {/* Render EMAIL field */}
                            {contact.email}
                          </td>
                          <td className="text-truncate ">
                            {/* Render PHONE field */}
                            {contact.phone}
                          </td>
                          <td className="  text-nowrap">
                            {/* Render QUERY field */}
                            {contact.organization}
                          </td>

                          <td className="  text-nowrap maxLengthDisplay">
                            {/* Render QUERY field */}
                            {limitedCractor(contact.inquiryAbout, 20)}
                          </td>
                          <td className="  text-nowrap maxLengthDisplay">
                            {/* Render QUERY field */}
                            {limitedCractor(contact.query, 20)}
                          </td>
                          <td className="  text-nowrap maxLengthDisplay">
                            {/* Render QUERY field */}
                            {contact.iAmAsA}
                          </td>
                          <td className="text-nowrap text-center">
                            {/* Render QUERY field */}                            
                              <Link
                              className="action-btn"
                                  onClick={() => {
                                  setOpenViewModal(true);
                                  setCurrentData(contact);
                                }}
                              >
                                <i class="fa fa-eye" title="View"></i>
                              </Link>
                              <Link
                              className="action-btn"
                                onClick={() => openDeleteModel(contact._id)}
                              >
                                 <i class="fa fa-trash" title="Delete"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* /Companies */}
        </div>
        {/*/Row*/}
        {/* Companies */}
      </div>
      <ViewInquiryModal
        show={openViewModal}
        handleClose={handleCloseViewModal}
        data={currentData}
        reply={reply}
        setReply={setReply}
        title={title}
        setTitle={setTitle}
      />
      <DeleteModel
        show={deleteModal}
        handleClose={closeDeleteModel}
        data={deleteData}
        deleteFunction={deleteContactHandler}
      />
    </div>
  );
};

export default Inquiry;
