import { Outlet } from "react-router-dom";
import Aside from "../Aside";
import Header from "../Header";
import { useState } from "react";

const Backend = () => {
  const [sideBarEnable, setSideBarEnable] = useState(true);
  return (
    <>
      <div
        data-sidebar="light"
        data-layout-mode="light"
        className={sideBarEnable ? "sidebar-enable" : "vertical-collpsed"}
      >
        <div id="layout-wrapper">
          <Header
            sideBarEnable={sideBarEnable}
            setSideBarEnable={setSideBarEnable}
          />
          <Aside />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Backend;
