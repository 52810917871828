import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import AddPhysicalTherapistPatient from "./AddPhysicalTherapistPatient";
import EditPhysicalTherapistPatient from "./EditPhysicalTherapistPatient";
import moment from "moment";
import { Context } from "../../context/Context";
import EmployeeListModal from "../user/EmployeeListModal";
import PaginationComponent from "../../pagination/PaginationComponent";
import approved from "./../../assets/images/approved.svg";
import phone from "./../../assets/images/phone.svg";
import message from "./../../assets/images/message.svg";
import smile from "./../../assets/images/smile.svg";
import userDemo from "./../../assets/images/user-demo.svg";
import DeleteModel from "../deleteModel/DeleteModel";
import ImageComponent from "../media/ImageComponent";
import AddTagModel from "./../addTagModel/AddTagModel";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PhysicalTherapistPatient = () => {
  const limit = 1000;
  const [searchField, setSearchField] = useState("");
  const { id } = useParams();
  const {
    getAllPhysicaltherapistPatient,
    deletePhysicaltherapistPatientApi,
    createMultiplePatientApi,
    getPTGraphApi,
    getPtPainExportDataApi,
    getPtSatisfactionGraphApi,
    getPtSatisfactionExportDataApi,
    getPtActivityGraphApi,
    getPtActivityExportDataApi,
    selectTagsPatientApi,
    getAllTagsApi,
    getPatientExportDataApi,
    getPhysicalTherapistScaleDataApi,
    getPTPainCountExportDataApi,getPTActivityCountExportDataApi
  } = useApiAxios();
  const [physicalTherapistsPatient, setPhysicalTherapistsPatient] = useState(
    []
  );

  const [physicalTherapists, setPhysicalTherapists] = useState({});
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [employeeDataArray, setEmployeeDataArray] = useState([]);
  const [openEmployeeListModal, setOpenEmployeeListModal] = useState(false);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [satisfactionChartOption, setSatisfactionChartOption] = useState(null);
 
  const [ChartOptionForActivityAndPain, setChartOptionForActivityAndPain] =
    useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [selectedAll, setSelectedAll] = useState(false);
  const [activeUserCount, setActiveUserCount] = useState(0);

  const {  setShowEmailData, setShowEmail } =
    useContext(Context);
  const searchPatientHandler = async (e) => {
    setSearch(e.target.value);
  };

  const openEmailModal = () => {
    setShowEmail(true);
    setShowEmailData(physicalTherapistsPatient);
  };
  const [currentPatient, setCurrentPatient] = useState({});
  const [showPatientEditModal, setShowPatientEditModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagModel, setTagModel] = useState(false);
  const [currentTag, setCurrentTag] = useState(null);
  const [count, setCount] = useState(0);
  const [satisfactionData, setSatisfactionData] = useState(null);
  const [scaleChartOption, setScaleChartOption] = useState(null);
  const selectTagFunction = async (tagId) => {
    const patientId = [];
    for (let index = 0; index < physicalTherapistsPatient.length; index++) {
      const element = physicalTherapistsPatient[index];
      if (element.selected) {
        patientId.push(element._id);
      }
    }
    const response = await selectTagsPatientApi(tagId, patientId);
  };

  const closeTagModel = () => {
    setTagModel(false);
  };

  function handleIncrementClick(_id, checked, all) {
    if (!all) {
      setSelectedAll(false);
      const nextCounters = physicalTherapistsPatient.map((item) => {
        if (item._id === _id) {
          // Increment the clicked counter
          return { ...item, selected: checked };
        } else {
          // The rest haven't changed
          return item;
        }
      });
      setPhysicalTherapistsPatient(nextCounters);
    } else {
      if (!selectedAll) {
        setSelectedAll(true);
        setPhysicalTherapistsPatient(
          physicalTherapistsPatient.map((item) => {
            return { ...item, selected: checked };
          })
        );
      } else {
        setSelectedAll(false);
        setPhysicalTherapistsPatient(
          physicalTherapistsPatient.map((item) => {
            return { ...item, selected: false };
          })
        );
      }
    }
  }

  const fetchSatisfactionData = async () => {
    try {
      const response = await getPtSatisfactionGraphApi(id);
      const data = response.data.data;
      console.log(data);

      // Get the last data point directly
      const lastDataPoint = data[data.length - 1];
      if (lastDataPoint) {
        setSatisfactionData({
          percentage: Math.trunc(lastDataPoint.y),
        });
      }
    } catch (error) {
      console.error("Error fetching satisfaction data:", error);
    }
  };

  const fetchPhysicalTherapistScaleData = async () => {
    console.log("first");
    const response = await getPhysicalTherapistScaleDataApi(id);

    if (!response.error) {
      console.log(response);

      setScaleChartOption({
        chart: {
          type: "column",
          height: "300px",
        },
        title: {
          text: "Pain Level and Average Function Level Comparison",
        },
        xAxis: {
          categories: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
          crosshair: true,
        },
        yAxis: {
          title: {
            text: "Count",
          },
        },
        series: [
          {
            name: "Pain Level",
            data: response.data.painData.map((item) => item.count), // Sample data for "painLabel"
            color: "#036E49",
          },
          {
            name: "Function Level",
            data: response.data.activitiesData.map((item) => item.count), 
            color: "#FD707B",
          },
        ],
      });
    }
  };

  useEffect(() => {
    let c = 0;
    physicalTherapistsPatient.forEach((item) => (item.selected ? (c += 1) : c));
    setCount(c);
  }, [physicalTherapistsPatient]);

  const fun2 = async () => {
    try {
      const [res, res1] = await Promise.all([
        getPTGraphApi(id),
        getPtSatisfactionGraphApi(id),
      ]);
      const res2 = await getPtActivityGraphApi(id);

      const satisfaction = [];
      for (let index = 0; index < res1.data.data.length; index++) {
        const element = res1.data.data[index];
        if (element?.x) {
          satisfaction.push({
            x: new Date(element.x),
            y: Number(element.y),
          });
        }
      }
      setSatisfactionChartOption({
        chart: {
          type: "line",
          height: "300px",
        },
        title: {
          text: "Satisfaction Scale",
        },
        xAxis: {
          type: "category",
          labels: {
            formatter: function () {
              const days = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ];
              console.log(this.value);
              const date = new Date(satisfaction[this.value].x);
              const weekday = days[date.getDay()];
              return weekday;
            },
          },
        },
        yAxis: {
          title: {
            text: "Percentage",
          },
          labels: {
            formatter: function () {
              return this.value + "%";
            },
          },
        },
        series: [
          {
            name: "Value ",
            data: satisfaction.map((item) => [item.x, Math.trunc(item.y)]),
            color: "#FD707B",
          },
        ],
      });

      const painData = [];
      const activityData = [];

      // Process Pain Data
      for (let index = 0; index < res.data.data.length; index++) {
        const element = res.data.data[index];
        if (element?.x) {
          painData.push({
            x: new Date(element.x),
            y: Number(element.y),
          });
        }
      }

      // Process Activity Data
      for (let index = 0; index < res2.data.data.length; index++) {
        const element = res2.data.data[index];
        if (element?.x) {
          activityData.push({
            x: new Date(element.x),
            y: Number(element.y),
          });
        }
      }

      // Set chart options for combined data
      setChartOptionForActivityAndPain({
        chart: {
          type: "line",
          height: "300px",
        },
        title: {
          text: "Pain and Function Scale",
        },
        xAxis: [
          {
            type: "category",
            categories: painData.map((item) =>
              moment(item.x).format("MM/DD/YYYY")
            ),
            title: {
              text: "Date",
            },
          },
          {
            type: "category",
            categories: activityData.map((item) =>
              moment(item.x).format("MM/DD/YYYY")
            ),

            opposite: true,
          },
        ],
        yAxis: {
          title: {
            text: "Value",
          },
        },
        series: [
          {
            name: "Average Pain level",
            data: painData.map((item) => item.y),
            color: "#036E49",
          },
          {
            name: "Average Functional level",
            data: activityData.map((item) => item.y),
            color: "#FD707B",
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching graph data:", error);
    }
  };

  const fun = async () => {
    const response = await getAllTagsApi();
    setTags(response.data.data);
    try {
      const data = await getAllPhysicaltherapistPatient(
        id,
        page,
        limit,
        sort,
        search,
        currentTag?._id
      );
      setPhysicalTherapistsPatient(data.data.data.patients);
      setPhysicalTherapists(data.data.data);

      setTotal(data.data.data.patients.length);
    } catch (error) {
      console.log(error);
      setPhysicalTherapistsPatient([]);
      setTotal(0);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const openDeleteModel = (data) => {
    setDeleteData(data);
    setDeleteModal(true);
  };
  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };
  const deletePhysicalTherapistPatientHandler = async (data) => {
    await deletePhysicaltherapistPatientApi(data);
    await fun();
    closeDeleteModel();
  };

  useEffect(() => {
    fun();
    fun2();
    fetchSatisfactionData();
    fetchPhysicalTherapistScaleData();
    document.title = "Patient";
    const fetchData = async () => {
      try {
        const data = await getAllPhysicaltherapistPatient(
          id,
          page,
          limit,
          sort,
          search,
          currentTag?._id
        );
        setPhysicalTherapistsPatient(data.data.data.physicalTherapist.patients);
        setTotal(data.data.data.physicalTherapist.length);
          setActiveUserCount(data.data.data.activeUserCount)
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, page, searchField, sort, search, currentTag?._id]);


  const handleEditPatient = async (patient) => {
    setCurrentPatient(patient);
    setShowPatientEditModal(true);
  };

  const handleSort = async (selectedSort) => {
    setSort(selectedSort);
    setPage(1);
  };

  useEffect(() => {
    let c = 0;
    physicalTherapistsPatient.forEach((item) => (item.selected ? (c += 1) : c));
    setCount(c);
  }, [physicalTherapistsPatient]);
  const handlePtPainExportData = async () => {
    try {
      const response = await getPtPainExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ptPainLevels.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };


  const handlePtPainCountExportData = async () => {
    try {
      const response = await getPTPainCountExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PtPainCountData.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };


  const handlePtActivityExportData = async () => {
    try {
      const response = await getPtActivityExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PtActivityLevels.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };



  const handlePtActivityCountExportData = async () => {
    try {
      const response = await getPTActivityCountExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PtActivityCountData.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };


  function calculateCompletionPercentage(modules) {
    if (!modules) {
      return 0;
    }
    const totalVideos = [];
    for (let index = 0; index < modules.length; index++) {
      const element = modules[index];
      // totalVideos.concat(element?.userVideos);
      element?.userVideos.forEach((item) => totalVideos.push(item));
    }

    const completedVideos = totalVideos.filter((item) => item.completed).length;
    const percentage = (completedVideos / totalVideos.length) * 100;
    console.log(percentage);
    return percentage;
  }
  const handleDropdownChange = (e) => {
    setIsDropdownVisible(false); // Hide dropdown after selection
    if (e === "Pain Level") {
      handlePtPainExportData();
    } else if (e === "Functional Level") {
      handlePtActivityExportData();
    }
  };

  const handleGraphDropdownChange = (e) => {
    setIsDropdownVisible(false); // Hide dropdown after selection
    if (e === "Pain Level") {
      handlePtPainCountExportData();
    } else if (e === "Functional Level") {
      handlePtActivityCountExportData();
    }
  };


  const handlePatientExportData = async () => {
    try {
      const response = await getPatientExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Patient.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handlePtSatisfactionExportData = async () => {
    try {
      const response = await getPtSatisfactionExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PtSatisfactionLevels.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-4 mb-4 align-items-stretch">
          <div className="card customer-sf h-100">
            <div className="card-body p-3">
              <div className="row">
                <div className="col-12 mb-5 pb-5">
                  <div className="coplany-info">
                    <div className="float-start c-logo-manage">
                      <div className="company-logo d-flex justify-content-center align-items-center float-start">
                        <ImageComponent
                          src={
                            physicalTherapists?.profileImage
                              ? `${physicalTherapists?.profileImage}`
                              : userDemo
                          }
                        />
                      </div>
                    </div>
                    <div className="company-namearea float-start">
                      <h4 className="c-title">{physicalTherapists.name}</h4>
                      <h6 className="c-subtitle"> </h6>
                      <div className="approve">
                        <img src={approved} alt="" className="me-2" /> Approved
                      </div>
                      <div className="action-icon">
                        <a href="#!" className="ps-1 pe-2 opc8">
                          <img src={phone} alt="" />
                        </a>
                        <a href="#!" className="px-2 opc8">
                          <img src={message} alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="company-satisfaction-container">
                            {isLoading ? (
                              <p>Loading...</p> // Display a loading indicator
                            ) : satisfactionData ? (
                              <div className="company-satisfaction float-end">
                                <p className="title-cs">
                                  Customer <br /> Satisfaction
                                </p>
                                <p className="title-pr d-inline-flex align-items-center mb-1">
                                  <img src={smile} alt="Smile" className="me-1" />
                                  {satisfactionData.percentage
                                    ? satisfactionData.percentage + "%"
                                    : "N/A"}
                                </p>
                              </div>
                            ) : (
                              <div className="company-satisfaction float-end">
                              <p className="title-cs">
                                Customer <br /> Satisfaction
                              </p>
                              <p className="title-pr d-inline-flex align-items-center mb-1">
                              <img src={smile} alt="Smile" className="me-1" />
                              N/A
                              </p>
                            </div>
                            )}
                          </div>
                      </div>
                </div>
              </div>
              <div className="row compamy-numbers">
                <div className="col-sm-4 mb-2 mb-sm-0">
                  <h6 className="fw-bold">{total}</h6>
                  <p>Total patient</p>
                </div>
                <div className="col-sm-4 mb-2 mb-sm-0 px-0 bdr-color border-end border-start">
                  <h6 className="fw-bold">
                    {moment(physicalTherapists?.createdAt).format("MM/DD/YYYY")}
                  </h6>
                  <p>Start Date</p>
                </div>
                <div className="col-sm-4 mb-2 mb-sm-0">
                  <h6 className="fw-bold">
                    {moment(physicalTherapists?.plainExpire).diff(
                      moment(),
                      "days"
                    )}
                  </h6>
                  <p>Days Left</p>
                </div>
              </div>
              <hr style={{ borderBottom: "1px solid #E9EDF7" }} />
              <div className="row compamy-numbers mb-3">
                <div className="col-sm-4 mb-2 mb-sm-0">
                  <h6 className="fw-bold">
                  {activeUserCount}
                  </h6>
                  <p> Active</p>
                </div>
                <div className="col-sm-4 mb-2 mb-sm-0 px-0 bdr-color border-end border-start">
                  <h6 className="fw-bold"> {total-activeUserCount}</h6>
                  <p>Inactive</p>
                </div>
                <div className="col-sm-4 mb-2 mb-sm-0">
                  <h6 className="fw-bold">0</h6>
                  <p>To be Activated</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Col */}
        <div className="col-xl-4 mb-4 align-items-stretch">
          <div className="card h-100">
            <div className="card-body py-2 px-0">
              <div id="satisfaction_data">
                {/* Export Button */}
                <div className="bulk-action d-flex justify-content-end me-2">
                  <button
                    className="btn btn-sm btn-success"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    type="button"
                  >
                    Export
                  </button>

                  <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3 w-25">
                    <li>
                      <button
                        className="dropdown-item "
                        type="button"
                        onClick={() => handleGraphDropdownChange("Pain Level")}
                      >
                        Pain Level
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleGraphDropdownChange("Functional Level")}
                      >
                        Functional Level
                      </button>
                    </li>
                  </ul>
                </div>
                {/* Selection Dropdown */}

                {scaleChartOption ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={scaleChartOption}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* /Col */}
        <div className="col-xl-4 mb-4 align-items-stretch">
          <div className="card h-100">
            <div className="card-body py-2 px-0">
              <div id="pain_activity_functional">
                {/* Export Button */}
                <div className="bulk-action d-flex justify-content-end me-2">
                  <button
                    className="btn btn-sm btn-success"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    type="button"
                  >
                    Export
                  </button>

                  <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3 w-25">
                    <li>
                      <button
                        className="dropdown-item "
                        type="button"
                        onClick={() => handleDropdownChange("Pain Level")}
                      >
                        Avg Pain Level
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleDropdownChange("Functional Level")}
                      >
                        Avg Functional Level
                      </button>
                    </li>
                  </ul>
                </div>
                {ChartOptionForActivityAndPain ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={ChartOptionForActivityAndPain}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* /Col */}
           {/* /Col */}
           <div className="col-xl-4 mb-4 align-items-stretch">
              <div className="card h-100">
                <div className="card-body py-2 px-0">
                  <div id="satisfaction_data">
                    {/* Export Button */}
                    <div className="bulk-action d-flex justify-content-end me-2">
                      <button
                        className="btn btn-sm btn-success"
                        onClick={handlePtSatisfactionExportData}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                        type="button"
                      >
                        Export
                      </button>

                    
                    </div>

                    {/* Selection Dropdown */}

                    {satisfactionChartOption ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={satisfactionChartOption}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* /Col */}
      </div>
      {/* /Row */}

      <div className="row align-items-center">
        <div className="col-9">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link to="/physical-therapists">Physical Therapists</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Patient
              </li>
            </ol>
          </nav>
          <h1 className="page-title">
            Patients<span>({total})</span>
          </h1>
        </div>
        <div className="col-3 ps-0 text-end">
          <button
            onClick={handlePatientExportData}
            className="btn btn-sm btn-success"
          >
            Export
          </button>
        </div>
        <div className="col-sm-7 mb-3 mb-sm-0 d-flex justify-content-start justify-content-sm-end align-items-center"></div>
      </div>

      {/* Companies */}
      <div className="row align-items-stretch">
        {/* Companies */}
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf">
            <div className="p-3 pb-0">
              <div className="input-group search-table">
                <input
                  type="text"
                  className="form-control form-control-filter"
                  placeholder="Search Patient"
                  onChange={searchPatientHandler}
                />

                <i className="icon-13 search-ic" />
                <div className="btn-group bulk-action ">
                  <button
                    type="button"
                    className="filter-btn px-3 px-md-4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    <i className="fa fa-filter me-2" /> Filters
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("newest")}
                      >
                        Newest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("oldest")}
                      >
                        Oldest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("updated")}
                      >
                        Recently Updated
                      </button>
                    </li>
                    <span>By Tag</span>
                    <li>
                      {tags.map((item) => (
                        <button
                          className={`dropdown-item ${
                            item?._id === currentTag?._id ? "active" : ""
                          }`}
                          type="button"
                          onClick={() => {
                            if (item?._id === currentTag?._id) {
                              setCurrentTag(null);
                            } else {
                              setCurrentTag(item);
                            }
                          }}
                        >
                          {item.name}
                        </button>
                      ))}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="companies-table">
              <div class="text-end">
                {physicalTherapistsPatient &&
                physicalTherapistsPatient.some((item) => item.selected) ? (
                  <div className="mt-1">
                    <label className="col-form-label">Selected {count}</label>
                    <div className="btn-group bulk-action ms-2">
                      <button
                        type="button"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Bulk Actions
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-end drop-tow mt-3"
                        style={{}}
                      >
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                            onClick={openEmailModal}
                          >
                            Send Email
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                            onClick={() => setTagModel(true)}
                          >
                            Add tag
                          </button>
                        </li>
                        {currentTag?.name ? (
                          <li>
                            <button
                              className="dropdown-item "
                              type="button"
                              onClick={openEmailModal}
                            >
                              Remove tags
                            </button>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="pb-4 companies-table">
              <div className="mt-2 table-responsive">
                <table
                  id="example"
                  className="table display table-edits"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Name: activate to sort column ascending"
                        style={{ width: 186 }}
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <input
                              className="form-check-input mr-1"
                              type="checkbox"
                              defaultValue=""
                              id="remember1"
                              checked={selectedAll}
                              onClick={() =>
                                handleIncrementClick("", true, true)
                              }
                            />
                          </div>
                        </span>
                      </th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th className="text-nowrap">Date Of Birth</th>
                      <th className="text-nowrap">Start Date</th>
                      <th>Gender</th>
                      <th>Plan</th>
                      <th className="text-nowrap">Plan Expiry</th>
                      <th className="text-nowrap">Last Seen</th>
                      <th>Progress</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {/* Mapping employeeData */}
                    {physicalTherapistsPatient &&
                      physicalTherapistsPatient.map((patient, index) => (
                        <tr key={index}>
                          {/* Display employee details */}
                          <td className="checked">
                            <span className="form-check d-flex align-items-center ">
                              <input
                                className="form-check-input mr-1"
                                type="checkbox"
                                checked={patient?.selected}
                                onClick={(e) => {
                               
                                  handleIncrementClick(
                                    patient._id,
                                    e.target.checked
                                  );
                                }}
                              />
                            </span>
                          </td>
                          <td>
                            <label
                              className="form-check-label text-truncate d-flex align-items-center"
                              htmlFor={`remember${index}`}
                            >
                              <span className="uusr_img me-2">
                                <ImageComponent
                                  className="h-100"
                                  src={
                                    patient?.profileImage
                                      ? `${patient?.profileImage}`
                                      : userDemo
                                  }
                                />
                              </span>
                              {patient.name}
                            </label>
                          </td>
                          <td>{patient.email}</td>
                          <td>{patient.phone}</td>
                          <td>
                            {moment(patient.dateOfBirth).format("MM/DD/YYYY")}
                          </td>
                          <td className="text-nowrap">
                            {moment(patient.createdAt).format("MM/DD/YYYY")}
                          </td>
                          <td>{patient.gender}</td>
                          <td>{patient?.offer?.title}</td>
                          <td>
                            {patient?.plainExpire
                              ? moment(patient.plainExpire).format("MM/DD/YYYY")
                              : "n/a"}
                          </td>
                         
                          <td>
                                {patient?.lastSeen && patient?.address
                                  ? moment(patient?.lastSeen).format("MM/DD/YYYY")
                                  : "Null"}
                              </td>

                          <td className="align-middle">
                            <div className="progress progress-bg1">
                              <div
                                className="progress-bar progress-red"
                                role="progressbar"
                                style={{
                                  width: calculateCompletionPercentage(
                                    patient.currentUserAnswer?.userVideoModule
                                  ),
                                }}
                                aria-valuenow={0}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </td>
                          {/* Action buttons */}
                          <td className="text-nowrap text-center">
                            <Link
                              to={`tel:${patient?.phone?.substring(
                                0,
                                3
                              )}-${patient?.phone?.substring(3)}`}
                              className="action-btn"
                            >
                              <i className="fa fa-phone" />
                            </Link>
                            <Link
                              onClick={() => {
                                setShowEmail(true);
                                setShowEmailData([
                                  {
                                    email: patient.email,
                                    name: patient.name,
                                    selected: true,
                                  },
                                ]);
                              }}
                              className="action-btn"
                            >
                              <i className="fa fa-envelope" />
                            </Link>
                            <Link
                              to={`/employee-details/${patient._id}`}
                              className="action-btn"
                            >
                              <i className="fa fa-eye" />
                            </Link>

                            <Link
                              to=""
                              className="action-btn"
                              onClick={() => {
                                handleEditPatient(patient);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </Link>
                            <Link
                              className="action-btn"
                              onClick={() => openDeleteModel(patient._id)}
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* /Companies */}
      </div>
      {/*/Row*/}
      {/* Companies */}
      <EmployeeListModal
        show={openEmployeeListModal}
        handleClose={() => {
          setOpenEmployeeListModal(false);
        }}
        onSubmit={async (data) => {
          await createMultiplePatientApi(id, data);
          setOpenEmployeeListModal(false);
          await fun();
        }}
        data={employeeDataArray}
        title={"Patient List"}
        buttonTitle="Create Patient"
      />
      <EditPhysicalTherapistPatient
        show={showPatientEditModal}
        handleClose={() => {
          setShowPatientEditModal(false);
          setCurrentPatient({});
        }}
        currentPatient={currentPatient}
        fun={fun}
      />

      <AddPhysicalTherapistPatient
        fun={fun}
        show={show}
        handleClose={() => setShow(false)}
      />
      <PaginationComponent
        total={total}
        page={page}
        limit={limit}
        setPage={setPage}
      />
      <AddTagModel
        show={tagModel}
        handleClose={closeTagModel}
        data={tags}
        selectTagFunction={selectTagFunction}
      />
      <DeleteModel
        show={deleteModal}
        handleClose={closeDeleteModel}
        data={deleteData}
        deleteFunction={deletePhysicalTherapistPatientHandler}
      />
    </>
  );
};

export default PhysicalTherapistPatient;
