import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";

const Email = () => {
  const [emails, setEmails] = useState([]);
  const { getEmail } = useApiAxios();
  const { openInfoSnackbar } = useContext(Context);

  const fetchEmails = async () => {
    try {
      const data = await getEmail();
      setEmails(data.data);
    } catch (error) {
      console.error("Error fetching emails:", error);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  return (
    <div className="container">
      <div className="row mt-4 mb-4">
        <div className="col-md-8 offset-md-2">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link to="/company">Subscription</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Emails
              </li>
            </ol>
          </nav>
          <h1 className="page-title">Subscription Emails</h1>
          <div className="card">
            <div className="card-body">
              <textarea
                id="emailTextarea"
                rows={10}
                className="form-control mb-3"
                value={emails}
                readOnly
              />
              <CopyToClipboard
                onCopy={() => openInfoSnackbar("Emails copied to clipboard")}
                text={emails}
              >
                <button className="btn btn-primary">Copy</button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;
