import React, { useState } from "react";
import cros from "../../assets/images/cros.svg";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const AddEmailTemplateModel = ({ show, handleClose, fun }) => {
  const { createEmailTemplateApi } = useApiAxios();
  const formik = useFormik({
    initialValues: {
      title: "",
      body: "",
      type: "",
      icon: null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      internalTitle: Yup.string().required("Internal Title is required"),
      body: Yup.string().required("Email body is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const response = await createEmailTemplateApi(values);
      if (!response.error) {
        resetForm();
        await fun();
        handleClose();
      }
    },
  });

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className="modal fade success-popup add-company"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Add Email Template
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <img src={cros} alt="" />
          </button>
        </div>
        <div className="modal-body text-start">
          <form onSubmit={formik.handleSubmit}>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">Title/Subject</label>
              <input
                type="text"
                className={`form-control ${
                  formik.errors.title && formik.touched.title
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Title Name"
                value={formik.values.title}
                onChange={formik.handleChange("title")}
              />
              {formik.errors.title && formik.touched.title && (
                <div className="invalid-feedback">{formik.errors.title}</div>
              )}
            </div>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-2">Internal Title</label>
              <input
                type="text"
                className={`form-control ${
                  formik.errors.internalTitle && formik.touched.internalTitle
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Internal Title"
                value={formik.values.internalTitle}
                onChange={formik.handleChange("internalTitle")}
              />
              {formik.errors.internalTitle && formik.touched.internalTitle && (
                <div className="invalid-feedback">
                  {formik.errors.internalTitle}
                </div>
              )}
            </div>
            <div className="col-lg-12 mb-4">
              <label className="form-label mt-3">Email Body</label>

              <SunEditor
                width="100%"
                setContents={formik.values.body}
                onChange={(editorText) =>
                  formik.setFieldValue("body", editorText)
                }
                setOptions={{
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    ["fontColor", "hiliteColor"],
                    ["align", "list", "lineHeight"],

                    ["table", "horizontalRule", "link"],
                  ],
                  defaultTag: "div",
                  minHeight: "300px",
                  showPathLabel: false,
                  
                }}
              />
              {formik.errors.body && formik.touched.body && (
                <div className="invalid-feedback">{formik.errors.body}</div>
              )}
            </div>

            <div className="modal-footer justify-content-start">
              <button
                href=""
                className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
                type="submit"
              >
                Continue
              </button>
              <button
                href=""
                onClick={handleClose}
                className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddEmailTemplateModel;
