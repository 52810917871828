import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import AddTagModel from "./AddTagModel";
import EditTagModel from "./EditTagModel";
import DeleteModel from "../deleteModel/DeleteModel";
import { Context } from "../../context/Context";

const Tags = () => {
  const { getAllTagsApi, deleteTagsApi } = useApiAxios();
  const [currentTag, setCurrentTag] = useState({});
  const [tags, setTags] = useState([]);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState("");
  const { userData } = useContext(Context);

  const searchTagsHandler = async (e) => {
    setSearch(e.target.value);
  };

  const fun = async () => {
    if (true) {
      try {
        const data = await getAllTagsApi(page, sort, search);

        setTags(data.data.data);
      } catch (error) {}
    } else {
      setTags([]);
    }
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const openDeleteModel = (data) => {
    setDeleteData(data);
    setDeleteModal(true);
  };
  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };
  const deleteTagHandler = async (data) => {
    await deleteTagsApi(data);
    await fun();
    closeDeleteModel();
  };
  useEffect(() => {
    fun();
    document.title = "Tags";
  }, [page, sort, search]);

  const handleSort = async (selectedSort) => {
    setSort(selectedSort);
    setPage(1);
  };
  return (
    <>
      <div className="row d-flex ">
        <div className="col-sm-5">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link to="/tags">Tags </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Tags
              </li>
            </ol>
          </nav>
          <h1 className="page-title">Tags</h1>
        </div>
        <div className="col-sm-7 mb-3 mb-sm-0 d-flex justify-content-start justify-content-sm-end align-items-center">
          <button className="btn company-btn" onClick={() => setShow(true)}>
            <i class="fa-solid fa-tag" /> Add Tag
          </button>
        </div>
      </div>
      {/* Companies */}
      <div className="row align-items-stretch">
        {/* Companies */}
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf h-100">
            <div className="p-3 pb-0">
              <div className="input-group search-table">
                <input
                  type="text"
                  className="form-control form-control-filter"
                  placeholder="Search Tags"
                  onChange={searchTagsHandler}
                />
                <i className="icon-13 search-ic" />
                <div className="btn-group bulk-action">
                  <button
                    type="button"
                    className="filter-btn px-3 px-md-4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    <i className="fa fa-filter me-2" /> Filters
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("newest")}
                      >
                        Newest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("oldest")}
                      >
                        Oldest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("updated")}
                      >
                        Recently Updated
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="pb-4 companies-table">
              <div className="mt-2 table-responsive">
                <table
                  id="example"
                  className="table display table-edits"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th className="text-center">S. No.</th>
                      <th>Name</th>
                      {userData.role === "admin" ? (
                        <th>Physical Therapists</th>
                      ) : (
                        ""
                      )}
                      <th>Company</th>
                      <th>Employees</th>
                      <th>Patient</th>
                      <th>Email</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tags.map((tagItem, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>
                        <label
                              className="form-check-label text-truncate d-flex align-items-center"
                              htmlFor="remember4"
                            >
                              {tagItem.name}
                            </label>
                        </td>
                        {userData.role === "admin" ? (
                          <td>{tagItem?.physicalTherapist.length}</td>
                        ) : (
                          ""
                        )}
                        <td>{tagItem?.company.length}</td>
                        <td>{tagItem?.employees.length}</td>
                        <td>{tagItem?.patient.length}</td>
                        <td>{tagItem?.emailSubscribe.length}</td>
                        <td className="text-center">
                          <Link
                            onClick={() => {
                              setEditShow(true);
                              setCurrentTag(tagItem);
                            }}
                            className="action-btn"
                          >
                            <i className="fa fa-pencil" />
                          </Link>

                          <Link
                            className="action-btn"
                            onClick={() => openDeleteModel(tagItem._id)}
                          >
                            <i className="fa fa-trash" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTagModel
        show={show}
        handleClose={() => {
          setShow(false);
        }}
        fun={fun}
      />
      <EditTagModel
        show={editShow}
        handleClose={() => {
          setCurrentTag({});
          setEditShow(false);
        }}
        currentTag={currentTag}
        fun={fun}
      />
      <DeleteModel
        show={deleteModal}
        handleClose={closeDeleteModel}
        data={deleteData}
        deleteFunction={deleteTagHandler}
      />
    </>
  );
};

export default Tags;
