import React, { useState, useEffect } from "react";
import AddEmailTemplateModel from "./AddEmailTemplateModel";
import EditEmailTemplate from "./EditEmailTemplate";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import PaginationComponent from "../../pagination/PaginationComponent";


const EmailTemplate = () => {
  const [show, setShow] = useState(false);
  const { getAllEmailTemplateApi, deleteEmailTemplateApi } = useApiAxios();
  const [currentCourseData, setCurrentCourseData] = useState({}); // State to store the selected course ID
  const [editimage, setEditImage] = useState("");
  const [editimageUrl, setEditImageUrl] = useState("");

  const limit = 15;
  const [showModal, setShowModal] = useState(false);
  
  const [courses, setCourses] = useState([]);

  const [total, setTotal] = useState(null);

  const handleDelete = async (courseId) => {
    await deleteEmailTemplateApi(courseId);
    await fun();
  };
  const fun = async () => {
    const data = await getAllEmailTemplateApi();

    setCourses(data.data.data);
    setTotal(data.data.total);
  };
  useEffect(() => {
    fun();
    document.title = "Email Template";
  }, []);

  const handleShow = () => {
    setShow(true);
  };
  const handleEditCourse = async (course) => {
    setCurrentCourseData(course);
    setShowModal(true);
  };

  return (
    <div>
      <div className="">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-xl-5 col-sm-5">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                  <li className="breadcrumb-item">
                    <Link href="#">Email </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Email Template
                  </li>
                </ol>
              </nav>
             
              <h4 className="page-title ">
                Email Template <span>({total})</span>
              </h4>
            </div>
            <div className="col-xl-7 col-sm-7 mb-3 mb-xl-0 d-flex justify-content-start justify-content-sm-end align-items-sm-center">
              <Link className="btn company-btn" onClick={handleShow}>
                <i class="fa-regular fa-plus"></i> Email Template
              </Link>
            </div>
          </div>
          <div className="row align-items-stretch">
            <div className="col-xxl-12 mb-3">
              <div className="card customer-sf h-100">
                <div className="p-30 pb-0">
                  <div className="row mt-4"></div>
                </div>
                <div className="pb-4 companies-table product-table">
                  <div className="mt-2 table-responsive">
                    <table
                      id="products-table"
                      className="table display table-edits"
                      cellSpacing={0}
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Internal Title</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {courses.map((course, index) => (
                          <tr key={index}>
                            <td>{course.title}</td>
                            <td className="pro-info text-nowrap">
                              {course.internalTitle}
                            </td>
                            <td className="text-center">
                              <Link
                              
                                className="action-btn my-1"
                                onClick={() => {
                                  handleEditCourse(course);
                                }}
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </Link>
                              <Link
                                className="action-btn"
                                onClick={() => handleDelete(course._id)}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PaginationComponent
            total={total}
            page={1}
            limit={10000}
            // setPage={setPage}
          />
        </div>
      </div>

      <EditEmailTemplate
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setEditImageUrl("");
          setEditImage("");
          setCurrentCourseData(null);
        }}
        currentCourseData={currentCourseData} // Pass the selected course ID
        fun={fun}
        imageUrl={editimageUrl}
        setImageUrl={setEditImageUrl}
        image={editimage}
        setImage={setEditImage}
      />
      <AddEmailTemplateModel
        show={show}
        handleClose={() => setShow(false)}
        fun={fun}
      />
    </div>
  );
};

export default EmailTemplate;
