import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import EditableText from "./EditableText";
import EditableImage from "./EditableImage";

const IndividualContent = () => {
  const { getWebsiteContent, updateWebsiteContent } = useApiAxios();
  const [content, setContent] = useState({});
  const init = async () => {
    const { data, error } = await getWebsiteContent();
    if (!error) {
      setContent(data.data);
    }
  };
  const updateData = async () => {
    await updateWebsiteContent(content);
  };

  const onChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <div>
        <div className="container-fluid banner_area pe-0">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-5 order-2 order-md-1">
              <EditableText
                text={content.t23}
                isHeading={true}
                level={1}
                className="banner-title mt-4 mt-md-0"
                name={"t23"}
                onChange={onChange}
              />

              <EditableText
                text={content.t24}
                isHeading={true}
                level={6}
                className="banner-subtitle"
                name={"t24"}
                onChange={onChange}
              />
            </div>
            <div className="col-md-7 order-1 order-md-2 text-end">
              <EditableImage
                src={`${content?.img11}`}
                title="Wellness video platform to prevent & reduce pain"
                alt=""
                className="img-fluid pt-5 banner-img"
                name="img11"
              />
            </div>
          </div>
        </div>

        <div className="container my-4 my-xl-5 ">
          <div className="row py-4">
            <div className="col-sm-12">
              <EditableText
                text={content.t25}
                isHeading={false}
                level={6}
                className="home_subtext text-center mb-3"
                name={"t25"}
                onChange={onChange}
              />

              <div className="row">
                <div className="col-md-12 text-center">
                  <Link to="/contact" className="book1-btn">
                    I want to sign up for Hanna Health
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="col-sm-12">
              <EditableText
                text={content.t26}
                isHeading={true}
                level={1}
                className="home_title text-center mb-3"
                name={"t26"}
                onChange={onChange}
              />
            </div>
            <div className="col-sm-6">
              <ul className="why-us">
                <li>
                  <i className="fas fa-check-circle" />
                  <strong>
                    <EditableText
                      text={content.t27}
                      isHeading={false}
                      level={1}
                      className=""
                      name={"t27"}
                      strong={true}
                      onChange={onChange}
                    />
                  </strong>

                  <EditableText
                    text={content.t28}
                    isHeading={false}
                    level={1}
                    span={true}
                    className=""
                    name={"t28"}
                    onChange={onChange}
                  />
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  <strong>
                    <EditableText
                      text={content.t29}
                      isHeading={false}
                      level={1}
                      className=""
                      name={"t29"}
                      strong={true}
                      onChange={onChange}
                    />
                  </strong>
                  <EditableText
                    text={content.t30}
                    isHeading={false}
                    level={1}
                    className=""
                    name={"t30"}
                    span={true}
                    onChange={onChange}
                  />
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  <EditableText
                    text={content.t31}
                    isHeading={false}
                    level={1}
                    className=""
                    name={"t31"}
                    span={true}
                    onChange={onChange}
                  />

                  <strong>
                    <EditableText
                      text={content.t32}
                      isHeading={false}
                      level={1}
                      className=""
                      name={"t32"}
                      strong={true}
                      onChange={onChange}
                    />
                  </strong>
                </li>
              </ul>
            </div>
            <div className="col-sm-6">
              <ul className="why-us">
                <li>
                  <i className="fas fa-check-circle" />
                  <EditableText
                    text={content.t33}
                    isHeading={false}
                    level={1}
                    className=""
                    name={"t33"}
                    span={true}
                    onChange={onChange}
                  />

                  <strong>
                    <EditableText
                      text={content.t34}
                      isHeading={false}
                      level={1}
                      className=""
                      name={"t34"}
                      strong={true}
                      onChange={onChange}
                    />
                  </strong>
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  <EditableText
                    text={content.t35}
                    isHeading={false}
                    level={1}
                    className=""
                    name={"t35"}
                    span={true}
                    onChange={onChange}
                  />

                  <strong>
                    <EditableText
                      text={content.t36}
                      isHeading={false}
                      level={1}
                      className=""
                      name={"t36"}
                      strong={true}
                      onChange={onChange}
                    />
                  </strong>
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  <strong>
                    <EditableText
                      text={content.t37}
                      isHeading={false}
                      level={1}
                      className=""
                      strong={true}
                      name={"t37"}
                      onChange={onChange}
                    />
                  </strong>

                  <EditableText
                    text={content.t38}
                    isHeading={false}
                    level={1}
                    className=""
                    name={"t38"}
                    span={true}
                    onChange={onChange}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <EditableText
                text={content.t39}
                isHeading={true}
                level={1}
                className="home_title text-center mb-4"
                name={"t39"}
                onChange={onChange}
              />

              <div className="row">
                <div className="col-md-12 text-center">
                  <Link className="book1-btn">I want to sign up for Hanna Health </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-4">
        <button className="virt_btn green" onClick={updateData}>
          Update Data
        </button>
      </div>
    </div>
  );
};

export default IndividualContent;
