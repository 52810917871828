import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";
import moment from "moment";
import defaultImg from "./../../assets/images/defaultImg.jpg";
import { Context } from "../../context/Context";
import ImageComponent from "../media/ImageComponent";
import DeleteSubscriptionModel from "../deleteModel/DeleteSubscriptionModal";

const PhysicalTherapistProfile = (data) => {
  const { userData, updateCount, setUpdateCount } = useContext(Context);
  console.log(userData);
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");

 
  const {
    updatePhysicalTherapistProfileData,
    updatePhysicalTherapistProfileImage,
    handleSubscriptionCancel,

    getOfferById,
    createSubscription,
  } = useApiAxios();
  const formik = useFormik({
    initialValues: {
      name: "",
      dob: "",
      gender: "",
      email: "",
      phone: "",
      address: "",
      licenseNumber: "",
      clinicName: "",
      zip: "",
      aboutMe: "",
    },
    validationSchema: Yup.object().shape({
      // validationSchema remains the same
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const data = await updatePhysicalTherapistProfileData(values);
      if (image) {
        const formData = new FormData();

        formData.append("image", image);
        const data2 = await updatePhysicalTherapistProfileImage(formData);
      }
      setUpdateCount(updateCount + 1);
    },
  });

  const [deleteSubscriptionModal, setDeleteSubscriptionModal] = useState(false);
  const [deleteSubscriptionData, setDeleteSubscriptionData] = useState(null);

  const closeDeleteSubscriptionModel = () => {
    setDeleteSubscriptionModal(false);
    setDeleteSubscriptionData(null);
  };

  const fun = async () => {};

  const deleteSubscriptionHandler = async (data) => {
    await handleSubscriptionCancel(data);
    await fun();
    closeDeleteSubscriptionModel();
  };

  const reNewSubscriptionHandler = async () => {
    const data = await getOfferById(userData.offer);

    if (!data.error) {
      const res = await createSubscription(
        userData.priceStripeId,
        data.data.data._id
      );
      if (res.error) {
      } else {
        window.location.href = res.data.data;
      }
    }
  
  };

  const planExpireDate = moment(userData.plainExpire).format("MM/DD/YYYY");

  useEffect(() => {
    document.title = "Profile";
    formik.setValues({
      name: userData.physicalTherapist.name,
      dob: moment(userData.physicalTherapist.dob).format("YYYY-MM-DD"),
      phone: userData.physicalTherapist.phone,
      email: userData.physicalTherapist.email,
      address: userData.physicalTherapist.address,
      gender: userData.physicalTherapist.gender,
      licenseNumber: userData.physicalTherapist.licenseNumber,
      zip: userData.physicalTherapist.zip,
      clinicName: userData.physicalTherapist.clinicName,

      aboutMe: userData.physicalTherapist.aboutMe,
    });
  }, [userData.physicalTherapist.name]);

  return (
    <div>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-sm-5">
            <nav aria-label="breadcrumb" className="theme-breadcrumb">
              <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                <li className="breadcrumb-item">
                  <Link href="#">Profile</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
              </ol>
            </nav>
            <h1 className="page-title">Update Profile</h1>
          </div>
        </div>
        <div className="row align-items-stretch">
          <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
            <div className="card customer-sf h-100">
              <div className="p-30">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row mt-4">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Name</label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            {...formik.getFieldProps("name")}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className="error">{formik.errors.name}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Date of Birth
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="date"
                            className="form-control"
                            id="dob"
                            {...formik.getFieldProps("dob")}
                          />
                          {formik.touched.dob && formik.errors.dob ? (
                            <div className="error">{formik.errors.dob}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Gender
                        </label>
                        <div className="col-sm-8">
                          <select
                            id="gender"
                            className="form-select"
                            {...formik.getFieldProps("gender")}
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                          {formik.touched.gender && formik.errors.gender ? (
                            <div className="error">{formik.errors.gender}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Email</label>
                        <div className="col-sm-8">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            {...formik.getFieldProps("email")}
                            disabled
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Phone</label>
                        <div className="col-sm-8">
                          <input
                            type="tel"
                            className="form-control"
                            id="phone"
                            {...formik.getFieldProps("phone")}
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="error">{formik.errors.phone}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Address
                        </label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control address-field"
                            rows={3}
                            id="address"
                            {...formik.getFieldProps("address")}
                          />
                          {formik.touched.address && formik.errors.address ? (
                            <div className="error">{formik.errors.address}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          License Number
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="licenseNumber"
                            {...formik.getFieldProps("licenseNumber")}
                          />
                          {formik.touched.licenseNumber &&
                          formik.errors.licenseNumber ? (
                            <div className="error">
                              {formik.errors.licenseNumber}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Zip</label>
                        <div className="col-sm-8">
                          <textarea
                            type="text"
                            className="form-control"
                            id="zip"
                            {...formik.getFieldProps("zip")}
                          />
                          {formik.touched.zip && formik.errors.zip ? (
                            <div className="error">{formik.errors.zip}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Clinic Name
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="clinicName"
                            {...formik.getFieldProps("clinicName")}
                          />
                          {formik.touched.clinicName &&
                          formik.errors.clinicName ? (
                            <div className="error">
                              {formik.errors.clinicName}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          About Me
                        </label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control address-field"
                            rows={3}
                            id="aboutMe"
                            {...formik.getFieldProps("aboutMe")}
                          />
                          {formik.touched.aboutMe && formik.errors.aboutMe ? (
                            <div className="error">{formik.errors.aboutMe}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6 col-lg-12 col-xl-6">
                      {/* Profile Picture field */}
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Profile Image
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="file"
                            className="form-control"
                            accept="image/png, image/gif, image/jpeg"
                            id=""
                            {...formik.getFieldProps("image")}
                            name="image"
                            value={formik.values.image}
                            onChange={(event) => {
                              const file = event.target.files[0];
                              setImage(file);

                              const reader = new FileReader();
                              reader.onload = () => {
                                setImageUrl(reader.result);
                              };
                              reader.readAsDataURL(file);
                            }}
                          />
                          <div>
                            {image ? (
                              <ImageComponent
                                className="minHeight200"
                                src={imageUrl}
                                alt=""
                              />
                            ) : (
                              <ImageComponent
                                className="minHeight200"
                                src={
                                  userData.physicalTherapist.profileImage
                                    ? `${userData.physicalTherapist.profileImage}`
                                    : defaultImg
                                }
                                alt=""
                              />
                            )}
                          </div>
                          {formik.touched.profileImage &&
                          formik.errors.profileImage ? (
                            <div className="text-danger">
                              {formik.errors.profileImage}
                            </div>
                          ) : null}
                          <img src="" alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Your Subscription will be expired on {planExpireDate}
                        </label>
                        <div className="col-sm-8">
                          <button
                            type="button"
                            className="btn addsubmit-btn green-btn mb-3"
                            onClick={() => reNewSubscriptionHandler()}
                          >
                            Re-New Subscription
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12 col-xl-12">
                      <div className="mb-3 form-fieldc d-flex justify-content-start align-items-center">
                        <div className="d-flex gap-3">
                          <button
                            type="submit"
                            className="btn addsubmit-btn green-btn mb-3"
                            disabled={formik.isSubmitting}
                          >
                            Update
                          </button>
                          <Link
                            to={"/"}
                            className="btn addsubmit-btn grey-btn mb-3"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteSubscriptionModel
        show={deleteSubscriptionModal}
        handleClose={closeDeleteSubscriptionModel}
        data={deleteSubscriptionData}
        deleteFunction={deleteSubscriptionHandler}
      />
    </div>
  );
};

export default PhysicalTherapistProfile;
