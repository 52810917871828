import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import arrowRight from "../../assets/images/arrow-right.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "../../assets/images/hanna1.png";

import { Link, useParams } from "react-router-dom";

const Register = () => {
  const [offer, setOffer] = useState({});
  const { priceStripeId, offerId } = useParams(); // Get the ID from the URL
  const { registerApi, getOfferById } = useApiAxios();
  const fun = async () => {
    const data = await getOfferById(offerId);
    if (!data.error) {
      setOffer(data.data.data);
    }
  };
  useEffect(() => {
    fun();
  }, []);
  const [showPassword, setShowPassword] = React.useState(false);
  const [ShowConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [index, setIndex] = useState(0);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      phone: "",
      gender: "",
      agree: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("User Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        ),
      passwordConfirm: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
        agree: Yup.string().required("Please agree to T&C"),
    }),
    onSubmit: (values) => {
      registerApi({ ...values, offerId, priceStripeId });
    },
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!ShowConfirmPassword);
  };

  return (
    <div className="login_page">
      <div className="row align-self-stretch h-100">
        {/*Col*/}
        <div className="col-xl-6 col-lg-6 align-self-stretch h-100 login_box_mob order-2 order-lg-1">
          <div className="h-100 px-0 px-lg-3 align-items-baseline login_box_outer scrollable-div d-flex align-items-start flex-column overyauto">
            <div className="row">
              <div className="col-12 mb-2">
                <Link to="/" className="login-back">
                  <img width={7} src={arrowRight} className="me-3" alt="" />
                  Back to homepage
                </Link>
              </div>
            </div>
            <div className="login-container m-auto">
              <div className="row scrollable-div">
                <h3 className="fw-bold login-title">Sign Up</h3>
                <p className="login-subtitle mb-2">
                  Enter your details to Register
                </p>
                <form onSubmit={formik.handleSubmit}>
                  {index === 0 ? (
                    <>
                      <div className="col-12 mb-2 position-relative">
                        <label htmlFor="name">
                          Full Name<span>*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="JohnDoe"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <small className="text-danger">
                            {formik.errors.name}
                          </small>
                        )}
                      </div>
                      <div className="col-12 mb-2 position-relative">
                        <label htmlFor="email">
                          Email<span>*</span>
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="mail@simple.com"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <small className="text-danger">
                            {formik.errors.email}
                          </small>
                        )}
                      </div>

                      <div className="col-12 mb-2 position-relative">
                        <label htmlFor="passwordConfirm">
                          Password<span>*</span>
                        </label>
                        <div class="input-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className="form-control"
                            placeholder="Min. 8 characters"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            class="input-group-text"
                          >
                            {" "}
                            {showPassword ? (
                              <i class="fa-solid fa-eye"></i>
                            ) : (
                              <i class="fa-solid fa-eye-slash"></i>
                            )}
                          </span>
                        </div>
                        {formik.touched.password && formik.errors.password && (
                          <small className="text-danger">
                            {formik.errors.password}
                          </small>
                        )}
                      </div>
                      <div className="col-12 mb-2 position-relative">
                        <label htmlFor="passwordConfirm">
                          Confirm Password<span>*</span>
                        </label>
                        <div class="input-group">
                          <input
                            type={ShowConfirmPassword ? "text" : "password"}
                            id="passwordConfirm"
                            name="passwordConfirm"
                            className="form-control"
                            placeholder="Confirm Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.passwordConfirm}
                          />
                          <span
                            onClick={toggleConfirmPasswordVisibility}
                            class="input-group-text"
                          >
                            {" "}
                            {ShowConfirmPassword ? (
                              <i class="fa-solid fa-eye"></i>
                            ) : (
                              <i class="fa-solid fa-eye-slash"></i>
                            )}
                          </span>
                        </div>
                        {formik.touched.passwordConfirm &&
                          formik.errors.passwordConfirm && (
                            <small className="text-danger">
                              {formik.errors.passwordConfirm}
                            </small>
                          )}
                      </div>

                      {/* Terms and Conditions Checkbox */}
                      <div className="col-12 mb-4 position-relative">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={true}
                            id="agree"
                            name="agree"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label className="form-check-label" htmlFor="agree">
                            I have read and agree to the&nbsp;
                            <Link to ="/term">
                              terms and conditions
                            </Link>
                            &nbsp;of this page as follows.
                          </label>
                        </div>
                      </div>
                      <button
                        type="submit"
                        id="submit"
                        className="btn btn-block btn_login w-100 my-2"
                      >
                        Sign Up
                      </button>
                      <Link
                        to="/"
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        <button className="btn btn-block btn_login w-100">
                          Login
                        </button>
                      </Link>
                    </>
                  ) : (
                    <>
                      <div className="col-12 mb-2 position-relative">
                        <label htmlFor="phone">
                          Phone Number<span>*</span>
                        </label>

                        <div class="input-group has-validation">
                          <span class="input-group-text" id="inputGroupPrepend">
                            +
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter phone number"
                            name="phone"
                            value={formik.values.phone}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            id="validationCustomUsername"
                            aria-describedby="inputGroupPrepend"
                          />
                          <div class="invalid-feedback">Phone</div>
                        </div>

                        {formik.touched.phone && formik.errors.phone && (
                          <small className="text-danger">
                            {formik.errors.phone}
                          </small>
                        )}
                      </div>
                      <div className="col-12 mb-2 position-relative">
                        <label htmlFor="dateOfBirth">
                          Date of Birth<span>*</span>
                        </label>
                        <input
                          type="date"
                          id="dateOfBirth"
                          name="dateOfBirth"
                          className="form-control"
                          placeholder="JohnDoe"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.dateOfBirth}
                        />
                      </div>
                      <div className="col-12 mb-2 position-relative">
                        <label>
                          Gender<span>*</span>
                        </label>
                        <br />

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineRadio1"
                            name="gender"
                            value="male"
                            checked={formik.values.gender === "male"}
                            onChange={formik.handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Male
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            value="female"
                            checked={formik.values.gender === "female"}
                            onChange={formik.handleChange}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Female
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            value="other"
                            checked={formik.values.gender === "other"}
                            onChange={formik.handleChange}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio3"
                          >
                            Other
                          </label>
                        </div>

                        {formik.touched.gender && formik.errors.gender && (
                          <small className="text-danger">
                            {formik.errors.gender}
                          </small>
                        )}
                      </div>

                      <div className="col-12 mb-2">
                        <button
                          type="submit"
                          id="submit"
                          className="btn btn-block btn_login w-100"
                        >
                          Sign Up
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
            <p className="copyright-text mt-4">
              © 2024 Hanna Health. All Rights Reserved
            </p>
          </div>
          {/*/Row*/}
        </div>
        {/*/Col*/}
        <div className="col-xl-6 col-lg-6 order-1 order-lg-2 pe-0">
          <div className="r-area">
            <div className="logo-r-area">
              <img
                src={logo}
                className="d-block login_img"
                alt=""
                title="VIRT"
              />
            </div>
            <div className="logo-r-link">
              <ul>
                
                <li>
                  <Link to="/term">Terms of Use</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*/Col*/}
      </div>
      {/*/Row*/}
    </div>
  );
};
export default Register;
